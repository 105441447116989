import { Stack } from '@stereograph/teia-system-design/components/Stack';
import { Text } from '@stereograph/teia-system-design/components/Typography';
import { usePluginTranslation } from '@translation';
import { AnomalyDocumentSectionActions } from './AnomalyDocumentSectionActions';

export const AnomalyDocumentSectionHeader = () => {
  const { t } = usePluginTranslation();

  return (
    <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
      <Text variant="lead">{t('anomaly_plugin.document.section.title')}</Text>
      <AnomalyDocumentSectionActions />
    </Stack>
  );
};
