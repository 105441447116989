import { Comment } from '@api/twin/CommentApi';
import { Button } from '@stereograph/teia-system-design/components/Button';
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader
} from '@stereograph/teia-system-design/components/Modal';
import { Stack } from '@stereograph/teia-system-design/components/Stack';
import { Text } from '@stereograph/teia-system-design/components/Typography';
import { usePluginTranslation } from '@translation';
import { useAnomalyWidgetContext } from '../../context/AnomalyWidgetContext';
import { useDeleteAnomalyDocument } from '../../hooks/useDeleteAnomalyDocument';
import { AnomalyDocument } from './AnomalyDocumentCard';

interface AnomalyDocumentDeleteModalProps {
  isModalOpen: boolean;
  onClose: () => void;
  document: AnomalyDocument;
  comment?: Comment;
}

export const AnomalyDocumentDeleteModal = (props: AnomalyDocumentDeleteModalProps) => {
  const { isModalOpen, onClose, document, comment } = props;
  const { projectId } = useAnomalyWidgetContext();
  const { t } = usePluginTranslation();
  const { deleteAnomalyDocument } = useDeleteAnomalyDocument();

  const handleDeleteDocument = () => {
    deleteAnomalyDocument(
      { projectId, document, commentId: comment?.id },
      {
        onSuccess: () => onClose()
      }
    );
  };

  return (
    <Modal open={isModalOpen} onClose={onClose} closeOnBackdropClick>
      <ModalHeader>{t('anomaly_plugin.document.delete.modal.title')}</ModalHeader>
      <ModalContent>
        <Text>{t('anomaly_plugin.document.delete.modal.text')}</Text>
      </ModalContent>
      <ModalFooter>
        <Stack direction="row" spacing={2} justifyContent="center">
          <Button onClick={onClose} color="info">
            {t('anomaly_plugin.document.delete.modal.cancel_button.label')}
          </Button>
          <Button onClick={() => handleDeleteDocument()} color="error">
            {t('anomaly_plugin.document.delete.modal.delete_button.label')}
          </Button>
        </Stack>
      </ModalFooter>
    </Modal>
  );
};
