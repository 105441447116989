import { AnomalyHistoryQueryParams, OrderDirection } from '@api/twin/AnomalyApi';
import { Button } from '@stereograph/teia-system-design/components/Button';
import { Divider } from '@stereograph/teia-system-design/components/Divider';
import { Stack } from '@stereograph/teia-system-design/components/Stack';
import { Text } from '@stereograph/teia-system-design/components/Typography';
import { usePluginTranslation } from '@translation';
import { useAnomalyApi } from '../../api/useAnomalyApi';
import { useAnomalySectionContext } from '../../context/AnomalySectionContext';
import { AnomalyHistoryItem } from './AnomalyHistoryItem';

interface AnomalyHistoryList {
  orderDirection: OrderDirection;
}

export const AnomalyHistoryList = (props: AnomalyHistoryList) => {
  const { orderDirection } = props;
  const { t } = usePluginTranslation();
  const { anomaly } = useAnomalySectionContext();
  const { getAnomalyHistory } = useAnomalyApi(anomaly.projectId);

  const anomalyHistoryQueryParams: AnomalyHistoryQueryParams = {
    pageNumber: 1,
    pageSize: 10,
    orderBy: 'creationDate',
    orderDirection: orderDirection
  };

  const { data, hasNextPage, fetchNextPage, isFetchingNextPage } = getAnomalyHistory(
    anomaly.id,
    anomalyHistoryQueryParams
  );

  const histories = data?.pages.flatMap((page) => page.data) ?? [];

  return (
    <Stack divider={<Divider flexItem />} spacing={3} sx={{ pb: 4 }}>
      {histories.map((historyEvent) => (
        <AnomalyHistoryItem anomalyHistory={historyEvent} key={`historyEvent-${historyEvent.id}`} />
      ))}
      {histories.length === 0 && <Text>{t('anomaly_plugin.widget.history.no_data.text')}</Text>}
      {hasNextPage && (
        <Button onClick={() => fetchNextPage()} disabled={isFetchingNextPage}>
          {t('anomaly_plugin.widget.history.load_more.label')}
        </Button>
      )}
    </Stack>
  );
};
