import { Attachment } from '@api/tia/attachments/Attachment';
import { createContext, useContext, useMemo, useRef } from 'react';
import { AttachmentController } from './AttachmentController';

export class AttachmentControllerRegistry {
  private readonly _controllers = new Map<Attachment['type'], AttachmentController<any>>();

  addController<T extends Attachment>(controller: AttachmentController<T>) {
    if (this._controllers.has(controller.type)) {
      console.warn(
        `You are overriding an attachment controller that is already registered for the type "${controller.type}"`
      );
    }

    this._controllers.set(controller.type, controller);
  }

  getController<T extends Attachment>(type: Attachment['type']): AttachmentController<T> {
    const controller = this._controllers.get(type);
    if (!controller) {
      console.error(`No attachment controller registered for the type "${type}"`);
    }
    return controller as unknown as AttachmentController<T>;
  }

  clear() {
    this._controllers.clear();
  }
}

export interface AttachmentControllerRegistryContext {
  registry: AttachmentControllerRegistry;
}

const Context = createContext<AttachmentControllerRegistryContext | null>(null);

export interface AttachmentControllerRegistryContextProviderProps {
  children: React.ReactNode;
}

export const AttachmentControllerRegistryContextProvider = (
  props: AttachmentControllerRegistryContextProviderProps
) => {
  const { children } = props;

  const { current: registry } = useRef(new AttachmentControllerRegistry());

  const ctx = useMemo(() => ({ registry }), [registry]);

  return <Context.Provider value={ctx}>{children}</Context.Provider>;
};

export const useAttachmentControllerRegistryContext = () => {
  const ctx = useContext(Context);
  if (!ctx) {
    throw new Error('Context not found');
  }
  return ctx;
};
