import { ApiClient } from '@stereograph/teia-system-design/utils';

export interface ModelFile {
  id: number;
  ifcModelId: number;
  version: number;
  ifcModelName: string;
  size: number;
  publicName: string;
  status: 'Imported';
  evaluationStatus: 'Unknown' | 'Accepted' | 'Refused';
  type: string;
  creationDate: string;
  model: {
    ifcModelId: number;
    name: string;
  };
  isInTwin: boolean;
}

export interface Model {
  ifcModelId: number;
  name: string;
  lastFile: ModelFile;
}

export interface ModelResponse {
  pageNumber: number;
  pageSize: number;
  totalPages: number;
  totalRecords: number;
  data: Array<Model>;
}

export interface GetModelsQueryParams {
  isInTwin?: boolean;
  pageNumber?: number;
  pageSize?: number;
}

export const ModelApi = (client: ApiClient) => ({
  getModels(projectId: number, options: GetModelsQueryParams) {
    const url = client.URL(`projects/${projectId}/models`);
    if (options.isInTwin) {
      url.searchParams.set('isInTwin', String(options.isInTwin));
    }
    if (options.pageNumber) {
      url.searchParams.set('pageNumber', String(options.pageNumber));
    }
    if (options.pageNumber) {
      url.searchParams.set('pageSize', String(options.pageSize));
    }

    const request = new Request(url, {
      method: 'GET'
    });

    return client.sendRequest<ModelResponse>(request);
  }
});
