import { Anomaly, AnomalyFileType } from '@api/twin/AnomalyApi';
import CreateAnomalyIcon from '@resources/icons/CreateAnomalyIcon.svg?react';
import { Button } from '@stereograph/teia-system-design/components/Button';
import { Icon } from '@stereograph/teia-system-design/components/Icon';
import { MRT_TableInstance } from '@stereograph/teia-system-design/components/Table';
import { Stack } from '@stereograph/teia-system-design/components/Stack';
import { usePluginTranslation } from '@translation';
import { MouseEvent } from 'react';
import { useAnomalyTabContext } from '../../context/AnomalyTabsContext';
import { useAnomalyWidgetContext } from '../../context/AnomalyWidgetContext';
import { useExportAnomalies } from '../../hooks/useExportAnomalies';

interface AnomalyResumeTableToolbarProps {
  table: MRT_TableInstance<Anomaly>;
}

export const AnomalyResumeTableToolbar = (props: AnomalyResumeTableToolbarProps) => {
  const { table } = props;
  const { rowSelection } = table.getState();
  const { t } = usePluginTranslation();
  const { projectId } = useAnomalyWidgetContext();
  const { handleAddCreationAnomalyTabClick } = useAnomalyTabContext();

  const anomalyIds = Object.keys(rowSelection).map((key) => parseInt(key));
  const { downloadAnomaly: downloadBcf, isDownloading: isBcfDownloading } = useExportAnomalies(
    anomalyIds,
    projectId,
    AnomalyFileType.BCF
  );
  const { downloadAnomaly: downloadExcel, isDownloading: isExcelDownloading } = useExportAnomalies(
    anomalyIds,
    projectId,
    AnomalyFileType.Excel
  );

  const openCreateAnomalyWidget = (event: MouseEvent) => {
    handleAddCreationAnomalyTabClick(event);
  };

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <Button
        onClick={openCreateAnomalyWidget}
        startIcon={<Icon component={CreateAnomalyIcon} sx={{ fill: 'currentcolor' }} />}
        sx={{ color: 'white' }}
      >
        {t('anomaly_plugin.widget.create_anomaly.text')}
      </Button>
      <Button
        onClick={downloadBcf}
        isLoading={isBcfDownloading}
        disabled={isBcfDownloading || anomalyIds.length === 0}
      >
        {t('anomaly_plugin.resume_widget.table.toolbar.export_bcf_label')}
      </Button>
      <Button
        onClick={downloadExcel}
        isLoading={isExcelDownloading}
        disabled={isExcelDownloading || anomalyIds.length === 0}
      >
        {t('anomaly_plugin.resume_widget.table.toolbar.export_xls_label')}
      </Button>
    </Stack>
  );
};
