import { Model } from '@api/twin/ModelApi';
import { SelectionFilter } from '@api/twin/SelectionFilterApi';
import { Twin } from '@api/twin/TwinApi';
import { createContext, ReactNode, useContext, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useModelApi } from '../api/useModelApi';
import { useSelectionFiltersApi } from '../api/useSelectionFilterApi';
import { PartialLoading } from './TeiaPartialLoadingWidgetContent';

export interface TeiaPartialLoadingContextProps {
  models: Array<Model>;
  selections: Array<SelectionFilter>;
}

const TeiaPartialLoadingContext = createContext<TeiaPartialLoadingContextProps | null>(null);

export interface TeiaPartialLoadingWidgetProviderProps {
  children: ReactNode;
  twin: Twin;
}

export const TeiaPartialLoadingWidgetProvider = (props: TeiaPartialLoadingWidgetProviderProps) => {
  const { children, twin } = props;

  const { ...methods } = useForm<PartialLoading>({
    defaultValues: {
      models: [],
      selections: [],
      name: ''
    }
  });

  const { useGetAllModels } = useModelApi(twin.projectId);
  const { data: modelsData } = useGetAllModels({ isInTwin: true });
  const models = modelsData ?? [];

  const { useGetAllSelectionFilters } = useSelectionFiltersApi(twin.projectId);
  const selectionFilterData = useGetAllSelectionFilters();
  const selectionFilters = selectionFilterData ?? [];

  const value = useMemo(
    () => ({ models, selections: selectionFilters }),
    [models, selectionFilters]
  );
  return (
    <TeiaPartialLoadingContext.Provider value={value}>
      <FormProvider {...methods}>{children}</FormProvider>
    </TeiaPartialLoadingContext.Provider>
  );
};

export const useTeiaPartialLoadingContext = () => {
  const context = useContext(TeiaPartialLoadingContext);
  if (context === null) {
    throw new Error(
      'useTeiaPartialLoadingContext must be used within a TeiaPartialLoadingWidgetProvider'
    );
  }
  return context;
};
