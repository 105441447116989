import { useDeleteComment } from '@api/twin/hooks/useDeleteComment';
import { useQueryClient } from '@tanstack/react-query';

export const useDeleteAnomalyComment = (projectId: number, anomalyId: number) => {
  const { deleteComment } = useDeleteComment(projectId);
  const queryClient = useQueryClient();

  const deleteAnomalyComment = (commentId: number) => {
    return deleteComment({ commentId, targetId: anomalyId })
      .then(() =>
        queryClient.invalidateQueries({
          queryKey: ['Anomaly', projectId]
        })
      )
      .catch((error) => console.log(error));
  };

  return {
    deleteAnomalyComment
  };
};
