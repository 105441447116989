import { Comment } from '@api/twin/CommentApi';
import { createContext, ReactNode, useContext, useMemo, useState } from 'react';

interface AnomalyCommentContextProps {
  comment: Comment;
  isResponseListOpened: boolean;
  toggleResponseList: () => void;
  isAnswering: boolean;
  toggleAnswering: () => void;
}

interface AnomalyCommentProviderProps {
  comment: Comment;
  children: ReactNode;
}

const AnomalyCommentContext = createContext<AnomalyCommentContextProps | null>(null);

export const AnomalyCommentProvider = ({ comment, children }: AnomalyCommentProviderProps) => {
  const [isResponseListOpened, setIsResponseListOpened] = useState(false);
  const [isAnswering, setIsAnswering] = useState(false);

  const toggleResponseList = () => setIsResponseListOpened((prev) => !prev);
  const toggleAnswering = () => setIsAnswering((prev) => !prev);

  const context = useMemo(
    () => ({
      comment,
      isResponseListOpened,
      toggleResponseList,
      isAnswering,
      toggleAnswering
    }),
    [comment, isResponseListOpened, isAnswering]
  );

  return (
    <AnomalyCommentContext.Provider value={context}>{children}</AnomalyCommentContext.Provider>
  );
};

export const useAnomalyCommentContext = () => {
  const context = useContext(AnomalyCommentContext);
  if (!context) {
    throw new Error('useAnomalyCommentContext must be used within an AnomalyCommentProvider');
  }
  return context;
};
