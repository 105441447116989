import { DocumentArrowDownIcon } from '@heroicons/react/24/outline';
import { Icon } from '@stereograph/teia-system-design/components/Icon';
import { IconButton } from '@stereograph/teia-system-design/components/IconButton';
import { Tooltip } from '@stereograph/teia-system-design/components/Tooltip';
import { usePluginTranslation } from '@translation';
import { useGetAllAnomalyDocuments } from '../../../hooks/useGetAllAnomalyDocuments';
import { useDownloadAllDocuments } from './useDownloadAllDocuments';

export const DownloadAllDocumentsAction = () => {
  const { downloadDocuments } = useDownloadAllDocuments();
  const { documents } = useGetAllAnomalyDocuments();
  const { t } = usePluginTranslation();

  return (
    <Tooltip title={t('anomaly_plugin.document.download_all_label')} enterDelay={150}>
      <IconButton
        aria-label={t('anomaly_plugin.document.download_all_label')}
        onClick={() => downloadDocuments(documents)}
        iconOnly
      >
        <Icon component={DocumentArrowDownIcon} />
      </IconButton>
    </Tooltip>
  );
};
