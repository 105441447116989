import { ChatBubbleLeftIcon } from '@heroicons/react/24/outline';
import { Chip } from '@stereograph/teia-system-design/components/Chip';
import { Icon } from '@stereograph/teia-system-design/components/Icon';
import {
  StandardWidget,
  StandardWidgetOptions,
  TeiaViewer,
  WidgetFactoryFunction
} from '@stereograph/teiaviewer';
import { ChatBotWidgetContent } from './ChatBotWidgetContent';

declare global {
  interface ViewerWidgetRegistry {
    chatbot: WidgetFactoryFunction<ChatBotWidget>;
  }
}

export class ChatBotWidget extends StandardWidget<TeiaViewer> {
  readonly type = 'chatbot';
  readonly isChatBotWidget = true;

  constructor(viewer: TeiaViewer, options: StandardWidgetOptions = {}) {
    super(viewer, { ...options, color: 'secondary', useFooter: false });
  }

  override Header = () => {
    return (
      <div className="st-flex st-gap-1 st-items-center">
        <Icon component={ChatBubbleLeftIcon} />
        <p>Elliot</p>
        <Chip
          label="BETA"
          color="error"
          sx={{
            marginLeft: 2,
            fontSize: 10,
            padding: 0,
            minWidth: 0,
            minHeight: 0,
            height: 20,
            '.MuiChip-label': {
              padding: 1
            }
          }}
        />
      </div>
    );
  };

  override Content = ChatBotWidgetContent;
}
