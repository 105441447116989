import type {
  ApiClient,
  ApiDataList,
  TeiaSearchFilter
} from '@stereograph/teia-system-design/utils';
import type { Twin } from './TwinApi';

export interface TwinBimDataProperty {
  propertyName: string;
  name: string;
  value: string;
  unit?: string;
  hasPatchData: boolean;
}

export interface TwinBimDataValue {
  value: string;
  count: number;
}

export interface TwinBimDataPropertyList {
  twinObjectGuid: string;
  twinBimDataPropertySearchDtos: Array<TwinBimDataPropertySearch>;
}

export interface TwinBimDataPropertySearch {
  propertyName: string;
  name: string;
  value: string;
  unit: string;
  type: string;
}

export interface TwinBimDataValueResponse {
  afterKey?: string;
  data: Array<TwinBimDataValue>;
}

export const TwinBimDataApi = (client: ApiClient) => ({
  getTwinObjectBimData(twin: Twin, guid: string) {
    const { projectId, type } = twin;
    const url = client.URL(`projects/${projectId}/twin/bimdata/${guid}`);
    url.searchParams.set('twinProjectType', type);
    const request = new Request(url, {
      method: 'GET'
    });
    return client.sendRequest<Array<TwinBimDataProperty>>(request);
  },

  getTwinBimDataValues(
    twin: Twin,
    propertyName: string,
    name: string,
    filter?: TeiaSearchFilter,
    afterKey?: string
  ) {
    const { projectId, type } = twin;
    const url = client.URL(`projects/${projectId}/twin/bimdata/values2`);
    url.searchParams.set('propertyName', propertyName);
    url.searchParams.set('name', name);
    url.searchParams.set('twinProjectType', type);
    if (afterKey) {
      url.searchParams.set('afterKey', afterKey);
    }
    const body = JSON.stringify(filter ?? {});
    const request = new Request(url, {
      method: 'POST',
      body,
      headers: {
        'Content-type': 'application/json'
      }
    });
    return client.sendRequest<TwinBimDataValueResponse>(request);
  },

  getTwinBimDataPropertyNames(twin: Twin, filter?: TeiaSearchFilter) {
    const { projectId, type } = twin;
    const url = client.URL(`projects/${projectId}/twin/bimdata/propertyNames2`);
    url.searchParams.set('twinProjectType', type);
    const body = JSON.stringify(filter ?? {});
    const request = new Request(url, {
      method: 'POST',
      body,
      headers: {
        'Content-type': 'application/json'
      }
    });
    return client.sendRequest<Array<TwinBimDataValue>>(request);
  },

  getTwinBimDataNames(twin: Twin, propertyName: string, filter?: TeiaSearchFilter) {
    const { projectId, type } = twin;
    const url = client.URL(`projects/${projectId}/twin/bimdata/names2`);
    url.searchParams.set('twinProjectType', type);
    url.searchParams.set('propertyName', propertyName);
    const body = JSON.stringify(filter ?? {});
    const request = new Request(url, {
      method: 'POST',
      body,
      headers: {
        'Content-type': 'application/json'
      }
    });
    return client.sendRequest<Array<TwinBimDataValue>>(request);
  },

  getTwinBimDataPropertiesSearch(
    twin: Twin,
    pageNumber: number,
    pageSize: number,
    filter?: TeiaSearchFilter
  ) {
    const { projectId, type } = twin;
    const url = client.URL(`projects/${projectId}/twin/bimdata/search`);
    url.searchParams.set('twinProjectType', type);
    url.searchParams.set('pageNumber', String(pageNumber));
    url.searchParams.set('pageSize', String(pageSize));
    const body = JSON.stringify(filter ?? {});
    const request = new Request(url, {
      method: 'POST',
      body,
      headers: {
        'Content-type': 'application/json'
      }
    });
    return client.sendRequest<ApiDataList<TwinBimDataPropertyList>>(request);
  }
});
