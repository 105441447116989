import { TwinObjectApi } from '@api/twin/TwinObjectApi';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { IconButton } from '@stereograph/teia-system-design/components/IconButton';
import { fetchAllPages, TeiaSearchFilter } from '@stereograph/teia-system-design/utils';

import {
  SelectObjectsCommand,
  TeiaViewerSourceType,
  useTeiaViewerContext
} from '@stereograph/teiaviewer';
import { usePluginTranslation } from '@translation';

interface ShowSelectionButtonProps {
  filter: TeiaSearchFilter;
}

export const ShowSelectionButton = (props: ShowSelectionButtonProps) => {
  const { viewer } = useTeiaViewerContext();
  const client = viewer.twinApiClient;
  const { filter } = props;
  const { t } = usePluginTranslation();
  const source = viewer.currentSource;

  if (!source || source.sourceType !== TeiaViewerSourceType.Twin) return null;

  const onShowSelectionFilter = async () => {
    const data = await fetchAllPages((pageNumber: number, pageSize: number) => {
      return TwinObjectApi(client).searchTwinObjects(source, pageNumber, pageSize, filter);
    });
    const guids = data.map((twinObject) => twinObject.guid);
    const command = new SelectObjectsCommand(viewer, guids);
    command.execute();
  };

  return (
    <IconButton
      aria-label={t('selection_plugin.record_widget.actions.show.label')}
      onClick={onShowSelectionFilter}
    >
      <VisibilityOutlinedIcon />
    </IconButton>
  );
};
