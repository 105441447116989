import { AnomalyHistory, AnomalyHistoryProperty, AnomalyHistoryStatus } from '@api/twin/AnomalyApi';
import { Stack } from '@stereograph/teia-system-design/components/Stack';
import { Text } from '@stereograph/teia-system-design/components/Typography';
import { AnomalyHistoryItemAdded } from './AnomalyHistoryItemAdded';
import { AnomalyHistoryItemCreated } from './AnomalyHistoryItemCreated';
import { AnomalyHistoryItemDeleted } from './AnomalyHistoryItemDeleted';
import { AnomalyHistoryItemUpdated } from './AnomalyHistoryItemUpdated';

interface AnomalyHistoryItemProps {
  anomalyHistory: AnomalyHistory;
}

export const AnomalyHistoryItem = (props: AnomalyHistoryItemProps) => {
  const { anomalyHistory } = props;

  const renderAnomalyItemByState = (
    anomalyHistoryState: string,
    property: AnomalyHistoryProperty
  ) => {
    switch (anomalyHistoryState) {
      case AnomalyHistoryStatus.ADDED:
        return <AnomalyHistoryItemAdded property={property} key={property.propertyName} />;
      case AnomalyHistoryStatus.UPDATED:
        return <AnomalyHistoryItemUpdated property={property} key={property.propertyName} />;
      case AnomalyHistoryStatus.DELETED:
        return <AnomalyHistoryItemDeleted property={property} key={property.propertyName} />;
      default:
        return <></>;
    }
  };

  return (
    <>
      <Stack direction="row" spacing={2}>
        <Text bold>{anomalyHistory.author}</Text>
        <Text>
          {new Date(anomalyHistory.creationDate).toLocaleString('fr-FR', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
          })}
        </Text>
      </Stack>
      {anomalyHistory.anomalyHistoryState !== AnomalyHistoryStatus.CREATED &&
        anomalyHistory.anomalyHistoryPropertyDtos.map((property) =>
          renderAnomalyItemByState(anomalyHistory.anomalyHistoryState, property)
        )}
      {anomalyHistory.anomalyHistoryState === AnomalyHistoryStatus.CREATED && (
        <AnomalyHistoryItemCreated />
      )}
    </>
  );
};
