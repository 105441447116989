import { ApiClient, ApiDataList } from '@stereograph/teia-system-design/utils';

export type AttachmentTargetType = 'Unknown' | 'Anomaly' | 'Comment';

export interface Attachment {
  id: number;
  guid: string;
  projectId: number;
  title: string;
  targetId: number;
  targetType: AttachmentTargetType;
  extension: 'Unknown' | 'Pdf' | 'Png' | 'Jpg' | 'Mp4';
  size: number;
  creationDate: string;
  updateDate: string;
  createdBy: string;
}

export interface AttachmentApiGetAllParams {
  targetId?: number;
  targetType?: AttachmentTargetType;
  ids?: Array<number>;
  title?: string;
  extension?: string;
  createdBy?: string;
  updatedBy?: string;
  creationDate?: string;
  updateDate?: string;
  /**
   * @default Attachment['id']
   */
  orderBy?: keyof Attachment;
  /**
   * @default asc
   */
  orderDirection?: 'asc' | 'desc';
  /**
   * @default 1
   */
  pageNumber?: number;
  /**
   * @default 10
   */
  pageSize?: number;
}

export const AttachmentApi = (client: ApiClient) => ({
  getAll: async (projectId: number, params: AttachmentApiGetAllParams) => {
    const url = client.URL(`projects/${projectId}/attachments`);
    Object.entries(params).forEach(([key, value]) => {
      if (!value) {
        return;
      }

      if (Array.isArray(value)) {
        value.forEach((item) => url.searchParams.append(key, String(item)));
      } else {
        url.searchParams.set(key, String(value));
      }
    });

    const request = new Request(url, {
      method: 'GET'
    });

    return client.sendRequest<ApiDataList<Attachment>>(request);
  },

  post: async (
    projectId: number,
    targetId: number,
    targetType: AttachmentTargetType,
    attachment: File
  ) => {
    const url = client.URL(`projects/${projectId}/attachments`);
    url.searchParams.append('targetId', targetId.toString());
    url.searchParams.append('targetType', targetType);
    const formData = new FormData();
    formData.append('attachments', attachment);

    const request = new Request(url, {
      method: 'POST',
      body: formData
    });

    return client.sendRequest(request);
  },

  download: async (projectId: number, attachmentId: number) => {
    const url = client.URL(`projects/${projectId}/attachments/${attachmentId}/download`);
    const request = new Request(url, {
      method: 'GET'
    });

    return client.sendRequest<Response>(request);
  },

  delete: (projectId: number, attachmentId: number) => {
    const url = client.URL(`projects/${projectId}/attachments/${attachmentId}`);

    const request = new Request(url, {
      method: 'DELETE'
    });

    return client.sendRequest(request);
  }
});
