import { Anomaly } from '@api/twin/AnomalyApi';
import PlaceholderImage from '@resources/icons/PlaceholderImage.svg';
import { MRT_Row } from '@stereograph/teia-system-design/components/Table';

interface ImageCellProps {
  row: MRT_Row<Anomaly>;
}

export const ImageCell = (props: ImageCellProps) => {
  const { row } = props;

  return (
    <img
      className="st-flex-grow st-object-cover st-min-h-16 st-max-h-20 st-w-full"
      src={
        row.original.snapshot ? `data:image/png;base64,${row.original.snapshot}` : PlaceholderImage
      }
      alt={row.original.title}
    />
  );
};
