import { ApiClient } from '@stereograph/teia-system-design/utils';
import { TwinType } from './TwinApi';

export enum PatchDataAction {
  Create = 'Create',
  Update = 'Update',
  Delete = 'Delete',
  AddOrUpdate = 'AddOrUpdate'
}

export interface PatchDataProperty {
  propertyName: string;
  name: string;
  value?: string;
  action: PatchDataAction;
}

export interface PatchDataPostRequest {
  name: string;
  description: string;
  image: string;
  twinObjectGuids: Array<string>;
  properties: Array<PatchDataProperty>;
}

export const PatchDataApi = (client: ApiClient) => ({
  postPatchData(projectId: number, twinType: TwinType, patchData: PatchDataPostRequest) {
    const url = client.URL(`projects/${projectId}/patchdatarequests`);
    url.searchParams.set('twinProjectType', twinType);

    const request = new Request(url, {
      method: 'POST',
      body: JSON.stringify(patchData),
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return client.sendRequest(request);
  }
});
