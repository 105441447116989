import { Stack } from '@stereograph/teia-system-design/components/Stack';
import { ReactNode } from 'react';
import { AnomalyHistoryChip } from './AnomalyHistoryChip';

interface AnomalyHistoryItemBaseProps {
  chipColor: 'error' | 'warning' | 'primary';
  chipLabel: string;
  children?: ReactNode;
}

export const AnomalyHistoryItemBase = (props: AnomalyHistoryItemBaseProps) => {
  const { chipColor, chipLabel, children } = props;

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <AnomalyHistoryChip color={chipColor} label={chipLabel} />
      {children}
    </Stack>
  );
};
