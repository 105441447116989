export const formatBytes = (bytes: number, decimals = 5): string => {
  if (!Number.isFinite(bytes) || bytes <= 0) return 'Invalid size';

  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  const formattedValue = (bytes / Math.pow(k, i)).toFixed(decimals);
  return `${new Intl.NumberFormat('fr-FR').format(Number(formattedValue))} ${sizes[i]}`;
};
