import { OrderDirection } from '@api/twin/AnomalyApi';
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/24/outline';
import { Icon } from '@stereograph/teia-system-design/components/Icon';
import { IconButton } from '@stereograph/teia-system-design/components/IconButton';
import { Stack } from '@stereograph/teia-system-design/components/Stack';
import { Text } from '@stereograph/teia-system-design/components/Typography';
import { Tooltip } from '@stereograph/teia-system-design/components/Tooltip';
import { usePluginTranslation } from '@translation';

interface SwitchOrderDirectionButtonProps {
  orderDirection: OrderDirection;
  onChange: (orderDirection: OrderDirection) => void;
}

export const SwitchOrderDirectionButton = (props: SwitchOrderDirectionButtonProps) => {
  const { orderDirection, onChange } = props;
  const { t } = usePluginTranslation();

  const isOrderDirectionAsc = orderDirection === 'asc';

  const switchDirection = () => {
    const nextDirection = isOrderDirectionAsc ? 'desc' : 'asc';
    onChange(nextDirection);
  };

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Text>{t('anomaly_plugin.widget.history.sort.text')}</Text>
      <Tooltip
        placement="top"
        title={
          isOrderDirectionAsc
            ? t('anomaly_plugin.widget.history.sort.asc.text')
            : t('anomaly_plugin.widget.history.sort.desc.text')
        }
      >
        <IconButton
          aria-label={
            isOrderDirectionAsc
              ? t('anomaly_plugin.widget.history.sort.asc.text')
              : t('anomaly_plugin.widget.history.sort.desc.text')
          }
          iconOnly
          onClick={() => switchDirection()}
        >
          <Icon fontSize="small" component={isOrderDirectionAsc ? ArrowUpIcon : ArrowDownIcon} />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};
