import { useTeiaViewerContext } from '@stereograph/teiaviewer';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CommentApi } from '../CommentApi';
import { commentQueryKeys } from '../queryKeys/comment.query-keys';

export const useDeleteComment = (projectId: number) => {
  const { viewer } = useTeiaViewerContext();
  const client = viewer.twinApiClient;
  const queryClient = useQueryClient();

  const { mutateAsync: deleteComment } = useMutation({
    mutationFn: (params: { commentId: number; targetId: number }) =>
      CommentApi(client, projectId).deleteComment(params.commentId),
    onSuccess: async (_response, variables) => {
      await queryClient.invalidateQueries({
        queryKey: commentQueryKeys.comment(projectId, variables.targetId)
      });
    }
  });

  return {
    deleteComment
  };
};
