import { Text } from '@stereograph/teia-system-design/components/Typography';
import { Tooltip } from '@stereograph/teia-system-design/components/Tooltip';

interface AnomalyTabBarItemLabelProps {
  title: string;
}

export const AnomalyTabBarItemLabel = (props: AnomalyTabBarItemLabelProps) => {
  const { title } = props;

  return (
    <Tooltip title={title} placement="top">
      <Text
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          maxWidth: '150px',
          display: 'inline-block',
          textTransform: 'none'
        }}
      >
        {title}
      </Text>
    </Tooltip>
  );
};
