import { Button } from '@stereograph/teia-system-design/components/Button';
import { Stack } from '@stereograph/teia-system-design/components/Stack';
import { Text } from '@stereograph/teia-system-design/components/Typography';
import { usePluginTranslation } from '@translation';
import { ChangeEventHandler, DragEvent, useRef } from 'react';

const acceptedFileTypes = 'application/pdf, image/jpg, image/jpeg, image/png, video/mp4';

interface DropzoneProps {
  onFileChange: (files: Array<File>) => void;
}

export const Dropzone = (props: DropzoneProps) => {
  const { onFileChange } = props;
  const { t } = usePluginTranslation();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleDrop = (e: DragEvent) => {
    e.preventDefault();
    const files = e.dataTransfer.files;

    if (!files) {
      return;
    }

    onFileChange(Array.from(files));
  };

  const handleFileChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    if (!event.target.files) {
      return;
    }

    onFileChange(Array.from(event.target.files));
  };

  return (
    <Stack
      component="label"
      alignItems="center"
      justifyContent="center"
      spacing={1}
      sx={{
        border: (theme) => `2px dashed ${theme.palette.divider}`,
        borderRadius: '4px',
        width: '100%',
        minHeight: '150px',
        cursor: 'pointer'
      }}
      onDragOver={(e) => e.preventDefault()}
      onDrop={handleDrop}
    >
      <Button component="span">{t('anomaly_plugin.document.upload.cta_label')}</Button>
      <Text component={'span'} variant="body2">
        {t('anomaly_plugin.document.upload.dragndrop_text')}
      </Text>
      <input
        type="file"
        style={{ display: 'none' }}
        ref={fileInputRef}
        onChange={handleFileChange}
        multiple
        accept={acceptedFileTypes}
      />
    </Stack>
  );
};
