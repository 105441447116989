import { Stack } from '@stereograph/teia-system-design/components/Stack';
import { Spinner } from '@stereograph/teia-system-design/deprecated';
import { TeiaViewer, useViewerContext, useWidgetContext } from '@stereograph/teiaviewer';
import { usePluginTranslation } from '@translation';
import { useState } from 'react';
import { SelectionBanner } from '../../../components/SelectionBanner';
import { TeiaOpenDataForm } from './TeiaOpenDataForm';
import { TeiaOpenDataWidget } from './TeiaOpenDataWidget';

export const TeiaOpenDataWidgetContent = () => {
  const { viewer } = useViewerContext<TeiaViewer>();
  const { widget } = useWidgetContext<TeiaOpenDataWidget>();
  const { t } = usePluginTranslation();
  const [selection, _setSelection] = useState(viewer.scene.objectSelection);

  const closeWidget = () => {
    widget.close();
  };

  const onSubmitSuccess = () => {
    closeWidget();
  };

  return (
    <Stack
      sx={{
        width: '100%',
        height: '100%',
        overflowY: 'auto'
      }}
      spacing={4}
    >
      <SelectionBanner
        label={t('patch_data_plugin.selected_objects.label', {
          count: selection.length
        })}
        selection={selection}
      />
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          width: '100%',
          padding: 4
        }}
      >
        {selection.length > 0 ? (
          <TeiaOpenDataForm
            selection={selection}
            onSubmitSuccess={onSubmitSuccess}
            onCancel={closeWidget}
          />
        ) : (
          <Spinner size="lg" />
        )}
      </Stack>
    </Stack>
  );
};
