import { TeiaTwinApiClientProvider } from '@stereograph/teia-system-design/teia-components';
import { ApiClient } from '@stereograph/teia-system-design/utils';
import {
  CameraGizmo,
  Command,
  Environment,
  LoadFileCommand,
  LoadTwinCommand,
  TeiaAuthProvider,
  TeiaToolbar,
  TeiaViewer,
  TeiaViewport,
  useTeiaAuth
} from '@stereograph/teiaviewer';
import {
  ChatBotPlugin,
  OpenDataPlugin,
  PartialLoadingPlugin,
  PatchDataPlugin,
  QuantityTakeOffPlugin,
  TeiaAnomalyPlugin,
  TeiaSelectionInfoPlugin
} from '@stereograph/teiaviewer-plugins';
import React from 'react';
import { Toaster } from 'react-hot-toast';
import { ShowDevToolsWidgetBhavior } from './behaviors/ShowDevToolsWidgetBehavior';
import { ENV_VARIABLES } from './env';

const viewer = new TeiaViewer({
  debug: true,
  dev: {
    enableAuthentication: true
  }
});
viewer.addBehavior(new ShowDevToolsWidgetBhavior(viewer));
viewer.registerPlugin('qto', new QuantityTakeOffPlugin(viewer));
viewer.registerPlugin('patchData', new PatchDataPlugin(viewer));
viewer.registerPlugin('openData', new OpenDataPlugin(viewer));
viewer.registerPlugin('selectionInfo', new TeiaSelectionInfoPlugin(viewer));
viewer.registerPlugin('partialLoading', new PartialLoadingPlugin(viewer));
viewer.registerPlugin('anomaly', new TeiaAnomalyPlugin(viewer));
viewer.registerPlugin(
  'chatbot',
  new ChatBotPlugin(viewer, {
    tiaApiUrl: ENV_VARIABLES.VITE_ELLIOT_API_BASE_URL
  })
);

/**
 * App component setting up a viewer and a viewport
 * @returns
 */
export const App = () => {
  const apiClient = new ApiClient('https://teiatwin-develop.teia-solution.com/');
  return (
    <div className="st-h-screen st-w-screen">
      <Toaster
        position="top-right"
        toastOptions={{
          loading: { duration: Infinity },
          duration: 2500,
          style: { overflow: 'hidden' }
        }}
      />
      <TeiaAuthProvider defaultEnvironment={Environment.Develop}>
        <TeiaTwinApiClientProvider client={apiClient}>
          <AutoLoadScene />
          <TeiaViewport viewer={viewer}>
            <CameraGizmo />
            <TeiaToolbar
              sx={{
                position: 'absolute',
                bottom: '0.75rem',
                left: '50%',
                transform: 'translateX(-50%)'
              }}
            >
              <QuantityTakeOffPlugin.ToolbarItems.ShowQTOWidget />
              <PatchDataPlugin.ToolbarItems.ShowTeiaPatchDataWidget />
              <TeiaAnomalyPlugin.ToolbarItems.ShowTeiaAnomaly />
              <TeiaSelectionInfoPlugin.ToolbarItems.ShowTeiaSelectionRecordWidget />
              <PartialLoadingPlugin.ToolbarItems.LoadPartialTwin />
              <ChatBotPlugin.ToolbarItems.ChatBotToolbarItem />
            </TeiaToolbar>
          </TeiaViewport>
        </TeiaTwinApiClientProvider>
      </TeiaAuthProvider>
    </div>
  );
};

const AutoLoadScene = () => {
  const executeCommandsOnce = React.useRef(false);
  const { isAuthenticated } = useTeiaAuth();

  React.useEffect(() => {
    if (isAuthenticated) {
      if (!executeCommandsOnce.current) {
        executeCommandsOnce.current = true;
        setTimeout(() => {
          autoLoadTwinOrFile();
        }, 300);
      }
    }
  }, [isAuthenticated]);

  /**
   * Automatically loads a Twin or a File if there is projectId={pid}/fileId={fid} in the url.
   * @returns
   */
  const autoLoadTwinOrFile = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const projectId = urlParams.get('projectId');
    const fileId = urlParams.get('fileId');
    if (projectId !== null) {
      let cmd: Command = new LoadTwinCommand(viewer, Number(projectId), 'Draft');
      if (fileId !== null) {
        cmd = new LoadFileCommand(viewer, Number(projectId), Number(fileId));
      }
      cmd.execute();
    }
  };

  return <></>;
};
