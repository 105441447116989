import { Anomaly } from '@api/twin/AnomalyApi';
import { MRT_Row } from '@stereograph/teia-system-design/components/Table';
import { Stack } from '@stereograph/teia-system-design/components/Stack';
import { EditAnomalyButton } from './EditAnomalyButton';
import { VisualizeSelectionButton } from './VisualizeSelectionButton';

interface ActionsCellProps {
  row: MRT_Row<Anomaly>;
}

export const ActionsCell = (props: ActionsCellProps) => {
  const { row } = props;

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <EditAnomalyButton anomaly={row.original} />
      <VisualizeSelectionButton anomaly={row.original} />
    </Stack>
  );
};
