import { Comment, CommentQueryParams } from '@api/twin/CommentApi';
import { Stack } from '@stereograph/teia-system-design/components/Stack';
import { AnomalyCommentProvider } from '../../context/AnomalyCommentContext';
import { useAnomalySectionContext } from '../../context/AnomalySectionContext';
import { useGetAnomalyComments } from '../../hooks/useGetAnomalyComments';
import { AnomalyComment } from './AnomalyComment';

interface AnomalyCommentListProps {
  parentComment?: Comment;
  orderDirection?: CommentQueryParams['orderDirection'];
}

export const AnomalyCommentList = (props: AnomalyCommentListProps) => {
  const { parentComment, orderDirection = 'desc' } = props;
  const { anomaly } = useAnomalySectionContext();
  const { getAnomalyComments } = useGetAnomalyComments(anomaly.projectId);

  const commentQueryParams: CommentQueryParams = {
    pageNumber: 1,
    pageSize: 100,
    targetId: anomaly.id,
    targetType: 'Anomaly',
    parentId: parentComment?.id ?? 0,
    orderDirection: orderDirection
  };

  const comments = getAnomalyComments(commentQueryParams);

  if (!comments || comments.length === 0) {
    return null;
  }

  return (
    <Stack spacing={2}>
      {comments.map((comment) => (
        <AnomalyCommentProvider key={`anomaly-comment-provider-${comment.id}`} comment={comment}>
          <AnomalyComment key={`anomaly-comment-${comment.id}`} />
        </AnomalyCommentProvider>
      ))}
    </Stack>
  );
};
