import { useViewerPlugin } from '@stereograph/teiaviewer';
import { useQuery } from '@tanstack/react-query';
import { TiaQuotaApi } from './TiaQuotaApi';

export const TiaQuotaApiQueryKey = 'Quota';

const useTiaQuotaApi = () => {
  const { plugin } = useViewerPlugin('chatbot');
  const client = plugin.elliotAPIClient;
  return TiaQuotaApi(client);
};

/**
 * Returns the token quota for the current instance token
 * @returns
 */
export const useGetQuota = () => {
  const api = useTiaQuotaApi();
  return useQuery({
    queryKey: [TiaQuotaApiQueryKey],
    queryFn: () => api.getQuota()
  });
};
