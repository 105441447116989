import { Anomaly, RequestPostAnomaly } from '@api/twin/AnomalyApi';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box } from '@stereograph/teia-system-design/components/Box';
import { usePluginTranslation } from '@translation';
import { FormProvider, useForm } from 'react-hook-form';
import { useAnomalyApi } from '../../api/useAnomalyApi';
import { useAnomalySectionContext } from '../../context/AnomalySectionContext';
import { useAnomalyTabContext } from '../../context/AnomalyTabsContext';
import { useAnomalyWidgetContext } from '../../context/AnomalyWidgetContext';
import { AnomalyForm, AnomalyFormSchema, anomalyFormSchema } from './AnomalyForm';

export const UpdateAnomalyForm = () => {
  const { anomaly } = useAnomalySectionContext();
  const { projectId } = useAnomalyWidgetContext();
  const { patchAnomaly } = useAnomalyApi(projectId);
  const mutateAnomaly = patchAnomaly(anomaly.id);
  const { t } = usePluginTranslation();
  const { updateTab, createAnomalyTab } = useAnomalyTabContext();

  const methods = useForm<AnomalyFormSchema>({
    resolver: zodResolver(anomalyFormSchema),
    mode: 'onSubmit',
    defaultValues: {
      anomalySchema: {
        ...anomaly
      }
    }
  });
  const { handleSubmit } = methods;

  const onSubmit = (data: AnomalyFormSchema) => {
    const formAnomaly: RequestPostAnomaly = {
      ...anomaly,
      ...data.anomalySchema,
      createAnomalyViewPointCommand: {
        ...anomaly.anomalyViewPointDto
      }
    };

    mutateAnomaly.mutate(formAnomaly, {
      onSuccess: (_data, variables) => {
        const isAnomalyTitleUpdated = anomaly.title !== variables.title;

        if (isAnomalyTitleUpdated) {
          const updatedAnomaly: Anomaly = {
            ...anomaly,
            ...variables
          };
          updateTab(createAnomalyTab(updatedAnomaly, true));
        }
      }
    });
  };

  return (
    <Box sx={{ py: 2 }}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <AnomalyForm
            buttonLabel={t('anomaly_plugin.widget.form.submit.update.label')}
            snapshot={anomaly.snapshot}
          />
        </form>
      </FormProvider>
    </Box>
  );
};
