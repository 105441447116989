import { TrashIcon } from '@heroicons/react/24/outline';
import { Box } from '@stereograph/teia-system-design/components/Box';
import { Icon } from '@stereograph/teia-system-design/components/Icon';
import { IconButton } from '@stereograph/teia-system-design/components/IconButton';
import { Text } from '@stereograph/teia-system-design/components/Typography';
import { Tooltip } from '@stereograph/teia-system-design/components/Tooltip';
import { usePluginTranslation } from '@translation';
import { Fragment } from 'react/jsx-runtime';
import { formatBytes } from '../../utils/formatBytes';
import { AnomalyFileSizeStatus } from '../document/AnomalyFileSizeStatus';

interface UploadFileListProps {
  files: Array<File>;
  onRemoveFile: (file: File) => void;
}

export const UploadFileList = (props: UploadFileListProps) => {
  const { files, onRemoveFile } = props;
  const { t } = usePluginTranslation();

  return (
    <Box display="grid" gridTemplateColumns="2fr 1fr 1.5fr 0.5fr" gap={2}>
      {files.map((file, index) => (
        <Fragment key={`file-row-${file.name}-${index}`}>
          <Box sx={{ p: 2 }}>
            <Text>{file.name}</Text>
          </Box>

          <Box sx={{ p: 2 }}>
            <Text variant="body2" bold>
              {formatBytes(file.size)}
            </Text>
          </Box>

          <Box sx={{ p: 2 }}>
            <AnomalyFileSizeStatus file={file} />
          </Box>

          <Box sx={{ p: 2, textAlign: 'center' }}>
            <Tooltip
              title={t('anomaly_plugin.document.upload.file.remove.label')}
              placement="top"
              enterDelay={500}
              enterNextDelay={500}
            >
              <IconButton
                aria-label={t('anomaly_plugin.document.upload.file.remove.label')}
                color="error"
                onClick={() => onRemoveFile(file)}
                iconOnly
              >
                <Icon component={TrashIcon} sx={{ height: 16, width: 16 }} />
              </IconButton>
            </Tooltip>
          </Box>
        </Fragment>
      ))}
    </Box>
  );
};
