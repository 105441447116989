import { Flow, FlowConfig, ModelError, ModelValidationError } from '@api/tia/TiaFlowApi';
import { useCreateFlow, useFlowConfigJsonSchema, useUpdateFlow } from '@api/tia/TiaFlowApiHooks';
import { Alert } from '@stereograph/teia-system-design/components/Alert';
import { Box } from '@stereograph/teia-system-design/components/Box';
import { Button } from '@stereograph/teia-system-design/components/Button';
import { TextField } from '@stereograph/teia-system-design/components/TextField';
import { Title } from '@stereograph/teia-system-design/components/Typography';
import { ChangeEvent, useState } from 'react';
import { useFlowConfigContext } from './FlowConfigContext';
import { JsonEditor } from './JsonEditor';

export interface FlowEditorProps {
  flow: Flow;
  mode: 'update' | 'create';
}

export const FlowEditorView = (props: FlowEditorProps) => {
  const { flow, mode } = props;
  const { setMode } = useFlowConfigContext();
  const { data: schema } = useFlowConfigJsonSchema();
  const [name, setName] = useState(flow.name);
  const [errors, setErrors] = useState<Array<ModelError>>([]);
  const [textConfig, setTextConfig] = useState<string>(JSON.stringify(flow.config, null, 2));
  const {
    mutateAsync: createFlow,
    isPending: isCreating,
    isError: isCreatingError
  } = useCreateFlow();
  const {
    mutateAsync: updateFlow,
    isPending: isUpdating,
    isError: isUpdatingError
  } = useUpdateFlow();

  const title = mode === 'create' ? 'Create flow' : 'Update flow';
  const isValidating = isCreating || isUpdating;
  const isError = isCreatingError || isUpdatingError;
  const canValidate = name !== '' && textConfig !== '';

  const onSuccess = () => {
    setMode('ListFlows');
  };

  const onError = (e: Error) => {
    const rep = e.cause as Response;
    rep.json().then((validationError: ModelValidationError) => {
      console.error(validationError);
      setErrors(validationError.detail);
    });
  };

  const onValidate = () => {
    const config: FlowConfig = JSON.parse(textConfig);
    if (mode === 'create') {
      createFlow({ name, config }, { onSuccess, onError });
    } else if (mode === 'update') {
      updateFlow({ flow: { id: flow.id, name, config, editable: true } }, { onSuccess, onError });
    }
  };

  const onNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const onCancel = () => {
    setMode('ListFlows');
  };

  return (
    <Box sx={{ width: 1, height: 1, display: 'flex', flexDirection: 'column', p: 1, gap: 1 }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 1 }}>
        <Title>{title}</Title>
      </Box>
      <TextField
        label="Flow name"
        placeholder="Enter flow name..."
        value={name}
        onChange={onNameChange}
      />
      {isError && (
        <Alert severity="error">
          Incorrect config
          <ul>
            {errors.map((e, idx) => (
              <li key={'error' + idx}>{e.msg.replace('Input', e.loc.slice(2).join('.'))}</li>
            ))}
          </ul>
        </Alert>
      )}
      <JsonEditor
        sx={{ flexGrow: 1, width: 1 }}
        schema={schema}
        value={textConfig}
        onChange={setTextConfig}
      />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 5 }}>
        <Button color="info" sx={{ px: 5 }} onClick={onCancel}>
          Cancel
        </Button>
        <Button
          sx={{ px: 5 }}
          onClick={onValidate}
          isLoading={isValidating}
          disabled={!canValidate}
        >
          Validate
        </Button>
      </Box>
    </Box>
  );
};
