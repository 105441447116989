import {
  ChatBubbleLeftIcon,
  InformationCircleIcon,
  WrenchScrewdriverIcon
} from '@heroicons/react/24/outline';
import { Box } from '@stereograph/teia-system-design/components/Box';
import { TabBar, TabBarItem, TabContainer, TabContent } from '../components/TabBar';
import { ChatTabView } from './chat/ChatTabView';
import { ConfigTabView } from './config/ConfigTabView';
import { InfoTabView } from './info/InfoTabView';

type Mode = 'chat' | 'config' | 'info';

export function ChatBotWidgetContent() {
  return (
    <Box
      sx={{
        width: 1,
        height: 1
      }}
    >
      <TabContainer<Mode> defaultValue="chat">
        <TabBar>
          <TabBarItem<Mode> icon={ChatBubbleLeftIcon} value="chat" />
          <TabBarItem<Mode> icon={WrenchScrewdriverIcon} value="config" />
          <TabBarItem<Mode> icon={InformationCircleIcon} value="info" />
        </TabBar>
        <TabContent<Mode> value="chat">
          <ChatTabView />
        </TabContent>
        <TabContent<Mode> value="config">
          <ConfigTabView />
        </TabContent>
        <TabContent<Mode> value="info">
          <InfoTabView />
        </TabContent>
      </TabContainer>
    </Box>
  );
}
