import { Button } from '@stereograph/teia-system-design/components/Button';
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader
} from '@stereograph/teia-system-design/components/Modal';
import { Stack } from '@stereograph/teia-system-design/components/Stack';
import { Text } from '@stereograph/teia-system-design/components/Typography';
import { usePluginTranslation } from '@translation';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useAnomalyCommentContext } from '../../context/AnomalyCommentContext';
import { useAnomalySectionContext } from '../../context/AnomalySectionContext';
import { useAnomalyWidgetContext } from '../../context/AnomalyWidgetContext';
import { useDeleteAnomalyComment } from '../../hooks/useDeleteAnomalyComment';

interface AnomalyDeleteCommentModalProps {
  isModalOpen: boolean;
  onClose: () => void;
}

interface FormValues {
  commentId: number;
}

export const AnomalyDeleteCommentModal = (props: AnomalyDeleteCommentModalProps) => {
  const { isModalOpen, onClose } = props;
  const { comment } = useAnomalyCommentContext();
  const { anomaly } = useAnomalySectionContext();
  const { projectId } = useAnomalyWidgetContext();
  const { deleteAnomalyComment } = useDeleteAnomalyComment(projectId, anomaly.id);
  const { t } = usePluginTranslation();

  const methods = useForm<FormValues>({
    mode: 'onSubmit',
    defaultValues: {
      commentId: comment.id
    }
  });

  const { handleSubmit } = methods;

  const onDeleteComment: SubmitHandler<FormValues> = (data: FormValues) => {
    deleteAnomalyComment(data.commentId);
  };

  return (
    <Modal open={isModalOpen} onClose={onClose} closeOnBackdropClick>
      <ModalHeader>{t('anomaly_plugin.widget.comment_form.delete.modal.title')}</ModalHeader>
      <ModalContent>
        <Text>{t('anomaly_plugin.widget.comment_form.delete.modal.text')}</Text>
      </ModalContent>
      <ModalFooter>
        <form onSubmit={handleSubmit(onDeleteComment)}>
          <Stack direction="row" spacing={2} justifyContent="center">
            <Button onClick={onClose} color="info">
              {t('anomaly_plugin.document.delete.modal.cancel_button.label')}
            </Button>
            <Button type="submit" color="error">
              {t('anomaly_plugin.document.delete.modal.delete_button.label')}
            </Button>
          </Stack>
        </form>
      </ModalFooter>
    </Modal>
  );
};
