import { usePluginTranslation } from '@translation';
import { AnomalyHistoryItemBase } from './AnomalyHistoryItemBase';

export const AnomalyHistoryItemCreated = () => {
  const { t } = usePluginTranslation();

  return (
    <AnomalyHistoryItemBase
      chipColor="primary"
      chipLabel={t('anomaly_plugin.widget.history.created.label')}
    ></AnomalyHistoryItemBase>
  );
};
