import { TwinType } from '@api/twin/TwinApi';
import { TeiaViewerSourceType, useTeiaViewerContext, ViewerSignal } from '@stereograph/teiaviewer';
import { createContext, ReactNode, useContext, useMemo } from 'react';
import { useTwinTransformersApi } from '../api/useTwinTransformersApi';

export interface AnomalyWidgetContext {
  projectId: number;
  twinProjectType: TwinType;
  fileIds: Array<number>;
  onAnomalyCreationSignal: ViewerSignal<() => void>;
}

export interface AnomalyWidgetContextProviderProps {
  projectId: number;
  children: ReactNode;
  onAnomalyCreationSignal: ViewerSignal<() => void>;
}

const Context = createContext<AnomalyWidgetContext | null>(null);

export const AnomalyWidgetContextProvider = (props: AnomalyWidgetContextProviderProps) => {
  const { projectId, onAnomalyCreationSignal, children } = props;
  const { viewer } = useTeiaViewerContext();
  const { getTwinFiles } = useTwinTransformersApi(projectId);

  const twinProjectType: TwinType =
    viewer.currentSource?.sourceType === TeiaViewerSourceType.Twin
      ? viewer.currentSource?.type
      : 'Draft';

  const { data: twinFiles, isLoading } = getTwinFiles({
    pageNumber: 1,
    pageSize: 1000,
    twinProjectType
  });

  const fileIds =
    viewer.currentSource?.sourceType === TeiaViewerSourceType.File
      ? [viewer.currentSource?.fileId]
      : twinFiles;

  const context = useMemo(
    () => ({
      projectId,
      twinProjectType,
      fileIds: fileIds,
      onAnomalyCreationSignal
    }),
    [projectId, twinProjectType, fileIds, onAnomalyCreationSignal]
  );

  if (isLoading) {
    return null;
  }

  return <Context.Provider value={context}>{children}</Context.Provider>;
};

export const useAnomalyWidgetContext = () => {
  const context = useContext(Context);

  if (!context) {
    throw new Error('Anomaly widget context not found');
  }

  return context;
};
