import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { IconButton } from '@stereograph/teia-system-design/components/IconButton';
import { usePluginTranslation } from '@translation';
import { useState } from 'react';
import { createPortal } from 'react-dom';
import { TeiaDeleteSelectionFilterModal } from './TeiaDeleteSelectionFilterModal';

interface DeleteSelectionButtonProps {
  selectionFilterId: number;
}

export const DeleteSelectionButton = (props: DeleteSelectionButtonProps) => {
  const { selectionFilterId } = props;
  const { t } = usePluginTranslation();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const onShowDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };

  const onClose = () => {
    setIsDeleteModalOpen(false);
  };

  return (
    <>
      <IconButton
        aria-label={t('selection_plugin.record_widget.actions.delete.label')}
        color="error"
        onClick={onShowDeleteModal}
      >
        <DeleteOutlineOutlinedIcon />
      </IconButton>
      {createPortal(
        <TeiaDeleteSelectionFilterModal
          isModalOpen={isDeleteModalOpen}
          onClose={onClose}
          selectionFilterId={selectionFilterId}
        />,
        document.getElementById('app') as Element
      )}
    </>
  );
};
