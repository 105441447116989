import { Button } from '@stereograph/teia-system-design/components/Button';
import { Message } from '@stereograph/teia-system-design/components/Message';
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader
} from '@stereograph/teia-system-design/components/Modal';
import { useTwinSource } from '@stereograph/teiaviewer';
import { usePluginTranslation } from '@translation';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useSelectionFiltersApi } from '../api/useSelectionFilterApi';

interface DeleteSelectionFilterModalProps {
  isModalOpen: boolean;
  onClose: () => void;
  selectionFilterId: number;
}

interface FormValues {
  selectionFilterId: number;
}

export const TeiaDeleteSelectionFilterModal = (props: DeleteSelectionFilterModalProps) => {
  const { isModalOpen, onClose, selectionFilterId } = props;
  const { t } = usePluginTranslation();
  const { projectId } = useTwinSource();
  const {
    useDeleteFilters: { mutateAsync }
  } = useSelectionFiltersApi(projectId);

  const methods = useForm<FormValues>({
    defaultValues: { selectionFilterId: selectionFilterId },
    mode: 'onChange'
  });

  const handleDeleteSelection: SubmitHandler<FormValues> = (data) => {
    mutateAsync(
      { selectionFilterId: data.selectionFilterId },
      {
        onSuccess: () => {
          onCloseModal();
        }
      }
    );
  };

  const onCloseModal = () => {
    methods.reset();
    onClose();
  };

  return (
    <Modal fullWidth={true} open={isModalOpen} onClose={onCloseModal}>
      <form onSubmit={methods.handleSubmit(handleDeleteSelection)}>
        <ModalHeader>
          {t('selection_plugin.record_widget.selection_delete.modal.title')}
        </ModalHeader>
        <ModalContent>
          <Message color={'error'}>
            {t('selection_plugin.record_widget.selection_delete.modal.banner.text')}
          </Message>
        </ModalContent>
        <ModalFooter>
          <Button type="button" color={'info'} onClick={onCloseModal}>
            {t('selection_plugin.record_widget.selection_delete.modal.cancel_button.label')}
          </Button>
          <Button type="submit" color="error">
            {t('selection_plugin.record_widget.selection_delete.modal.validate_button.label')}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};
