import { DSProvider } from '@stereograph/teia-system-design/components/DSProvider';
import i18next from 'i18next';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './App';
import './i18n';
import './index.css';

const domNode = document.getElementById('app');
if (domNode) {
  const root = createRoot(domNode);
  root.render(
    <React.StrictMode>
      <DSProvider i18n={i18next}>
        <App />
      </DSProvider>
    </React.StrictMode>
  );
}
