import { AnomalyHistoryProperty } from '@api/twin/AnomalyApi';
import { ArrowLongRightIcon } from '@heroicons/react/24/solid';
import { Icon } from '@stereograph/teia-system-design/components/Icon';
import { Text } from '@stereograph/teia-system-design/components/Typography';
import { usePluginTranslation } from '@translation';
import { AnomalyHistoryItemBase } from './AnomalyHistoryItemBase';

interface AnomalyHistoryItemUpdatedProps {
  property: AnomalyHistoryProperty;
}

export const AnomalyHistoryItemUpdated = (props: AnomalyHistoryItemUpdatedProps) => {
  const { property } = props;
  const { t } = usePluginTranslation();

  return (
    <AnomalyHistoryItemBase
      chipColor="warning"
      chipLabel={t('anomaly_plugin.widget.history.updated.label')}
    >
      <Text bold>{property.propertyName}</Text>
      <Icon component={ArrowLongRightIcon} sx={{ width: 15 }} />
      <Text color="grey">{property.oldValue}</Text>
      <Icon component={ArrowLongRightIcon} sx={{ width: 15 }} />
      <Text>{property.newValue}</Text>
    </AnomalyHistoryItemBase>
  );
};
