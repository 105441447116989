import { DocumentIcon, FilmIcon, PhotoIcon } from '@heroicons/react/24/outline';
import { Icon } from '@stereograph/teia-system-design/components/Icon';
import { Stack } from '@stereograph/teia-system-design/components/Stack';

interface AnomalyDocumentBannerProps {
  type: File['type'];
}

export const AnomalyDocumentBanner = (props: AnomalyDocumentBannerProps) => {
  const { type } = props;
  const typeLower = type.toLowerCase();

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{
        minHeight: 80,
        backgroundColor: 'secondary.light',
        borderRadius: 1
      }}
    >
      {(typeLower === 'jpg' || typeLower === 'jpeg' || typeLower === 'png') && (
        <Icon component={PhotoIcon} />
      )}
      {typeLower === 'mp4' && <Icon component={FilmIcon} />}
      {typeLower === 'pdf' && <Icon component={DocumentIcon} />}
    </Stack>
  );
};
