import { XMarkIcon } from '@heroicons/react/24/outline';
import { Icon } from '@stereograph/teia-system-design/components/Icon';
import { IconButton } from '@stereograph/teia-system-design/components/IconButton';
import { Tab, TabProps } from '@stereograph/teia-system-design/components/Tabs';
import { usePluginTranslation } from '@translation';
import { MouseEvent } from 'react';
import { AnomalyTab, useAnomalyTabContext } from '../../context/AnomalyTabsContext';
import { AnomalyTabBarItemLabel } from './AnomalyTabBarItemLabel';

interface AnomalyTabBarItemProps extends TabProps {
  anomalyTab: AnomalyTab;
}

export const AnomalyTabBarItem = (props: AnomalyTabBarItemProps) => {
  const { anomalyTab, ...otherProps } = props;
  const { t } = usePluginTranslation();
  const { handleCloseTab } = useAnomalyTabContext();

  return (
    <Tab
      {...otherProps}
      sx={{
        minWidth: 0,
        minHeight: '40px',
        height: '40px'
      }}
      iconPosition="end"
      label={<AnomalyTabBarItemLabel title={anomalyTab.label} />}
      icon={
        anomalyTab.closable ? (
          <IconButton
            aria-label={t('anomaly_plugin.widget.tabs.close.label')}
            iconOnly
            onClick={(event: MouseEvent) => {
              event.stopPropagation();
              handleCloseTab(anomalyTab);
            }}
          >
            <Icon component={XMarkIcon} />
          </IconButton>
        ) : undefined
      }
    />
  );
};
