import { usePluginTranslation } from '@translation';

type DateFormatOption = 'numeric' | '2-digit';

interface DateTimeFormatOptions {
  localeMatcher?: 'best fit' | 'lookup';
  weekday?: 'long' | 'short' | 'narrow';
  era?: 'long' | 'short' | 'narrow';
  year?: DateFormatOption;
  month?: DateFormatOption | 'long' | 'short' | 'narrow';
  day?: DateFormatOption;
  hour?: DateFormatOption;
  minute?: DateFormatOption;
  second?: DateFormatOption;
  timeZoneName?: 'short' | 'long' | 'shortOffset' | 'longOffset' | 'shortGeneric' | 'longGeneric';
  formatMatcher?: 'best fit' | 'basic';
  hour12?: boolean;
  timeZone?: string;
}

export const usei18nDate = () => {
  const { t } = usePluginTranslation();
  const format = (date: Date, options?: DateTimeFormatOptions) => {
    return t('date', {
      val: date,
      formatParams: {
        val: {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          ...options
        }
      }
    });
  };
  return { format };
};
