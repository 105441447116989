import { Anomaly } from '@api/twin/AnomalyApi';
import { Box } from '@stereograph/teia-system-design/components/Box';
import { MRT_Cell } from '@stereograph/teia-system-design/components/Table';
import { Text } from '@stereograph/teia-system-design/components/Typography';

interface ClampedTextCellProps {
  cell: MRT_Cell<Anomaly, string>;
}

export const ClampedTextCell = (props: ClampedTextCellProps) => {
  const { cell } = props;

  return (
    <Box
      sx={{
        height: '7em',
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <Text
        sx={{
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 3,
          overflow: 'hidden'
        }}
      >
        {cell.getValue()}
      </Text>
    </Box>
  );
};
