import { Box } from '@stereograph/teia-system-design/components/Box';
import { Text } from '@stereograph/teia-system-design/components/Typography';
import { ReactNode } from 'react';

export interface MessageHeaderProps {
  children?: ReactNode;
  title: string;
}

export const MessageHeader = (props: MessageHeaderProps) => {
  const { children, title } = props;
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Text fontWeight={500}>{title}</Text>
      {children}
    </Box>
  );
};
