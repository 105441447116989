import { TwinApi, TwinSearchDownloadExportInput } from '@api/twin/TwinApi';
import { useTeiaViewerContext, useTwinSource } from '@stereograph/teiaviewer';
import { useQuery } from '@tanstack/react-query';
import { teiaQuantityTakeoffQueryKeys } from '../utils/teiaQuantityTakeoffQueryKeys';

export const useTwinSearchExport = (filter: TwinSearchDownloadExportInput) => {
  const twin = useTwinSource();
  const { viewer } = useTeiaViewerContext();
  const client = viewer.twinApiClient;
  return useQuery({
    queryKey: teiaQuantityTakeoffQueryKeys.download(filter.rule, filter.columns, filter.group),
    queryFn: async () => {
      const searchExportResponse = await TwinApi(client).downloadExport(twin, filter);
      const headerDisposition = searchExportResponse.headers.get('Content-Disposition');
      let filename = `export-qto-${Date.now()}.xlsx`;
      if (headerDisposition && headerDisposition.indexOf('attachment') !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(headerDisposition);

        if (matches?.[1]) {
          filename = matches[1].replace(/['"]/g, '');
        }
      }
      const blob = await searchExportResponse.blob();
      return {
        fileName: filename,
        blob: blob
      };
    },
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retryOnMount: false,
    retry: 0,
    enabled: false
  });
};
