import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import { IconButton } from '@stereograph/teia-system-design/components/IconButton';
import { useTwinSource } from '@stereograph/teiaviewer';
import { usePluginTranslation } from '@translation';
import { useState } from 'react';
import { createPortal } from 'react-dom';
import { useSelectionFiltersApi } from '../api/useSelectionFilterApi';
import { TeiaUpdateSelectionModal } from './TeiaUpdateSelectionModal';

interface UpdateSelectionButtonProps {
  selectionFilterId: number;
}

export const UpdateSelectionButton = (props: UpdateSelectionButtonProps) => {
  const { selectionFilterId } = props;
  const { t } = usePluginTranslation();
  const [modalOpen, setModalOpen] = useState(false);

  const { projectId } = useTwinSource();
  const { useGetSelectionFilter } = useSelectionFiltersApi(projectId);

  const { data: selection } = useGetSelectionFilter(selectionFilterId, { enabled: modalOpen });

  return (
    <>
      <IconButton
        aria-label={t('selection_plugin.record_widget.actions.update.label')}
        onClick={() => setModalOpen(true)}
      >
        <CreateOutlinedIcon />
      </IconButton>
      {modalOpen &&
        selection &&
        createPortal(
          <TeiaUpdateSelectionModal
            selection={selection}
            isModalOpen={modalOpen}
            onClose={() => setModalOpen(false)}
          />,
          document.getElementById('app') as Element
        )}
    </>
  );
};
