import { AnomalyApi } from '@api/twin/AnomalyApi';
import { useTeiaViewerContext } from '@stereograph/teiaviewer';
import { useSuspenseInfiniteQuery } from '@tanstack/react-query';
import { AnomalyDocument } from '../components/document/AnomalyDocumentCard';
import { useAnomalySectionContext } from '../context/AnomalySectionContext';
import { anomalyQueryKeys } from '../query-keys';

export const useGetAllAnomalyDocuments = () => {
  const { anomaly } = useAnomalySectionContext();
  const {
    viewer: { twinApiClient }
  } = useTeiaViewerContext();

  const { data, isLoading, hasNextPage, fetchNextPage } = useSuspenseInfiniteQuery({
    queryKey: anomalyQueryKeys.documents(anomaly.id),
    initialPageParam: 1,
    queryFn: async ({ pageParam }) => {
      const response = await AnomalyApi(twinApiClient).getAnomalyAttachments(
        anomaly.projectId,
        anomaly.id,
        {
          pageNumber: pageParam
        }
      );

      return response;
    },
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.pageNumber + 1;
      if (nextPage > lastPage.totalPages) {
        return undefined;
      }

      return nextPage;
    }
  });

  if (hasNextPage) {
    fetchNextPage();
  }

  return {
    documents:
      data?.pages.flatMap((page) =>
        page?.data.map(
          (attachment) =>
            ({
              id: attachment.id,
              title: attachment.title,
              type: attachment.extension,
              createdAt: new Date(attachment.creationDate)
            }) satisfies AnomalyDocument
        )
      ) ?? [],
    isLoading: isLoading
  };
};
