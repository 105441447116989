import QuantityTakeoffIcon from '@resources/icons/QuantityTakeOffAggregateIcon.svg?react';
import {
  OpenWidgetCommand,
  TeiaViewerSourceType,
  ToolbarItem,
  useTeiaSource,
  useTeiaViewerContext,
  useViewerPlugin,
  useViewerSelection
} from '@stereograph/teiaviewer';
import { usePluginTranslation } from '@translation';
import { uuidsToAdvancedSearch } from './utils/uuidsToAdvancedSearch';

export const ShowTeiaQuantityTakeoffWidgetToolbarItem = () => {
  const { t } = usePluginTranslation();
  const { viewer } = useTeiaViewerContext();
  const { selection } = useViewerSelection();
  const { plugin } = useViewerPlugin('qto');
  const twin = useTeiaSource(TeiaViewerSourceType.Twin);

  const QTO_MAX_OBJECTS_SELECTION_SIZE = plugin.settings.maxObjectsLimit.value;
  const onClick = () => {
    const command = new OpenWidgetCommand(viewer, 'TeiaQuantityTakeOff', {
      searchRules: uuidsToAdvancedSearch(viewer.scene.objectSelection.getUuids())
    });
    command.execute();
  };

  let tooltip = '';
  if (selection.length > QTO_MAX_OBJECTS_SELECTION_SIZE) {
    tooltip = t('quantity_takeoff_plugin.footer_info_text', {
      limit: QTO_MAX_OBJECTS_SELECTION_SIZE
    });
  }

  return (
    <ToolbarItem
      disabled={twin === null || selection.length > QTO_MAX_OBJECTS_SELECTION_SIZE}
      icon={QuantityTakeoffIcon}
      onClick={onClick}
      label={t('toolbar.quantity_takeoff.tooltip')}
      tooltip={tooltip}
    />
  );
};
