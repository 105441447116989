import { MessageFeedback } from '@api/tia/TiaConversationApi';
import { ThumbDownAlt, ThumbDownOffAlt, ThumbUpAlt, ThumbUpOffAlt } from '@mui/icons-material';
import { IconButton } from '@stereograph/teia-system-design/components/IconButton';
import { Tooltip } from '@stereograph/teia-system-design/components/Tooltip';

export interface MessageFeedbackControlProps {
  value: MessageFeedback | null;
  isLoading: boolean;
  onChange: (value: MessageFeedback) => void;
}

export const MessageFeedbackControl = (props: MessageFeedbackControlProps) => {
  const { value, onChange, isLoading } = props;
  return (
    <div className="st-flex st-float-right">
      {
        <Tooltip title="Validate answer">
          <IconButton
            iconOnly
            aria-label="Validate answer"
            color={value === 'negative' || isLoading ? 'info' : 'primary'}
            onClick={() => {
              onChange('positive');
            }}
          >
            {value === 'positive' ? <ThumbUpAlt /> : <ThumbUpOffAlt />}
          </IconButton>
        </Tooltip>
      }
      {
        <Tooltip title="Invalidate answer">
          <IconButton
            iconOnly
            aria-label="Invalidate answer"
            color={value === 'positive' || isLoading ? 'info' : 'error'}
            onClick={() => {
              onChange('negative');
            }}
          >
            {value === 'negative' ? <ThumbDownAlt /> : <ThumbDownOffAlt />}
          </IconButton>
        </Tooltip>
      }
    </div>
  );
};
