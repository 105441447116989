import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/24/outline';
import { usePluginTranslation } from '@translation';
import { useAnomalyCommentContext } from '../../context/AnomalyCommentContext';
import { AnomalyCommentActionsButton } from './AnomalyCommentActionsButton';

export const AnomalyCommentOpenRepliesButton = () => {
  const { t } = usePluginTranslation();
  const { comment, isResponseListOpened, toggleResponseList } = useAnomalyCommentContext();

  return (
    <AnomalyCommentActionsButton
      icon={isResponseListOpened ? ArrowUpIcon : ArrowDownIcon}
      title={t('anomaly_plugin.widget.comment_form.answer.labels.label', {
        count: comment.responsesLength
      })}
      onClick={toggleResponseList}
    />
  );
};
