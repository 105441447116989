import { Stack } from '@stereograph/teia-system-design/components/Stack';
import { Text } from '@stereograph/teia-system-design/components/Typography';
import { ReactNode } from 'react';

interface AnomalySectionLayoutProps {
  title: string;
  children?: ReactNode;
  headerRight?: ReactNode;
}

export const AnomalySectionLayout = (props: AnomalySectionLayoutProps) => {
  const { title, children, headerRight } = props;

  return (
    <Stack spacing={4} sx={{ padding: 4, height: '100%' }}>
      <Stack direction="row" spacing={6} alignItems="center">
        <Text variant="lead" bold>
          {title}
        </Text>
        {headerRight}
      </Stack>
      {children}
    </Stack>
  );
};
