import { AttachmentApi } from '@api/twin/AttachmentApi';
import { useTeiaViewerContext } from '@stereograph/teiaviewer';
import { useSuspenseQuery } from '@tanstack/react-query';
import { AnomalyDocument } from '../components/document/AnomalyDocumentCard';
import { useAnomalyCommentContext } from '../context/AnomalyCommentContext';
import { anomalyQueryKeys } from '../query-keys';

export const useGetAnomalyCommentDocuments = () => {
  const { comment } = useAnomalyCommentContext();
  const {
    viewer: { twinApiClient }
  } = useTeiaViewerContext();

  const { data, isLoading } = useSuspenseQuery({
    queryKey: anomalyQueryKeys.commentDocuments(comment.id),
    queryFn: async () => {
      const response = await AttachmentApi(twinApiClient).getAll(comment.projectId, {
        targetId: comment.id,
        targetType: 'Comment',
        orderBy: 'id',
        orderDirection: 'desc'
      });
      return response.data.map(
        (attachment): AnomalyDocument =>
          ({
            id: attachment.id,
            title: attachment.title,
            type: attachment.extension,
            createdAt: new Date(attachment.creationDate)
          }) satisfies AnomalyDocument
      );
    },
    staleTime: 60 * 1000,
    gcTime: 5 * 60 * 1000
  });

  return {
    documents: data ?? [],
    isLoading: isLoading
  };
};
