import { AnomalyFileType } from '@api/twin/AnomalyApi';
import { useEffect, useRef } from 'react';
import { useAnomalyApi } from '../api/useAnomalyApi';

interface Column {
  title: string;
  value: string;
}

export interface UseDownloadSearchExport {
  guids: Array<string>;
  tableColumns: Array<string>;
  groupBy: Column | null;
}

export const useExportAnomalies = (
  anomalyIds: Array<number>,
  projectId: number,
  urlExportType: AnomalyFileType
) => {
  const lastFileUpdate = useRef(Date.now());
  const { exportAnomalies } = useAnomalyApi(projectId);

  const {
    data: file,
    refetch: downloadExport,
    isLoading: isDownloading,
    dataUpdatedAt
  } = exportAnomalies(anomalyIds, urlExportType);

  const downloadFile = () => {
    if (!file) {
      return;
    }

    const url = window.URL.createObjectURL(file.blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = file.fileName;
    a.click();
    a.remove();
  };

  useEffect(() => {
    if (file !== undefined && dataUpdatedAt > lastFileUpdate.current) {
      lastFileUpdate.current = dataUpdatedAt;
      downloadFile();
    }
  }, [file]);

  const downloadAnomaly = () => {
    if (isDownloading) {
      return;
    }
    return downloadExport();
  };

  return { downloadAnomaly, isDownloading };
};
