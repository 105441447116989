import { TwinTransformersApi, TwinTransformersQueryParams } from '@api/twin/TwinTransformersApi';
import { useTeiaViewerContext } from '@stereograph/teiaviewer';
import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';

const queryKeys = ['Anomaly'];

export const useTwinTransformersApi = (projectId: number) => {
  const { viewer } = useTeiaViewerContext();
  const client = viewer.twinApiClient;

  const getTwinFiles = (twinTransformersQueryParams: TwinTransformersQueryParams) => {
    let pageSize = 1000;
    const { data, isLoading } = useInfiniteQuery({
      queryKey: [...queryKeys, projectId, twinTransformersQueryParams],
      queryFn: () => {
        return TwinTransformersApi(client).getTwinTransformers(projectId, {
          ...twinTransformersQueryParams,
          pageSize
        });
      },
      initialPageParam: 1,
      getNextPageParam: (lastPage) => {
        pageSize = lastPage.pageSize;
        const nextPage = lastPage.pageNumber + 1;
        if (nextPage > lastPage.totalPages) {
          return undefined;
        }
        return nextPage;
      },
      placeholderData: keepPreviousData
    });

    const fileIds = new Set(
      data?.pages.flatMap((page) => page.data.map((twinTransformer) => twinTransformer.fileId))
    );

    return {
      data: Array.from(fileIds),
      isLoading
    };
  };

  return {
    getTwinFiles
  };
};
