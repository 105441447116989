import {
  IsAllOptional,
  OpenWidgetCommand,
  OpenWidgetCommandOptions,
  WidgetFactoryFunction
} from '@stereograph/teiaviewer';
import { TeiaAnomalyResumeWidget } from '../widgets/TeiaAnomalyResumeWidget';

type CommandParams<T> =
  T extends WidgetFactoryFunction<any, infer TParams>
    ? IsAllOptional<TParams> extends true
      ? [params?: TParams & OpenWidgetCommandOptions]
      : [params: TParams & OpenWidgetCommandOptions]
    : never;

export class OpenAnomalyWidgetCommand<TType extends ViewerWidgetType> extends OpenWidgetCommand<
  TType,
  ViewerWidgetRegistry[TType],
  CommandParams<ViewerWidgetRegistry[TType]>
> {
  protected override async _do() {
    if (this.viewer.viewport) {
      const widgets = Array.from(
        this.viewer.viewport.widgetController.getWidgetsOfType('TeiaAnomalyResume')
      );

      if (widgets.length === 1) {
        const anomalyWidget: TeiaAnomalyResumeWidget = widgets[0] as TeiaAnomalyResumeWidget;
        anomalyWidget.onCreateAnomalySignal.emit();
      } else {
        await super._do();
      }
    }
  }
}
