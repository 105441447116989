import { Stack } from '@stereograph/teia-system-design/components/Stack';
import { useGetAnomalyCommentDocuments } from '../../hooks/useGetAnomalyCommentDocuments';
import { AnomalyCommentFile } from './AnomalyCommentFile';

export const AnomalyCommentFiles = () => {
  const { documents, isLoading } = useGetAnomalyCommentDocuments();

  if (isLoading || !documents || documents.length === 0) {
    return null;
  }

  return (
    <Stack direction="row" spacing={1} flexWrap="wrap">
      {documents.map((document) => (
        <AnomalyCommentFile document={document} key={`anomaly-comment-document-${document.id}`} />
      ))}
    </Stack>
  );
};
