import { CommentQueryParams } from '@api/twin/CommentApi';
import { useGetComments } from '@api/twin/hooks/useGetComments';

export const useGetAnomalyComments = (projectId: number) => {
  const getAnomalyComments = (commentQueryParams: CommentQueryParams) => {
    const { data } = useGetComments(projectId, commentQueryParams);

    if (!data) {
      return null;
    }

    return data.pages.flatMap((page) => page.data);
  };

  return {
    getAnomalyComments
  };
};
