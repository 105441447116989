import { Text } from '@stereograph/teia-system-design/components/Typography';
import { TypewriterText } from '../common/TypewriterText';

export interface MessageTextProps {
  content: string;
  animated?: boolean;
}

export const MessageText = (props: MessageTextProps) => {
  const { content, animated = false } = props;

  if (animated) {
    return <TypewriterText content={content} speed={7} />;
  } else {
    return (
      <Text fontSize={14} style={{ userSelect: 'text' }}>
        {content}
      </Text>
    );
  }
};
