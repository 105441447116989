import { OpenDataApi, OpenDataRequest } from '@api/twin/OpenDataApi';
import { Twin, TwinType } from '@api/twin/TwinApi';
import { AsyncTaskQueue, useTeiaViewerContext, useViewerPlugin } from '@stereograph/teiaviewer';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { openDataQueryKeys } from '../openData.query-keys';

const queryKeys = ['OpenData'];

/**
 * Generator function that splits an array into chunks of n elements.
 */
function* chunks<T>(arr: Array<T>, n: number): Generator<Array<T>, void> {
  for (let i = 0; i < arr.length; i += n) {
    yield arr.slice(i, i + n);
  }
}

export const useOpenDataApi = (twin: Twin) => {
  const { viewer } = useTeiaViewerContext();
  const { plugin } = useViewerPlugin('openData');
  const OPENDATA_GUIDS_CHUNK_SIZE = plugin.settings.guidChunkSize.value;
  const client = viewer.twinApiClient;
  const queryClient = useQueryClient();

  const { mutateAsync: submitOpenData } = useMutation({
    mutationFn: async (t: { openDataRequest: OpenDataRequest }) => {
      /**
       * The OpenData API has a limit of twinObjectGuids per request.
       * We need to split the request into chunks of 1000 twinObjectGuids.
       * We then send a request for each chunk.
       * This is done to avoid the limit of twinObjectGuids per request.
       * @see https://stereograph.atlassian.net/browse/DEV-6812
       *
       **/
      const taskQueue = new AsyncTaskQueue({
        delayBetweenTasks: 150,
        concurrency: plugin.settings.maxPostRequestParallelism.value
      });
      const totalChunks = Math.ceil(
        t.openDataRequest.twinObjectGuids.length / OPENDATA_GUIDS_CHUNK_SIZE
      );

      const tasks = [
        ...chunks<string>(t.openDataRequest.twinObjectGuids, OPENDATA_GUIDS_CHUNK_SIZE)
      ].map(async (guidsChunk, index) => {
        const nameSuffix = totalChunks > 1 ? ` (${index + 1}/${totalChunks})` : '';
        return taskQueue.addTask(() =>
          OpenDataApi(client).postOpenData(twin.projectId, twin.type, {
            ...t.openDataRequest,
            name: `${t.openDataRequest.name}${nameSuffix}`,
            twinObjectGuids: guidsChunk
          })
        );
      });

      taskQueue.dequeue(true);
      return () => Promise.all(tasks);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [...queryKeys, twin.projectId] });
      queryClient.invalidateQueries({
        queryKey: openDataQueryKeys.propertyKeys(twin.projectId, twin.type)
      });
    }
  });

  const getOpenData = (projectId: number, twinType: TwinType, twinObjectGuid: string) => {
    if (!twinObjectGuid) {
      return { data: null, isLoading: false };
    }
    return useQuery({
      queryKey: [...queryKeys, twin.projectId, twinType, twinObjectGuid],
      queryFn: () => OpenDataApi(client).getOpenData(projectId, twinType, twinObjectGuid)
    });
  };

  return {
    submitOpenData,
    getOpenData
  };
};
