import { Stack } from '@stereograph/teia-system-design/components/Stack';
import {
  ObjectSelection,
  useTeiaViewerContext,
  ViewportScreenshotCommand
} from '@stereograph/teiaviewer';
import { usePluginTranslation } from '@translation';
import { useEffect, useRef, useState } from 'react';
import { SelectionBanner } from '../../../../components/SelectionBanner';
import { useAnomalyPerspectiveCamera } from '../../hooks/useAnomalyPerspectiveCamera';
import { useFormatAnomalyGuids } from '../../hooks/useFormatAnomalyGuids';
import { CreateAnomalyForm } from './CreateAnomalyForm';

export const CreateAnomaly = () => {
  const { viewer } = useTeiaViewerContext();
  const { t } = usePluginTranslation();
  const selectionWhenOpened = useRef<ObjectSelection>(viewer.scene.objectSelection);
  const [snapshot, setSnapshot] = useState('');
  const camera = useRef(useAnomalyPerspectiveCamera());
  const anomalyGuids = useFormatAnomalyGuids(viewer);

  useEffect(() => {
    getSnapshot();
  }, []);

  const getSnapshot = async () => {
    const image = await new ViewportScreenshotCommand(viewer).execute();
    setSnapshot(image);
  };

  return (
    <Stack
      direction="column"
      spacing={4}
      sx={{
        padding: 4,
        height: '100%'
      }}
    >
      {selectionWhenOpened.current && selectionWhenOpened.current.length > 0 && (
        <SelectionBanner
          selection={selectionWhenOpened.current}
          label={t('patch_data_plugin.selected_objects.label', {
            count: selectionWhenOpened.current.length
          })}
        />
      )}
      {snapshot && camera && (
        <CreateAnomalyForm
          snapshot={snapshot}
          anomalyGuids={anomalyGuids}
          camera={camera.current}
        />
      )}
    </Stack>
  );
};
