import { AttachmentApi } from '@api/twin/AttachmentApi';
import { useTeiaViewerContext, useTwinSource } from '@stereograph/teiaviewer';
import { AnomalyDocument } from '../components/document/AnomalyDocumentCard';

export const useDownloadDocument = () => {
  const {
    viewer: { twinApiClient }
  } = useTeiaViewerContext();
  const twin = useTwinSource();
  const attachmentApi = AttachmentApi(twinApiClient);

  const download = async (attachment: AnomalyDocument) => {
    const response = await attachmentApi.download(twin.projectId, attachment.id);
    const fileToDownload = await response.blob();

    const url = URL.createObjectURL(fileToDownload);
    const a = document.createElement('a');
    a.href = url;
    a.download = attachment.title;
    a.click();
  };

  return {
    download: download
  };
};
