import { useDownloadDocument } from '../../../hooks/useDownloadDocument';
import { AnomalyDocument } from '../AnomalyDocumentCard';

export const useDownloadAllDocuments = () => {
  const { download } = useDownloadDocument();

  const downloadDocuments = (documents: Array<AnomalyDocument>) => {
    documents.forEach((document) => {
      download(document);
    });
  };
  return { downloadDocuments };
};
