import { PatchDataAction } from '@api/twin/PatchDataApi';
import { PlusIcon } from '@heroicons/react/24/outline';
import { IconButton } from '@stereograph/teia-system-design/components/IconButton';
import { Stack } from '@stereograph/teia-system-design/components/Stack';
import { Text } from '@stereograph/teia-system-design/components/Typography';
import { Alert } from '@stereograph/teia-system-design/deprecated';
import { TeiaSearchFilter } from '@stereograph/teia-system-design/utils';
import { usePluginTranslation } from '@translation';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { PatchDataSchema } from '../widgets/TeiaPatchDataWidgetForm';
import { PatchDataActionField } from './PatchDataActionField';

interface PatchDataActionFieldProps {
  filter: TeiaSearchFilter;
}

export const PatchDataActionFieldList = (props: PatchDataActionFieldProps) => {
  const { t } = usePluginTranslation();
  const { filter } = props;
  const { control } = useFormContext<PatchDataSchema>();

  const { fields, remove, append } = useFieldArray({
    control,
    name: 'patchDataSchema.properties'
  });

  return (
    <Stack spacing={4}>
      <Stack spacing={8}>
        {fields.map((field, index) => (
          <PatchDataActionField index={index} removeField={remove} filter={filter} key={field.id} />
        ))}
        {fields.length === 0 && (
          <Alert variant="info">
            <Text>
              <Trans t={t} i18nKey="patch_data_plugin.no_fields.text" components={{ 1: <br /> }} />
            </Text>
          </Alert>
        )}
      </Stack>
      <Stack alignItems="center" justifyContent="center">
        <IconButton
          aria-label={t('patch_data_plugin.form.property.add_property_label')}
          onClick={() =>
            append({
              action: PatchDataAction.Create,
              name: '',
              propertyName: '',
              value: ''
            })
          }
        >
          <PlusIcon className="st-h-8 st-w-8" />
        </IconButton>
      </Stack>
    </Stack>
  );
};
