import { SelectionFilter } from '@api/twin/SelectionFilterApi';
import { MRT_Row } from '@stereograph/teia-system-design/components/Table';
import { Stack } from '@stereograph/teia-system-design/components/Stack';
import { DeleteSelectionButton } from './DeleteSelectionButton';
import { ShowSelectionButton } from './ShowSelectionButton';
import { UpdateSelectionButton } from './UpdateSelectionButton';

interface SelectionActionsCellProps {
  row: MRT_Row<SelectionFilter>;
}

export const SelectionActionsCell = (props: SelectionActionsCellProps) => {
  const { row } = props;

  return (
    <Stack justifyContent={'end'} alignItems={'center'} spacing={2} direction={'row'}>
      <ShowSelectionButton filter={row.original.filter} />
      <UpdateSelectionButton selectionFilterId={row.original.id} />
      <DeleteSelectionButton selectionFilterId={row.original.id} />
    </Stack>
  );
};
