import { Chip } from '@stereograph/teia-system-design/components/Chip';

interface AnomalyHistoryChipProps {
  color: 'primary' | 'warning' | 'error';
  label: string;
}

export const AnomalyHistoryChip = (props: AnomalyHistoryChipProps) => {
  const { color, label } = props;

  return <Chip color={color} size="small" sx={{ width: '7rem' }} label={label} />;
};
