import { Stack } from '@stereograph/teia-system-design/components/Stack';
import { useAnomalyCommentContext } from '../../context/AnomalyCommentContext';
import { useCanUpdateOrDeleteComment } from '../../hooks/useCanDeleteComment';
import { AnomalyAddCommentAttachmentButton } from './AnomalyAddCommentAttachmentButton';
import { AnomalyAnswerCommentButton } from './AnomalyAnswerCommentButton';
import { AnomalyCommentOpenRepliesButton } from './AnomalyCommentOpenRepliesButton';
import { DeleteAnomalyCommentForm } from './DeleteAnomalyCommentForm';

export const AnomalyCommentActionBar = () => {
  const { comment } = useAnomalyCommentContext();
  const canUpdateOrDeleteComment = useCanUpdateOrDeleteComment(comment.createdBy);

  return (
    <Stack direction="row" spacing={1} sx={{ pl: -1 }}>
      {comment.responsesLength && comment.responsesLength > 0 && (
        <AnomalyCommentOpenRepliesButton />
      )}
      {comment.parentId === 0 && <AnomalyAnswerCommentButton />}
      {canUpdateOrDeleteComment && (
        <>
          <AnomalyAddCommentAttachmentButton />
          <DeleteAnomalyCommentForm />
        </>
      )}
    </Stack>
  );
};
