import { TrashIcon } from '@heroicons/react/24/outline';
import { Icon } from '@stereograph/teia-system-design/components/Icon';
import { IconButton } from '@stereograph/teia-system-design/components/IconButton';
import { usePluginTranslation } from '@translation';
import { useState } from 'react';
import { AnomalyDocument } from './AnomalyDocumentCard';
import { AnomalyDocumentDeleteModal } from './AnomalyDocumentDeletionModal';

interface AnomalyDocumentCardDeleteProps {
  document: AnomalyDocument;
}

export const AnomalyDocumentCardDelete = (props: AnomalyDocumentCardDeleteProps) => {
  const { document } = props;
  const { t } = usePluginTranslation();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  return (
    <>
      <IconButton
        onClick={() => setIsDeleteModalOpen(true)}
        aria-label={t('anomaly_plugin.document.download_label')}
        iconOnly
      >
        <Icon component={TrashIcon} fontSize={'small'} />
      </IconButton>
      <AnomalyDocumentDeleteModal
        isModalOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        document={document}
      />
    </>
  );
};
