import { SelectionFilter } from '@api/twin/SelectionFilterApi';
import { Checkbox } from '@stereograph/teia-system-design/components/Checkbox';
import { Controller } from 'react-hook-form';
import { useSelectionFormContext } from '../../hooks/useQuantityTakeoffSelectionFormContext';

interface SelectionCheckboxProps {
  selection: SelectionFilter;
}

export const SelectionCheckbox = (props: SelectionCheckboxProps) => {
  const { selection } = props;
  const { control } = useSelectionFormContext();

  const isSameSelectionId = (id: string, selection: SelectionFilter) => {
    return id === String(selection.id);
  };
  return (
    <Controller
      key={selection.id}
      render={({ field: { value, onChange, name } }) => (
        <Checkbox
          label={selection.name}
          id={selection.name}
          value={String(selection.id)}
          name={name}
          checked={value.includes(String(selection.id))}
          onChange={() => {
            if (!value.includes(String(selection.id))) {
              onChange([...value, String(selection.id)]);
              return;
            }

            const newSelections = value.filter((id) => !isSameSelectionId(id, selection));
            onChange(newSelections);
          }}
        />
      )}
      name={'selections'}
      control={control}
      defaultValue={[]}
    />
  );
};
