import { DocumentTextIcon } from '@heroicons/react/24/outline';
import { Box, SxProps } from '@mui/material';
import { Button } from '@stereograph/teia-system-design/components/Button';
import { Icon } from '@stereograph/teia-system-design/components/Icon';
import { langs, loadLanguage } from '@uiw/codemirror-extensions-langs';
import CodeMirror, { EditorView } from '@uiw/react-codemirror';
import { jsonSchema } from 'codemirror-json-schema';
import { KeyboardEvent } from 'react';

loadLanguage('json');

export interface FlowConfigEditorProps {
  sx?: SxProps;
  value: string;
  onChange: (value: string) => void;
  schema?: any;
}

export const JsonEditor = (props: FlowConfigEditorProps) => {
  const { value, onChange, sx, schema } = props;

  const onCodeChange = (value: string) => {
    onChange(value);
  };

  const onKeyDown = (e: KeyboardEvent) => {
    e.stopPropagation();
  };

  const onFormat = () => {
    const data = JSON.parse(value);
    onChange(JSON.stringify(data, null, 2));
  };

  return (
    <Box sx={{ ...sx, display: 'flex', flexDirection: 'column', overflowY: 'scroll' }}>
      <Box sx={{ display: 'flex', justifyContent: 'end' }}>
        <Button onClick={onFormat} aria-label="Format" color="info" variant="text">
          <Icon component={DocumentTextIcon} />
          Format JSON
        </Button>
      </Box>
      <Box
        sx={{
          overflow: 'auto'
        }}
      >
        <CodeMirror
          style={{
            border: '1px solid lightgray',
            height: '100%',
            width: '100%'
          }}
          tabIndex={0}
          theme="light"
          value={value}
          onChange={onCodeChange}
          onKeyDown={onKeyDown}
          extensions={[langs.json(), jsonSchema(schema), EditorView.lineWrapping]}
          basicSetup={{
            foldGutter: true,
            dropCursor: false,
            allowMultipleSelections: true,
            indentOnInput: true,
            autocompletion: true,
            bracketMatching: true,
            closeBrackets: true
          }}
        />
      </Box>
    </Box>
  );
};
