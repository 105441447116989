import { ApiClient, ApiDataList, TeiaSearchFilter } from '@stereograph/teia-system-design/utils';

export enum SelectionFilterType {
  STATIC = 'Static',
  DYNAMIC = 'Dynamic'
}

export enum SelectionSortOrder {
  ASC = 'asc',
  DESC = 'desc'
}

export interface SelectionFilter {
  id: number;
  name: string;
  type: SelectionFilterType;
  filter: TeiaSearchFilter;
  isPublic: boolean;
}

export type NewSelectionFilter = Omit<SelectionFilter, 'id'>;

export const SelectionFilterApi = (client: ApiClient) => ({
  getSelectionFilter(projectId: number, selectionFilterId: number) {
    const url = client.URL(`projects/${projectId}/selectionfilters/${selectionFilterId}`);
    const request = new Request(url, {
      method: 'GET'
    });
    return client.sendRequest<SelectionFilter>(request);
  },

  getSelectionFilters(
    projectId: number,
    pageNumber: number,
    pageSize: number,
    searchParams?: {
      sortField?: string;
      sortOrder?: SelectionSortOrder;
      name?: string;
      type?: SelectionFilterType;
      isPublic?: boolean;
    }
  ) {
    const { name, type, isPublic, sortField, sortOrder } = searchParams ?? {};
    const url = client.URL(`projects/${projectId}/selectionfilters`);
    if (name !== undefined) {
      url.searchParams.set('name', name);
    }
    if (type !== undefined) {
      url.searchParams.set('type', type);
    }
    if (isPublic !== undefined) {
      url.searchParams.set('isPublic', isPublic.toString());
    }
    if (sortField && sortOrder) {
      url.searchParams.set('sortField', sortField);
      url.searchParams.set('sortOrder', sortOrder);
    }
    url.searchParams.set('pageNumber', String(pageNumber));
    url.searchParams.set('pageSize', String(pageSize));
    const request = new Request(url, {
      method: 'GET'
    });
    return client.sendRequest<ApiDataList<SelectionFilter>>(request);
  },

  deleteSelectionFilter(projectId: number, selectionFilterId: number) {
    const url = client.URL(`projects/${projectId}/selectionfilters/${selectionFilterId}`);
    const request = new Request(url, {
      method: 'DELETE'
    });
    return client.sendRequest(request);
  },

  postSelectionFilter(projectId: number, selectionFilter: NewSelectionFilter) {
    const url = client.URL(`projects/${projectId}/selectionfilters`);
    const request = new Request(url, {
      method: 'POST',
      body: JSON.stringify(selectionFilter),
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return client.sendRequest<SelectionFilter>(request);
  },

  patchSelectionFilter(projectId: number, selectionFilter: SelectionFilter) {
    const url = client.URL(`projects/${projectId}/selectionfilters/${selectionFilter.id}`);
    const request = new Request(url, {
      method: 'PATCH',
      body: JSON.stringify(selectionFilter),
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return client.sendRequest<SelectionFilter>(request);
  }
});
