import { OrderDirection } from '@api/twin/AnomalyApi';
import { usePluginTranslation } from '@translation';
import { useState } from 'react';
import { AnomalySectionLayout } from '../tabs/AnomalySectionLayout';
import { AnomalyHistoryList } from './AnomalyHistoryList';
import { SwitchOrderDirectionButton } from './SwitchOrderDirectionButton';

export const AnomalyHistory = () => {
  const { t } = usePluginTranslation();
  const [orderDirection, setOrderDirection] = useState<OrderDirection>('asc');

  return (
    <AnomalySectionLayout
      title={t('anomaly_plugin.widget.sections.history_section.title')}
      headerRight={
        <SwitchOrderDirectionButton orderDirection={orderDirection} onChange={setOrderDirection} />
      }
    >
      <AnomalyHistoryList orderDirection={orderDirection} />
    </AnomalySectionLayout>
  );
};
