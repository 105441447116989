import Divider from '@mui/material/Divider/Divider';
import { Tabs } from '@stereograph/teia-system-design/components/Tabs';
import { usePluginTranslation } from '@translation';
import { Children, SyntheticEvent } from 'react';
import { useAnomalyTabContext } from '../../context/AnomalyTabsContext';
import { AnomalyTabBarItem } from './AnomalyTabBarItem';

export const AnomalyTabs = () => {
  const { selectedTab, setSelectedTab, openTabs } = useAnomalyTabContext();
  const { t } = usePluginTranslation();

  const onChange = (_event: SyntheticEvent, newValue: string) => {
    const getTabWithValue = openTabs.find((anomalyTab) => anomalyTab.value === newValue) ?? {
      label: t('anomaly_plugin.resume_widget.tab.title'),
      value: 'list'
    };
    setSelectedTab(getTabWithValue);
  };

  return (
    <Tabs
      value={selectedTab.value}
      orientation="horizontal"
      variant="scrollable"
      scrollButtons={false}
      onChange={onChange}
      sx={{
        width: '100%',
        minWidth: 'auto',
        borderBottom: 'solid 1px lightgrey',
        minHeight: '40px',
        height: '40px'
      }}
    >
      {Children.toArray(
        openTabs.flatMap((anomalyTab, index) => [
          index > 0 && (
            <Divider
              key={`divider-${anomalyTab.value}`}
              orientation="vertical"
              sx={{ height: 22, alignSelf: 'center' }}
            />
          ),
          <AnomalyTabBarItem
            key={`anomaly-tabBarItem-${anomalyTab.value}`}
            anomalyTab={anomalyTab}
            value={anomalyTab.value}
          />
        ])
      )}
    </Tabs>
  );
};
