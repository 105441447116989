import { Box } from '@stereograph/teia-system-design/components/Box';
import { Stack } from '@stereograph/teia-system-design/components/Stack';
import { useAnomalyCommentContext } from '../../context/AnomalyCommentContext';
import { AnomalyCommentContent } from './AnomalyCommentContent';
import { AnomalyCommentHeading } from './AnomalyCommentHeading';
import { AnomalyCommentList } from './AnomalyCommentList';
import { CreateAnomalyCommentForm } from './CreateAnomalyCommentForm';

export const AnomalyComment = () => {
  const { comment, isResponseListOpened, toggleResponseList, isAnswering, toggleAnswering } =
    useAnomalyCommentContext();

  const hasResponses = comment.responsesLength && comment.responsesLength > 0;

  return (
    <Stack direction="column" sx={{ width: '100%' }} spacing={2}>
      <AnomalyCommentHeading />
      <AnomalyCommentContent />
      {isResponseListOpened && hasResponses && (
        <Box sx={{ pl: 8, py: 4 }}>
          <AnomalyCommentList parentComment={comment} orderDirection="asc" />
        </Box>
      )}
      {isAnswering && (
        <Box sx={{ pl: 10, py: 4 }}>
          <CreateAnomalyCommentForm
            parentId={comment.id}
            focused
            onClose={() => {
              toggleAnswering();
              toggleResponseList();
            }}
          />
        </Box>
      )}
    </Stack>
  );
};
