import { InfoOutlined } from '@mui/icons-material';
import { IconButton } from '@stereograph/teia-system-design/components/IconButton';
import { Paper } from '@stereograph/teia-system-design/components/Paper';
import { Popover } from '@stereograph/teia-system-design/components/Popover';
import { Stack } from '@stereograph/teia-system-design/components/Stack';
import { Text } from '@stereograph/teia-system-design/components/Typography';
import { useState } from 'react';
import { AssistantMessage } from '../ChatMessage';

export interface MessageInfoPanelProps {
  message: AssistantMessage;
}

export const MessageInfoPanel = (props: MessageInfoPanelProps) => {
  const { message } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <IconButton color="info" iconOnly aria-label="Invalidate answer" onClick={handleClick}>
        <InfoOutlined />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
      >
        <Paper sx={{ p: 2 }}>
          <Stack>
            <Text>Input tokens: {message.promptTokens.toLocaleString()}</Text>
            <Text>Output tokens: {message.completionTokens.toLocaleString()}</Text>
            <Text>Tool calls: {message.tools.length}</Text>
            <Text>Attachments: {message.attachments.length}</Text>
          </Stack>
        </Paper>
      </Popover>
    </>
  );
};
