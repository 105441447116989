import { Stack } from '@stereograph/teia-system-design/components/Stack';
import { Text } from '@stereograph/teia-system-design/components/Typography';
import { Avatar } from '@stereograph/teia-system-design/deprecated';
import { usePluginTranslation } from '@translation';
import { useAnomalyCommentContext } from '../../context/AnomalyCommentContext';

export const AnomalyCommentHeading = () => {
  const { comment } = useAnomalyCommentContext();
  const { t } = usePluginTranslation();

  return (
    <Stack direction="row" spacing={3} alignItems="center">
      <Avatar userName={comment.username} />
      <Stack direction="row" spacing={1}>
        <Text variant="body2" bold>
          {comment.username}
        </Text>
        <Text variant="body2" bold>
          &bull;
        </Text>
        <Text
          variant="body2"
          sx={{
            fontStyle: 'italic',
            color: (theme) => theme.palette.text.secondary
          }}
        >
          {t('date', {
            val: new Date(comment.creationDate),
            formatParams: {
              val: {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: 'numeric',
                minute: 'numeric'
              }
            }
          })}
        </Text>
      </Stack>
    </Stack>
  );
};
