import { Alert } from '@stereograph/teia-system-design/components/Alert';
import { Stack } from '@stereograph/teia-system-design/components/Stack';
import { TeiaViewer, useViewerContext } from '@stereograph/teiaviewer';
import { usePluginTranslation } from '@translation';
import { AnomalyTabs } from '../components/tabs/AnomalyTabs';
import { AnomalyTabsContentList } from '../components/tabs/AnomalyTabsContentList';

export const TeiaAnomalyResumeWidgetContent = () => {
  const { viewer } = useViewerContext<TeiaViewer>();
  const source = viewer.currentSource;
  const { t } = usePluginTranslation();

  if (!source) {
    return <Alert severity="info">{t('anomaly_plugin.null_source.text')}</Alert>;
  }

  return (
    <Stack sx={{ height: 1, width: 1 }}>
      <AnomalyTabs />
      <AnomalyTabsContentList />
    </Stack>
  );
};
