import { TrashIcon } from '@heroicons/react/24/outline';
import { usePluginTranslation } from '@translation';
import { useState } from 'react';
import { AnomalyCommentActionsButton } from './AnomalyCommentActionsButton';
import { AnomalyDeleteCommentModal } from './AnomalyDeleteCommentModal';

export const DeleteAnomalyCommentForm = () => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { t } = usePluginTranslation();

  return (
    <>
      <AnomalyCommentActionsButton
        icon={TrashIcon}
        title={t('anomaly_plugin.widget.comment_form.submit.delete.label')}
        onClick={() => setIsDeleteModalOpen(true)}
      />
      <AnomalyDeleteCommentModal
        isModalOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
      />
    </>
  );
};
