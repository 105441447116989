import { ApiClient } from '@stereograph/teia-system-design/utils';
import { TwinType } from './TwinApi';

export interface OpenDataProperty {
  key: string;
  value: string;
  action: OpenDataAction;
}

export interface OpenDataRequest {
  name: string;
  twinObjectGuids: Array<string>;
  properties: Array<OpenDataProperty>;
}

export interface OpenDataResponse {
  id: number;
}

export enum OpenDataAction {
  Add = 'Add',
  Update = 'Update',
  Delete = 'Delete',
  AddOrUpdate = 'AddOrUpdate'
}

export interface OpenDataKeysResponse {
  keys: Array<string>;
  afterKey?: string;
}

export const OpenDataApi = (client: ApiClient) => ({
  postOpenData(projectId: number, twinType: TwinType, openData: OpenDataRequest) {
    const url = client.URL(`projects/${projectId}/opendata`);
    url.searchParams.set('twinProjectType', twinType);

    const request = new Request(url, {
      method: 'POST',
      body: JSON.stringify(openData),
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return client.sendRequest<OpenDataResponse>(request);
  },

  getOpenData(projectId: number, twinType: TwinType, twinObjectGuid: string) {
    const url = client.URL(`projects/${projectId}/twin/opendata/${twinObjectGuid}`);
    url.searchParams.set('twinProjectType', twinType);
    url.searchParams.set('twinObjectGuid', twinObjectGuid);

    const request = new Request(url, {
      method: 'GET'
    });

    return client.sendRequest<Array<OpenDataProperty>>(request);
  },

  getOpenDataKeys(projectId: number, twinType: TwinType, afterKey?: string) {
    const url = client.URL(`projects/${projectId}/twin/opendata/keys`);
    url.searchParams.set('twinProjectType', twinType);
    if (afterKey) {
      url.searchParams.set('afterKey', afterKey);
    }

    const request = new Request(url, {
      method: 'POST',
      body: JSON.stringify({}),
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return client.sendRequest<OpenDataKeysResponse>(request);
  }
});
