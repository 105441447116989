import { useViewerPlugin } from '@stereograph/teiaviewer';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Flow, FlowConfig, TiaFlowApi } from './TiaFlowApi';

export const TiaFlowsApiQueryKey = 'Flows';

const useTiaFlowApi = () => {
  const { plugin } = useViewerPlugin('chatbot');
  const client = plugin.elliotAPIClient;
  return TiaFlowApi(client);
};

export const useGetAllFlows = () => {
  const api = useTiaFlowApi();
  return useQuery({
    queryKey: [TiaFlowsApiQueryKey],
    queryFn: () => api.getAllFlows()
  });
};

export const useFlowConfigJsonSchema = () => {
  const api = useTiaFlowApi();
  return useQuery({
    queryKey: [TiaFlowsApiQueryKey, 'FlowConfigJsonSchema'],
    queryFn: () => api.getConfigJsonSchema()
  });
};

export const useCreateFlow = () => {
  const api = useTiaFlowApi();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (t: { name: string; config: FlowConfig }) => {
      const result = await api.createFlow(t.name, t.config);
      await queryClient.invalidateQueries({ queryKey: [TiaFlowsApiQueryKey] });
      return result;
    }
  });
};

export const useUpdateFlow = () => {
  const api = useTiaFlowApi();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (t: { flow: Flow }) => {
      const result = await api.updateFlow(t.flow);
      await queryClient.invalidateQueries({ queryKey: [TiaFlowsApiQueryKey] });
      return result;
    }
  });
};

export const useDeleteFlow = () => {
  const api = useTiaFlowApi();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (t: { flow: Flow }) => {
      const result = await api.deleteFlow(t.flow);
      await queryClient.invalidateQueries({ queryKey: [TiaFlowsApiQueryKey] });
      return result;
    }
  });
};
