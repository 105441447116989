import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import { Box } from '@stereograph/teia-system-design/components/Box';
import { Icon } from '@stereograph/teia-system-design/components/Icon';
import { Tab, TabProps, Tabs } from '@stereograph/teia-system-design/components/Tabs';
import {
  createContext,
  FC,
  ReactNode,
  SVGProps,
  SyntheticEvent,
  useContext,
  useMemo,
  useState
} from 'react';

interface TabContext<TValue> {
  selectedValue: TValue;
  setSelectedValue: (index: TValue) => void;
}

const Context = createContext<TabContext<any> | null>(null);

export interface TabContainerProps<TValue> {
  defaultValue: TValue;
  children?: ReactNode;
}

export const TabContainer = <TValue,>(props: TabContainerProps<TValue>) => {
  const { defaultValue, children } = props;
  const [selectedValue, setSelectedValue] = useState(defaultValue);

  const ctx = useMemo(
    () => ({ selectedValue, setSelectedValue }),
    [selectedValue, setSelectedValue]
  );

  return (
    <Context.Provider value={ctx}>
      <Box sx={{ height: 1, width: 1, display: 'flex', overflowY: 'auto' }}>{children}</Box>
    </Context.Provider>
  );
};

const useTabContext = <TValue,>() => {
  const CastedContext = Context as unknown as React.Context<TabContext<TValue>>;
  const ctx = useContext<TabContext<TValue>>(CastedContext);
  if (!ctx) {
    throw new Error('TabContext not found');
  }
  return ctx;
};

interface TabBarItemProps<TValue> extends Omit<TabProps, 'icon'> {
  value: TValue;
  icon: FC<SVGProps<SVGSVGElement>>;
}

export const TabBarItem = <TValue,>(props: TabBarItemProps<TValue>) => {
  const { icon, ...otherProps } = props;
  return (
    <Tab
      {...otherProps}
      sx={{ minWidth: 0 }}
      icon={<Icon component={icon ?? QuestionMarkCircleIcon} />}
    />
  );
};

interface TabBarProps {
  children?: ReactNode;
}

export const TabBar = <TValue,>(props: TabBarProps) => {
  const { children } = props;
  const { selectedValue, setSelectedValue } = useTabContext<TValue>();

  const onChange = (_event: SyntheticEvent, newValue: TValue) => {
    setSelectedValue(newValue);
  };

  return (
    <Tabs
      value={selectedValue}
      orientation="vertical"
      onChange={onChange}
      sx={{
        borderRight: 'solid 1px',
        borderColor: 'lightgrey',
        width: '50px',
        minWidth: '50px',
        height: '100%'
      }}
    >
      {children}
    </Tabs>
  );
};

interface TabContentProps<TValue> {
  value: TValue;
  children?: ReactNode;
}

export const TabContent = <TValue,>(props: TabContentProps<TValue>) => {
  const { value, children } = props;
  const { selectedValue } = useTabContext<TValue>();

  const isVisible = value === selectedValue;

  return (
    <Box
      sx={{
        minWidth: 0,
        flexGrow: 1,
        display: isVisible ? 'inline' : 'none',
        overflowY: 'auto',
        height: '100%'
      }}
    >
      {children}
    </Box>
  );
};
