import { Stack } from '@stereograph/teia-system-design/components/Stack';
import { ObjectSelection, useTeiaViewerContext, useTwinSource } from '@stereograph/teiaviewer';
import { usePluginTranslation } from '@translation';
import { SelectionBanner } from '../../../components/SelectionBanner';
import { useTeiaQuantityTakeoffContext } from '../components/TeiaQuantityTakeoffProvider';
import { TeiaQuantityTakeoffTable } from '../components/TeiaQuantityTakeoffTable';
import { useTwinTotalObjects } from '../hooks/useTwinTotalObjects';
import { uuidsToAdvancedSearch } from '../utils/uuidsToAdvancedSearch';

export const TeiaQuantityTakeoffWidgetContent = () => {
  const { updateSearchRules } = useTeiaQuantityTakeoffContext();
  const { t } = usePluginTranslation();
  const { viewer } = useTeiaViewerContext();
  const twin = useTwinSource();
  const totalObjects = useTwinTotalObjects(twin?.filter ?? {});
  const selectedObjects = viewer.scene.objectSelection.length;

  const updateGuids = (objectSelection: ObjectSelection) => {
    updateSearchRules(uuidsToAdvancedSearch(objectSelection.getUuids()));
  };

  return (
    <Stack
      spacing={1}
      sx={{
        height: '100%',
        overflow: 'auto'
      }}
    >
      <SelectionBanner
        label={t('quantity_takeoff_plugin.selection_info.label', {
          count: selectedObjects === 0 ? totalObjects : selectedObjects,
          total: totalObjects
        })}
        selection={viewer.scene.objectSelection}
        onSelectionChange={updateGuids}
      />
      <TeiaQuantityTakeoffTable />
    </Stack>
  );
};
