import { usePluginTranslation } from '@translation';
import { useAnomalySectionContext } from '../../context/AnomalySectionContext';
import { AnomalyDocumentSection } from '../document/AnomalyDocumentSection';
import { AnomalySectionLayout } from '../tabs/AnomalySectionLayout';
import { AnomalySceneBanner } from './AnomalySceneBanner';
import { UpdateAnomalyForm } from './UpdateAnomalyForm';

export const UpdateAnomalySection = () => {
  const { anomaly } = useAnomalySectionContext();
  const { t } = usePluginTranslation();

  return (
    <AnomalySectionLayout title={t('anomaly_plugin.widget.sections.update_section.title')}>
      {anomaly.stateByGuid && Object.keys(anomaly.stateByGuid).length > 1 && (
        <AnomalySceneBanner anomalyGuids={anomaly.stateByGuid} />
      )}
      <UpdateAnomalyForm />
      <AnomalyDocumentSection />
    </AnomalySectionLayout>
  );
};
