import { Stack } from '@stereograph/teia-system-design/components/Stack';
import { AnomalyDocument, AnomalyDocumentCard } from './AnomalyDocumentCard';

interface AnomalyDocumentListProps {
  documents: Array<AnomalyDocument>;
}

export const AnomalyDocumentList = (props: AnomalyDocumentListProps) => {
  const { documents } = props;

  return (
    <Stack direction="row" flexWrap="wrap" spacing={4}>
      {documents.map((document) => (
        <AnomalyDocumentCard
          key={document.id}
          title={document.title}
          type={document.type}
          id={document.id}
          createdAt={document.createdAt}
        />
      ))}
    </Stack>
  );
};
