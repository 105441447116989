import { PlusCircleIcon } from '@heroicons/react/24/outline';
import { Icon } from '@stereograph/teia-system-design/components/Icon';
import { IconButton } from '@stereograph/teia-system-design/components/IconButton';
import { Tooltip } from '@stereograph/teia-system-design/components/Tooltip';
import { usePluginTranslation } from '@translation';
import { useState } from 'react';
import { useAnomalySectionContext } from '../../../context/AnomalySectionContext';
import { useUploadAnomalyDocument } from '../../../hooks/useUploadAnomalyDocument';
import { UploadModal } from '../../upload/UploadModal';

export const UploadDocumentAction = () => {
  const { t } = usePluginTranslation();
  const [open, setOpen] = useState(false);
  const { anomaly } = useAnomalySectionContext();
  const { uploadToAnomaly } = useUploadAnomalyDocument();

  const handleUpload = (files: Array<File>) => {
    if (!files || files.length === 0) {
      return;
    }
    uploadToAnomaly(anomaly, files);
  };

  return (
    <>
      <Tooltip title={t('anomaly_plugin.document.upload.document_label')}>
        <IconButton
          aria-label={t('anomaly_plugin.document.upload.document_label')}
          iconOnly
          onClick={() => setOpen(true)}
        >
          <Icon component={PlusCircleIcon} />
        </IconButton>
      </Tooltip>
      <UploadModal open={open} onClose={() => setOpen(false)} onUpload={handleUpload} />
    </>
  );
};
