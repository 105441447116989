import { AnomalyHistoryProperty } from '@api/twin/AnomalyApi';
import { ArrowLongRightIcon } from '@heroicons/react/24/solid';
import { Icon } from '@stereograph/teia-system-design/components/Icon';
import { Text } from '@stereograph/teia-system-design/components/Typography';
import { usePluginTranslation } from '@translation';
import { AnomalyHistoryItemBase } from './AnomalyHistoryItemBase';

interface AnomalyHistoryItemAddedProps {
  property: AnomalyHistoryProperty;
}

export const AnomalyHistoryItemAdded = (props: AnomalyHistoryItemAddedProps) => {
  const { property } = props;
  const { t } = usePluginTranslation();

  return (
    <AnomalyHistoryItemBase
      chipColor="primary"
      chipLabel={t('anomaly_plugin.widget.history.added.label')}
    >
      <Text variant="lead" bold>
        {property.propertyName}
      </Text>
      <Icon component={ArrowLongRightIcon} sx={{ width: 15 }} />
      <Text>{property.newValue}</Text>
    </AnomalyHistoryItemBase>
  );
};
