import { PaperClipIcon } from '@heroicons/react/24/outline';
import { usePluginTranslation } from '@translation';
import { useState } from 'react';
import { useAnomalyCommentContext } from '../../context/AnomalyCommentContext';
import { useUploadAnomalyDocument } from '../../hooks/useUploadAnomalyDocument';
import { UploadModal } from '../upload/UploadModal';
import { AnomalyCommentActionsButton } from './AnomalyCommentActionsButton';

export const AnomalyAddCommentAttachmentButton = () => {
  const { comment } = useAnomalyCommentContext();
  const [isUploadModalOpened, setIsUploadModalOpened] = useState(false);
  const { uploadToComment } = useUploadAnomalyDocument();
  const { t } = usePluginTranslation();

  const handleUpload = (files: Array<File>) => {
    if (!files || files.length === 0) {
      return;
    }
    uploadToComment(comment, files);
  };

  return (
    <>
      <AnomalyCommentActionsButton
        icon={PaperClipIcon}
        title={t('anomaly_plugin.widget.comment_form.attachment.button.label')}
        onClick={() => setIsUploadModalOpened(true)}
      />
      <UploadModal
        onClose={() => setIsUploadModalOpened(false)}
        open={isUploadModalOpened}
        onUpload={handleUpload}
      />
    </>
  );
};
