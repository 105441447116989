import { ApiClient, TeiaSearchFilter } from '@stereograph/teia-system-design/utils';

export type TwinType = 'Draft' | 'Master';

export interface Twin {
  projectId: number;
  type: TwinType;
}

export type TwinStatusType = 'Unknown' | 'InProgress' | 'Complete';

export type TwinOptimizationSatusType =
  | 'Unknown'
  | 'CacheNotFound'
  | 'Deprecated'
  | 'Pending'
  | 'InProgress'
  | 'Complete'
  | 'Degraded';

export interface TwinInfo {
  projectId: number;
  azimuth: number;
  refLatitude: number;
  refLongitude: number;
  elevation: number;
  status: TwinStatusType;
  optimizationStatus: TwinOptimizationSatusType;
  twinObjectsCount: number;
}

export interface TwinSearchExportInput {
  columns: Array<string>;
  group: string;
  rule: TeiaSearchFilter;
}

export interface TwinSearchDownloadExportInput extends TwinSearchExportInput {
  fileName?: string;
}

export interface TwinObjectQuantityTakeoffAggregate {
  name: string;
  count: number;
  min: number;
  max: number;
  sum: number;
  avg: number;
}

export interface TwinObjectQuantityTakeoffValue {
  name: string;
  value?: string | number;
}

export interface TwinObjectQuantityTakeoffPropertyAggregate {
  name: string;
  objectQuantityTakeOffDtos: Array<TwinObjectQuantityTakeoffAggregate>;
}

export interface TwinObjectQuantityTakeoffPropertyValue {
  objectQuantityTakeOffDtos: Array<TwinObjectQuantityTakeoffValue>;
}

export type TwinObjectQuantityTakeoff =
  | TwinObjectQuantityTakeoffPropertyAggregate
  | TwinObjectQuantityTakeoffPropertyValue;

export interface TwinObjectQuantityTakeoffBaseResponse<T extends TwinObjectQuantityTakeoff> {
  pageNumber: number;
  pageSize: number;
  totalPages: number;
  totalRecords: number;
  data: {
    datas: Array<T>;
  };
}

export type TwinObjectQuantityTakeoffResponse =
  | TwinObjectQuantityTakeoffBaseResponse<TwinObjectQuantityTakeoffPropertyAggregate>
  | TwinObjectQuantityTakeoffBaseResponse<TwinObjectQuantityTakeoffPropertyValue>;

export const TwinApi = (client: ApiClient) => ({
  getTwinInfo(twin: Twin) {
    const { projectId, type } = twin;
    const url = client.URL(`projects/${projectId}/twin`);
    url.searchParams.set('twinProjectType', type);
    const request = new Request(url, {
      method: 'GET'
    });
    return client.sendRequest<TwinInfo>(request);
  },

  searchExport(
    twin: Twin,
    filter: TwinSearchExportInput,
    pageNumber = 1,
    pageSize = 100,
    orderBy = '',
    orderDirection = ''
  ) {
    const { projectId, type } = twin;
    const url = client.URL(`projects/${projectId}/twin/search-export`);
    url.searchParams.set('twinProjectType', type);
    url.searchParams.set('pageNumber', String(pageNumber));
    url.searchParams.set('pageSize', String(pageSize));
    url.searchParams.set('orderBy', orderBy);
    url.searchParams.set('orderDirection', orderDirection);
    const body = JSON.stringify(filter);
    const request = new Request(url, {
      method: 'POST',
      body,
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return client.sendRequest<TwinObjectQuantityTakeoffResponse>(request);
  },

  downloadExport(twin: Twin, filter: TwinSearchDownloadExportInput) {
    const { projectId, type } = twin;
    const url = client.URL(`projects/${projectId}/twin/download-search-export`);
    url.searchParams.set('twinProjectType', type);
    const body = JSON.stringify(filter);
    const request = new Request(url, {
      method: 'POST',
      body,
      headers: {
        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }
    });

    return client.sendRequest<any>(request);
  },

  postTwinInfo(
    projectId: number,
    azimuth: number,
    refLatitude: number,
    refLongitude: number,
    elevation: number
  ) {
    const url = client.URL(`projects/${projectId}/twin`);
    const body = JSON.stringify({
      azimuth,
      refLatitude,
      refLongitude,
      elevation
    });
    const request = new Request(url, {
      method: 'PATCH',
      body,
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return client.sendRequest<void>(request);
  }
});
