import { AttachmentApi } from '@api/twin/AttachmentApi';
import { useTeiaViewerContext } from '@stereograph/teiaviewer';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { usePluginTranslation } from '@translation';
import DOMPurify from 'dompurify';
import toast from 'react-hot-toast';
import { AnomalyDocument } from '../components/document/AnomalyDocumentCard';
import { useAnomalySectionContext } from '../context/AnomalySectionContext';
import { anomalyQueryKeys } from '../query-keys';

const TOAST_DELETE_DOCUMENT = 'delete-anomaly-document';

export const useDeleteAnomalyDocument = () => {
  const queryClient = useQueryClient();
  const {
    viewer: { twinApiClient }
  } = useTeiaViewerContext();
  const { anomaly } = useAnomalySectionContext();
  const { t } = usePluginTranslation();

  const { mutate: deleteAnomalyDocument } = useMutation({
    mutationFn: async (params: {
      projectId: number;
      document: AnomalyDocument;
      commentId?: number;
    }) => await AttachmentApi(twinApiClient).delete(params.projectId, params.document.id),
    onSuccess: async (_response, variables) => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: anomalyQueryKeys.documents(anomaly.id) }),
        queryClient.invalidateQueries({ queryKey: anomalyQueryKeys.history_default(anomaly.id) })
      ]);

      if (variables.commentId) {
        await queryClient.invalidateQueries({
          queryKey: anomalyQueryKeys.commentDocuments(variables.commentId)
        });
      }

      toast.success(
        t('anomaly_plugin.document.delete.modal.toast.success_text', {
          file: DOMPurify.sanitize(variables.document.title)
        }),
        { id: TOAST_DELETE_DOCUMENT }
      );
    },
    onError: (_response, variables) => {
      toast.error(
        t('anomaly_plugin.document.delete.modal.toast.error_text', {
          file: DOMPurify.sanitize(variables.document.title)
        }),
        { id: TOAST_DELETE_DOCUMENT }
      );
    }
  });

  return {
    deleteAnomalyDocument
  };
};
