import { useViewerPlugin } from '@stereograph/teiaviewer';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ChatMessageInput, MessageFeedback, TiaConversationApi } from './TiaConversationApi';
import { TiaQuotaApiQueryKey } from './TiaQuotaApiHooks';

export const TiaConversationApiQueryKey = 'TiaConversation';

const useTiaConversationApi = () => {
  const { plugin } = useViewerPlugin('chatbot');
  const client = plugin.elliotAPIClient;
  return TiaConversationApi(client);
};

export const useCreateConversation = () => {
  const api = useTiaConversationApi();
  return useMutation({
    mutationFn: async (t: { flowId: string }) => {
      return api.createConversation(t.flowId);
    }
  });
};

export const usePostFeedback = () => {
  const api = useTiaConversationApi();
  return useMutation({
    mutationFn: async (t: {
      conversationId: string;
      messageId: string;
      feedback: MessageFeedback;
    }) => {
      return api.postFeedback(t.conversationId, t.messageId, t.feedback);
    }
  });
};

export const usePostMessage = () => {
  const api = useTiaConversationApi();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (t: { conversationId: string; input: ChatMessageInput }) => {
      await queryClient.invalidateQueries({ queryKey: [TiaQuotaApiQueryKey] });
      return api.postMessage(t.conversationId, t.input);
    }
  });
};
