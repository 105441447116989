import { useCreateConversation } from '@api/tia/TiaConversationApiHooks';
import { Flow } from '@api/tia/TiaFlowApi';
import { useGetAllFlows } from '@api/tia/TiaFlowApiHooks';
import { Alert } from '@stereograph/teia-system-design/components/Alert';
import { Box } from '@stereograph/teia-system-design/components/Box';
import { Button } from '@stereograph/teia-system-design/components/Button';
import { CircularProgress } from '@stereograph/teia-system-design/components/Progress';
import { Select } from '@stereograph/teia-system-design/components/Select';
import { useEffect, useState } from 'react';
import { AttachmentControllerRegistryContextProvider } from '../../attachment/AttachmentControllerRegistry';
import { ChatContextProvider } from '../../components/chat/ChatContext';
import { ChatView } from './ChatView';

export const ChatTabView = () => {
  const [conversationId, setConversationId] = useState<string | null>(null);
  const { mutateAsync: createConversationAsync, isPending: isLoadingConversation } =
    useCreateConversation();

  const {
    data: flows = [],
    isPending: isLoadingFlows,
    isSuccess: hasLoadedFlows
  } = useGetAllFlows();
  const [selectedFlow, setSelectedFlow] = useState<Flow | null>(null);
  const hasSelectedFlow = selectedFlow !== null;
  const hasConversation = conversationId !== null;

  const createConversation = async () => {
    if (hasSelectedFlow) {
      const conversation = await createConversationAsync({ flowId: selectedFlow.id });
      setConversationId(conversation.id);
    }
  };

  useEffect(() => {
    createConversation();
  }, [selectedFlow]);

  useEffect(() => {
    if (flows.length > 0) {
      setSelectedFlow(flows[0]);
    }
  }, [hasLoadedFlows]);

  const onNewConversationButtonClick = () => {
    createConversation();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: 1,
        height: 1,
        padding: 1
      }}
    >
      <Box sx={{ display: 'flex', gap: 1 }}>
        <Select
          placeholder="Flow"
          loading={isLoadingFlows}
          options={flows}
          getOptionLabel={(flow) => flow.name}
          value={selectedFlow}
          onChange={setSelectedFlow}
        ></Select>
        <Button disabled={!hasSelectedFlow} onClick={onNewConversationButtonClick}>
          New conversation
        </Button>
      </Box>
      {isLoadingConversation && (
        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress variant="indeterminate" />
        </Box>
      )}
      {hasConversation && (
        <AttachmentControllerRegistryContextProvider>
          <ChatContextProvider conversationId={conversationId}>
            <ChatView />
          </ChatContextProvider>
        </AttachmentControllerRegistryContextProvider>
      )}
      {!hasConversation && !isLoadingConversation && (
        <Box
          sx={{
            display: 'flex',
            width: 1,
            height: 1,
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Alert severity="info">Select a flow to start a conversation</Alert>
        </Box>
      )}
    </Box>
  );
};
