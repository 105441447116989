import { QTORequestAttachment } from '@api/tia/attachments/Attachment';
import { useTwinApi } from '@api/twin/hooks/useTwinApi';
import {
  TwinApi,
  TwinObjectQuantityTakeoffPropertyAggregate,
  TwinObjectQuantityTakeoffPropertyValue
} from '@api/twin/TwinApi';
import { CircularProgress, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import XlsIcon from '@resources/fileExtensions/xlsxIcon.svg?react';
import { Alert } from '@stereograph/teia-system-design/components/Alert';
import { Box } from '@stereograph/teia-system-design/components/Box';
import { Button } from '@stereograph/teia-system-design/components/Button';
import { Icon } from '@stereograph/teia-system-design/components/Icon';
import { Text } from '@stereograph/teia-system-design/components/Typography';
import { mergeTeiaSearchFilters } from '@stereograph/teia-system-design/teia-components';
import {
  TwinSource,
  useTeiaViewerContext,
  useTwinSource,
  useValueController,
  useViewerPlugin
} from '@stereograph/teiaviewer';
import { BlurTable } from '../../components/BlurTable';
import { AttachmentController, AttachmentViewProps } from '../AttachmentController';

export class QTORequestAttachmentController extends AttachmentController<QTORequestAttachment> {
  readonly showInConversation = true;
  readonly type = 'qto_request';

  override View = QTORequestAttachmentView;
}

type QTORequestAttachmentViewProps = AttachmentViewProps<QTORequestAttachment>;

const QTORequestAttachmentView = (props: QTORequestAttachmentViewProps) => {
  const { attachment } = props;
  const twin = useTwinSource();
  const { viewer } = useTeiaViewerContext();
  const { plugin } = useViewerPlugin('chatbot');
  const [qtoPreviewSize] = useValueController(plugin.settings.qtoPreviewSize);

  let filter = attachment.request.rule;
  const subViewerFilter = (viewer.currentSource as TwinSource).filter;
  if (subViewerFilter) {
    filter = mergeTeiaSearchFilters([filter, subViewerFilter]);
  }

  const { useSearchExport } = useTwinApi(twin);
  const { data: qtoData, isLoading } = useSearchExport(
    { ...attachment.request, rule: filter },
    1,
    qtoPreviewSize
  );

  const onDownloadButtonClick = async () => {
    const api = TwinApi(viewer.twinApiClient);
    const response = await api.downloadExport(twin, { ...attachment.request });
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'result.xls';
    a.click();
  };

  let board = <></>;
  let dataSize = 0;
  if (!isLoading && qtoData) {
    const slicedData = qtoData.data.datas.slice(0, qtoPreviewSize);
    dataSize = slicedData.length;
    board = <QTOBoardPreview data={slicedData} />;
    if (attachment.request.group !== '') {
      const aggData = slicedData as Array<TwinObjectQuantityTakeoffPropertyAggregate>;
      board = <QTOBoardAggregatePreview data={aggData} />;
    }
  }

  if (isLoading) {
    return <CircularProgress />;
  }

  if (!qtoData || qtoData.totalRecords === 0) {
    return (
      <Box>
        <Alert severity="info">No results found for the given request</Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 2, width: 1, display: 'flex', flexDirection: 'column' }}>
      <Box>{board}</Box>
      <Box
        sx={{
          mt: 3,
          width: 1,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Text fontSize={14}>
          Preview {dataSize} values out of {qtoData?.totalRecords}
        </Text>

        <Button
          sx={{ marginLeft: 'auto' }}
          variant="text"
          startIcon={<Icon component={XlsIcon} />}
          onClick={onDownloadButtonClick}
        >
          Download
        </Button>
      </Box>
    </Box>
  );
};

const QTOBoardPreview = (props: { data: Array<TwinObjectQuantityTakeoffPropertyValue> }) => {
  const { data } = props;

  if (data.length === 0) {
    return <>No data</>;
  }

  return (
    <BlurTable>
      <TableHead>
        <TableRow
          sx={{
            border: '1px',
            borderColor: 'gray',
            borderStyle: 'solid'
          }}
        >
          {data[0].objectQuantityTakeOffDtos.map((entry) => (
            <TableCell sx={{ border: 0, paddingY: '1px' }} key={entry.name}>
              {entry.name.split('//').at(-1)}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((row, index) => (
          <TableRow
            key={`row-${index}`}
            sx={{
              border: '1px',
              borderColor: 'gray',
              borderStyle: 'solid'
            }}
          >
            {row.objectQuantityTakeOffDtos.map((entry) => (
              <TableCell key={entry.name} sx={{ border: 0, paddingY: '1px', whiteSpace: 'nowrap' }}>
                {entry.value}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </BlurTable>
  );
};

const QTOBoardAggregatePreview = (props: {
  data: Array<TwinObjectQuantityTakeoffPropertyAggregate>;
}) => {
  const { data } = props;

  if (data.length === 0) {
    return <>No data</>;
  }

  return (
    <BlurTable>
      <TableHead>
        <TableRow
          sx={{
            border: '1px',
            borderColor: 'gray',
            borderStyle: 'solid'
          }}
        >
          <TableCell sx={{ border: 0, paddingY: '1px' }} key="Group">
            Group
          </TableCell>
          {data[0].objectQuantityTakeOffDtos.map((entry) => (
            <TableCell sx={{ border: 0, paddingY: '1px' }} key={entry.name}>
              {entry.name.split('//').at(-1)}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((row) => (
          <TableRow
            key={`row-${row.name}`}
            sx={{
              border: '1px',
              borderColor: 'gray',
              borderStyle: 'solid'
            }}
          >
            <TableCell key="group" sx={{ border: 0, paddingY: '1px', whiteSpace: 'nowrap' }}>
              {row.name}
            </TableCell>
            {row.objectQuantityTakeOffDtos.map((entry) => (
              <TableCell key={entry.name} sx={{ border: 0, paddingY: '1px', whiteSpace: 'nowrap' }}>
                count: {entry.count}
                <br />
                avg: {entry.avg}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </BlurTable>
  );
};
