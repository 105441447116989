import { Box } from '@stereograph/teia-system-design/components/Box';
import { AnomalySectionProvider } from '../../context/AnomalySectionContext';
import { useAnomalyTabContext } from '../../context/AnomalyTabsContext';
import { AnomalyResume } from '../AnomalyResume';
import { CreateAnomaly } from '../details/CreateAnomaly';
import { AnomalySections } from './AnomalySections';
import { AnomalyTabContent } from './AnomalyTabContent';

export const AnomalyTabsContentList = () => {
  const { openTabs } = useAnomalyTabContext();

  return (
    <Box
      sx={{
        overflowY: 'auto',
        height: '100%'
      }}
    >
      {openTabs.map((anomalyTab) => (
        <AnomalyTabContent key={`anomaly-content-${anomalyTab.value}`} value={anomalyTab.value}>
          {anomalyTab.value === 'list' && <AnomalyResume />}
          {anomalyTab.value === 'creation' && <CreateAnomaly />}
          {anomalyTab.anomaly && (
            <AnomalySectionProvider anomaly={anomalyTab.anomaly}>
              <AnomalySections />
            </AnomalySectionProvider>
          )}
        </AnomalyTabContent>
      ))}
    </Box>
  );
};
