import { useTeiaViewerSettings } from '@stereograph/teiaviewer';
import { jwtDecode } from 'jwt-decode';

export const useCanUpdateOrDeleteComment = (createdBy: string): boolean => {
  const [token] = useTeiaViewerSettings('twinApi.token');

  if (!token) {
    console.warn('Token is missing in viewer settings.');
    return false;
  }

  try {
    const decodedToken = jwtDecode(token);

    if (!decodedToken?.sub) {
      console.warn('Token does not contain sub field.');
      return false;
    }

    return decodedToken.sub === createdBy;
  } catch (error) {
    console.error('Failed to decode token:', error);
    return false;
  }
};
