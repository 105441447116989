import { Stack } from '@stereograph/teia-system-design/components/Stack';
import { Suspense } from 'react';
import { DownloadAllDocumentsAction } from './action/DownloadAllDocumentsAction';
import { UploadDocumentAction } from './action/UploadDocumentAction';

export const AnomalyDocumentSectionActions = () => {
  return (
    <Stack direction="row">
      <Suspense>
        <DownloadAllDocumentsAction />
      </Suspense>
      <UploadDocumentAction />
    </Stack>
  );
};
