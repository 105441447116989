import { CheckCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { Icon } from '@stereograph/teia-system-design/components/Icon';
import { Stack } from '@stereograph/teia-system-design/components/Stack';
import { Text } from '@stereograph/teia-system-design/components/Typography';
import { usePluginTranslation } from '@translation';
import { formatBytes } from '../../utils/formatBytes';

interface AnomalyFileSizeStatusProps {
  file: File;
}

export const ANOMALY_FILE_SIZE_LIMIT = 10 * 1024 * 1024; // 10Mo
export const ANOMALY_FILELIST_SIZE_LIMIT = 5 * ANOMALY_FILE_SIZE_LIMIT; // 50Mo

export const AnomalyFileSizeStatus = (props: AnomalyFileSizeStatusProps) => {
  const { file } = props;
  const { t } = usePluginTranslation();

  return (
    <>
      {file.size > ANOMALY_FILE_SIZE_LIMIT ? (
        <Stack direction="row" spacing={1} alignItems="center">
          <Icon
            component={ExclamationTriangleIcon}
            sx={{ color: (theme) => theme.palette.error.main }}
          />
          <Text variant="body2">
            {t('anomaly_plugin.document.upload.file.size.above_limit_text', {
              size: formatBytes(ANOMALY_FILE_SIZE_LIMIT)
            })}
          </Text>
        </Stack>
      ) : (
        <Stack direction="row" spacing={1} alignItems="center">
          <Icon component={CheckCircleIcon} sx={{ color: (theme) => theme.palette.success.main }} />
          <Text variant="body2">
            {t('anomaly_plugin.document.upload.file.size.below_limit_text')}
          </Text>
        </Stack>
      )}
    </>
  );
};
