import { CommentApi, CommentQueryParams } from '@api/twin/CommentApi';
import { useTeiaViewerContext } from '@stereograph/teiaviewer';
import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';
import { commentQueryKeys } from '../queryKeys/comment.query-keys';

export const useGetComments = (projectId: number, commentQueryParams: CommentQueryParams) => {
  const { viewer } = useTeiaViewerContext();
  const client = viewer.twinApiClient;

  return useInfiniteQuery({
    queryKey: commentQueryKeys.getAll(projectId, commentQueryParams),
    queryFn: ({ pageParam }) =>
      CommentApi(client, projectId).getAllComments({
        ...commentQueryParams,
        pageNumber: pageParam
      }),
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      if (lastPage.totalPages > lastPage.pageNumber) {
        return lastPage.pageNumber + 1;
      }
      return undefined;
    },
    placeholderData: keepPreviousData,
    staleTime: 60 * 1000,
    gcTime: 5 * 60 * 1000
  });
};
