import { TwinType } from '@api/twin/TwinApi';

export const openDataQueryKeys = {
  base: 'OpenData' as const,
  project: (projectId: number) => [openDataQueryKeys.base, projectId] as const,
  twinType: (projectId: number, twinType: TwinType) =>
    [openDataQueryKeys.base, projectId, twinType] as const,
  propertyKeys: (projectId: number, twinType: TwinType) =>
    [...openDataQueryKeys.twinType(projectId, twinType), 'propertyKeys'] as const
};
