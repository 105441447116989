import {
  StandardWidget,
  StandardWidgetContextProviderProps,
  StandardWidgetOptions,
  TeiaViewer,
  ViewerSignal,
  WidgetFactoryFunction
} from '@stereograph/teiaviewer';
import { usePluginTranslation } from '@translation';
import { AnomalyTabsProvider } from '../context/AnomalyTabsContext';
import { AnomalyWidgetContextProvider } from '../context/AnomalyWidgetContext';
import { TeiaAnomalyResumeWidgetContent } from './TeiaAnomalyResumeWidgetContent';

declare global {
  interface ViewerWidgetRegistry {
    TeiaAnomalyResume: WidgetFactoryFunction<
      TeiaAnomalyResumeWidget,
      {
        projectId: number;
        isAnomalyCreation: boolean;
      }
    >;
  }
}

export class TeiaAnomalyResumeWidget extends StandardWidget {
  readonly type = 'TeiaAnomalyResume';
  anomalyId?: number;
  projectId: number;
  isAnomalyCreation: boolean;
  readonly onCreateAnomalySignal = new ViewerSignal();

  constructor(
    viewer: TeiaViewer,
    projectId: number,
    isAnomalyCreation: boolean,
    options: StandardWidgetOptions = {}
  ) {
    super(viewer, {
      ...options,
      color: 'info'
    });

    this.projectId = projectId;
    this.isAnomalyCreation = isAnomalyCreation;
  }

  override Header = () => {
    const { t } = usePluginTranslation();
    return t('anomaly_plugin.resume_widget.title');
  };

  override Content = () => {
    return <TeiaAnomalyResumeWidgetContent />;
  };

  protected override ContextProvider = (props: StandardWidgetContextProviderProps) => {
    const { children } = props;

    return (
      <AnomalyWidgetContextProvider
        projectId={this.projectId}
        onAnomalyCreationSignal={this.onCreateAnomalySignal}
        {...props}
      >
        <AnomalyTabsProvider isAnomalyCreation={this.isAnomalyCreation}>
          {children}
        </AnomalyTabsProvider>
      </AnomalyWidgetContextProvider>
    );
  };

  override Footer = () => {
    return null;
  };
}
