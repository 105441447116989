import { default as MoreVertOutlinedIcon } from '@mui/icons-material/MoreVertOutlined';
import { OpenDataPlugin, PatchDataPlugin, QuantityTakeOffPlugin } from '@plugins';
import {
  SelectedObjectsInfoPanel,
  TeiaViewerSourceType,
  Toolbar,
  ToolbarDropdownMenu,
  ToolbarItems,
  useTeiaSource
} from '@stereograph/teiaviewer';
import { TeiaSelectionInfoPlugin } from '../TeiaSelectionInfoPlugin';

export const TeiaSelectionInfoWidgetContent = () => {
  return (
    <div className="st-flex st-flex-col st-h-full st-w-full">
      <SelectedObjectsInfoPanel />
      <Toolbar sx={{ width: '100%' }} rounded={false}>
        <TeiaSelectionInfoPlugin.ToolbarItems.SaveTeiaObjectsSelection />
        <ToolbarItems.HideObjects />
        <ToolbarItems.IsolateObjects />
        <ShowMoreDropdownMenu />
      </Toolbar>
    </div>
  );
};

const ShowMoreDropdownMenu = () => {
  const twin = useTeiaSource(TeiaViewerSourceType.Twin);
  if (!twin) {
    return null;
  }
  return (
    <ToolbarDropdownMenu icon={Icon} disabled={!twin} label="More actions">
      <PatchDataPlugin.ToolbarItems.ShowTeiaPatchDataWidget />
      <OpenDataPlugin.ToolbarItems.ShowTeiaOpenDataWidget />
      <QuantityTakeOffPlugin.ToolbarItems.ShowQTOWidget />
      <TeiaSelectionInfoPlugin.ToolbarItems.ShowTeiaSelectionRecordWidget />
    </ToolbarDropdownMenu>
  );
};

const Icon = () => {
  return <MoreVertOutlinedIcon color={'primary'} />;
};
