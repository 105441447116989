import { Comment, CommentQueryParams, RequestPostComment } from '@api/twin/CommentApi';
import { useTeiaViewerContext } from '@stereograph/teiaviewer';
import { QueryKey, useMutation, useQueryClient } from '@tanstack/react-query';
import { usePluginTranslation } from '@translation';
import toast from 'react-hot-toast';
import { CommentApi } from '../CommentApi';
import { commentQueryKeys } from '../queryKeys/comment.query-keys';

const TOAST_POST_COMMENT = 'toast_post_comment';

export const usePostComment = (projectId: number, invalidateQueryKeys: QueryKey) => {
  const { viewer } = useTeiaViewerContext();
  const client = viewer.twinApiClient;
  const queryClient = useQueryClient();
  const { t } = usePluginTranslation();

  const { mutateAsync: postComment } = useMutation<Comment, Error, RequestPostComment>({
    mutationFn: async (requestPostComment) => {
      return await CommentApi(client, projectId).postComment(requestPostComment);
    },
    onMutate: () => {
      toast.loading(t('anomaly_plugin.widget.comment_form.submit.loading.text'), {
        id: TOAST_POST_COMMENT
      });
    },
    onSuccess: async (_response, variables) => {
      const commentQueryParams = {
        targetId: variables.targetId,
        targetType: variables.targetType
      } as CommentQueryParams;

      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: commentQueryKeys.getAll(projectId, commentQueryParams)
        }),
        queryClient.invalidateQueries({
          queryKey: invalidateQueryKeys
        })
      ]);

      toast.success(t('anomaly_plugin.widget.comment_form.submit.success.text'), {
        id: TOAST_POST_COMMENT
      });
    },
    onError: () => {
      toast.error(t('anomaly_plugin.widget.comment_form.submit.error.text'), {
        id: TOAST_POST_COMMENT
      });
    }
  });

  return {
    postComment
  };
};
