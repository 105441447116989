import { ApiClient, ApiDataList, TeiaSearchFilter } from '@stereograph/teia-system-design/utils';
import { Twin, TwinType } from './TwinApi';

export type Search2Response = ApiDataList<
  Array<{
    id: number;
    guid: string;
    name: string;
    parentId: number;
    parentGuid: string;
    type: string;
    teiaType: string;
  }>
>;

export interface TwinObject {
  guid: string;
  name?: string;
  parentGuid?: string;
}

export const TwinObjectApi = (client: ApiClient) => ({
  search2({
    projectId,
    type = 'Draft',
    pageNumber = 1,
    pageSize = 100,
    filter
  }: {
    projectId: number;
    type: TwinType;
    pageNumber?: number;
    pageSize?: number;
    filter: TeiaSearchFilter;
  }) {
    const url = client.URL(`projects/${projectId}/twin/objects/search2`);
    url.searchParams.set('twinProjectType', type);
    url.searchParams.set('pageNumber', String(pageNumber));
    url.searchParams.set('pageSize', String(pageSize));
    const body = JSON.stringify(filter);
    const request = new Request(url, {
      method: 'POST',
      body,
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return client.sendRequest<Search2Response>(request);
  },

  searchTwinObjects<TObject extends TwinObject = TwinObject>(
    twin: Twin,
    pageNumber: number,
    pageSize: number,
    filter?: TeiaSearchFilter
  ) {
    const { projectId, type } = twin;
    const url = client.URL(`projects/${projectId}/twin/objects/search2`);
    url.searchParams.set('pageNumber', String(pageNumber));
    url.searchParams.set('pageSize', String(pageSize));
    url.searchParams.set('twinProjectType', type);
    const body = JSON.stringify(filter ?? {});
    const request = new Request(url, {
      method: 'POST',
      body,
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return client.sendRequest<ApiDataList<TObject>>(request);
  },

  flattenedObjectProperties(twin: Twin) {
    const { projectId, type } = twin;
    const url = client.URL(`projects/${projectId}/twin/objects/flattened-object-properties`);
    url.searchParams.set('twinProjectType', type);
    const request = new Request(url, {
      method: 'GET'
    });
    return client.sendRequest<Array<string>>(request);
  }
});
