import { AnomalyScene } from '@api/twin/AnomalyApi';
import { EyeIcon } from '@heroicons/react/24/outline';
import { Icon } from '@stereograph/teia-system-design/components/Icon';
import { IconButton } from '@stereograph/teia-system-design/components/IconButton';
import { useTeiaViewerContext } from '@stereograph/teiaviewer';
import { usePluginTranslation } from '@translation';
import { SetAnomalySceneCommand } from '../../command/SetAnomalySceneCommand';

interface LoadAnomalySceneButtonProps {
  anomalyScene: AnomalyScene;
}

export const LoadAnomalySceneButton = (props: LoadAnomalySceneButtonProps) => {
  const { anomalyScene } = props;
  const { viewer } = useTeiaViewerContext();
  const { t } = usePluginTranslation();

  const onLoadAnomalyContext = () => {
    const command = new SetAnomalySceneCommand(viewer, anomalyScene);
    command.execute();
  };

  return (
    <IconButton
      aria-label={t('anomaly_plugin.widget.scene_banner.button.label')}
      onClick={onLoadAnomalyContext}
      color="primary"
    >
      <Icon component={EyeIcon} />
    </IconButton>
  );
};
