import { ApiClient, ApiDataList } from '@stereograph/teia-system-design/utils';

export type TargetType = 'Project' | 'File' | 'Anomaly' | 'TwinTag' | 'Unknown';

export interface Comment {
  id: number;
  projectId: number;
  createdBy: string;
  parentId: number;
  targetType: TargetType;
  targetId: number;
  value: string;
  creationDate: Date;
  username: string;
  responses?: Array<Comment>;
  responsesLength?: number;
}

export interface RequestPostComment {
  targetType: TargetType;
  targetId: number;
  value: string;
  parentId?: number;
}

export interface CommentQueryParams {
  targetType: TargetType;
  targetId: number;
  pageNumber: number;
  pageSize: number;
  parentId?: number;
  orderDirection?: 'asc' | 'desc';
}

export const CommentApi = (client: ApiClient, projectId: number) => {
  const url = client.URL(`projects/${projectId}/comments`);

  const getAllComments = (
    commentsQueryParams: CommentQueryParams
  ): Promise<ApiDataList<Comment>> => {
    Object.entries(commentsQueryParams).forEach(([key, value]) => {
      if (value) {
        url.searchParams.set(key, value);
      }
    });

    const request = new Request(url, {
      method: 'GET'
    });

    return client.sendRequest<ApiDataList<Comment>>(request);
  };

  const postComment = (comment: RequestPostComment) => {
    const request = new Request(url, {
      method: 'POST',
      body: JSON.stringify(comment),
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return client.sendRequest<Comment>(request);
  };

  const deleteComment = (commentId: number) => {
    const deleteUrl = new URL(`${url}/${commentId}`);

    const request = new Request(deleteUrl.toString(), {
      method: 'DELETE'
    });

    return client.sendRequest(request);
  };

  const patchComment = (comment: Comment) => {
    const patchUrl = new URL(`${url}/${comment.id}`);

    const request = new Request(patchUrl, {
      method: 'PATCH',
      body: JSON.stringify(comment),
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return client.sendRequest<Comment>(request);
  };

  return {
    getAllComments,
    postComment,
    deleteComment,
    patchComment
  };
};
