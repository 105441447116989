import { RequestPostComment } from '@api/twin/CommentApi';
import { usePostComment } from '@api/twin/hooks/usePostComment';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@stereograph/teia-system-design/components/Button';
import { Stack } from '@stereograph/teia-system-design/components/Stack';
import { Textarea } from '@stereograph/teia-system-design/components/Textarea';
import { usePluginTranslation } from '@translation';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as z from 'zod';
import { useAnomalySectionContext } from '../../context/AnomalySectionContext';
import { useAnomalyWidgetContext } from '../../context/AnomalyWidgetContext';
import { anomalyQueryKeys } from '../../query-keys';

export const commentSchema = z.object({
  comment: z.string().trim().min(1)
});

type CommentFormSchema = z.infer<typeof commentSchema>;

interface CreateAnomalyCommentFormProps {
  parentId?: number;
  focused?: boolean;
  onClose?: () => void;
}

export const CreateAnomalyCommentForm = (props: CreateAnomalyCommentFormProps) => {
  const { parentId, focused, onClose } = props;
  const { anomaly } = useAnomalySectionContext();
  const { projectId } = useAnomalyWidgetContext();
  const { postComment } = usePostComment(projectId, anomalyQueryKeys.default);
  const { t } = usePluginTranslation();

  const methods = useForm<CommentFormSchema>({
    resolver: zodResolver(commentSchema),
    mode: 'onSubmit',
    defaultValues: {
      comment: ''
    }
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitSuccessful }
  } = methods;

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  const onSubmitComment = (formComment: CommentFormSchema) => {
    const requestPostComment: RequestPostComment = {
      targetId: anomaly.id,
      targetType: 'Anomaly',
      value: formComment.comment,
      parentId
    };

    postComment(
      { ...requestPostComment },
      {
        onSuccess: () => {
          onClose?.();
        }
      }
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmitComment)}>
      <Stack
        direction="row"
        spacing={6}
        alignItems="center"
        sx={{
          width: '100%'
        }}
      >
        <Controller
          control={control}
          name="comment"
          render={({ field, fieldState: { error } }) => {
            return (
              <Textarea
                inputRef={(input) => {
                  if (input && focused) {
                    input.focus();
                  }
                }}
                name={field.name}
                value={field.value}
                onChange={field.onChange}
                sx={{ minWidth: 400, width: '80%' }}
                id={field.name}
                placeholder={
                  parentId
                    ? t('anomaly_plugin.widget.comment_form.answer.textarea.placeholder_text')
                    : t('anomaly_plugin.widget.comment_form.textarea.placeholder_text')
                }
                label={
                  parentId
                    ? t('anomaly_plugin.widget.comment_form.answer.textarea.label')
                    : t('anomaly_plugin.widget.comment_form.textarea.label')
                }
                error={error !== undefined}
                helperText={error?.message}
                required
              />
            );
          }}
        />
        <Button type="submit">{t('anomaly_plugin.widget.comment_form.submit.create.label')}</Button>
      </Stack>
    </form>
  );
};
