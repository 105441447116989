import { ChatBubbleLeftIcon } from '@heroicons/react/24/outline';
import {
  OpenWidgetCommand,
  TeiaViewer,
  TeiaViewerSourceType,
  ToolbarItem,
  useTeiaSource,
  useViewerContext
} from '@stereograph/teiaviewer';

export const ChatBotToolbarItem = (): JSX.Element => {
  const { viewer } = useViewerContext<TeiaViewer>();
  const twin = useTeiaSource(TeiaViewerSourceType.Twin);
  const showItem = twin !== null;

  const onClick = () => {
    const cmd = new OpenWidgetCommand(viewer, 'chatbot');
    cmd.execute();
  };

  return (
    <ToolbarItem
      hidden={!showItem}
      icon={ChatBubbleLeftIcon}
      label="ChatBot (Beta)"
      onClick={onClick}
    />
  );
};
