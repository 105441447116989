import { Flow } from '@api/tia/TiaFlowApi';
import { useCreateFlow, useDeleteFlow, useGetAllFlows } from '@api/tia/TiaFlowApiHooks';
import { DocumentDuplicateIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import { Box } from '@stereograph/teia-system-design/components/Box';
import { Button } from '@stereograph/teia-system-design/components/Button';
import { CircularProgress } from '@stereograph/teia-system-design/components/Progress';
import { createMRTColumnHelper, Table } from '@stereograph/teia-system-design/components/Table';
import { Icon } from '@stereograph/teia-system-design/components/Icon';
import { IconButton } from '@stereograph/teia-system-design/components/IconButton';
import { Title } from '@stereograph/teia-system-design/components/Typography';
import { useFlowConfigContext } from './FlowConfigContext';

const columnHelper = createMRTColumnHelper<Flow>();
const columns = [
  columnHelper.accessor('name', { header: 'Name' }),
  columnHelper.accessor('id', { header: 'Id' })
];

export const FlowListView = () => {
  const { setMode } = useFlowConfigContext();
  const { data: flows = [], isPending: isLoadingFlows } = useGetAllFlows();

  const onAddFlow = () => {
    setMode('CreateFlow');
  };

  return (
    <Box sx={{ height: 1, width: 1, display: 'flex', flexDirection: 'column', p: 1, gap: 1 }}>
      <Title sx={{ alignSelf: 'center', m: 1, mb: 5 }}>My Flows</Title>
      <Table
        tableOptions={{
          columns,
          data: flows,
          muiTablePaperProps: {
            sx: {
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column'
            }
          },
          state: {
            isLoading: isLoadingFlows
          },
          renderTopToolbarCustomActions: () => (
            <Button onClick={onAddFlow} sx={{ px: 3 }}>
              Create Flow
            </Button>
          ),
          muiTableContainerProps: {
            sx: {
              flexGrow: 1
            }
          },
          enableRowActions: true,
          positionActionsColumn: 'last',
          renderRowActions: ({ row }) => <FlowRowActions flow={row.original} />
        }}
      />
    </Box>
  );
};

interface RowActionProps {
  flow: Flow;
}

const FlowRowActions = (props: RowActionProps) => {
  const { flow } = props;
  const { setMode } = useFlowConfigContext();
  const { mutateAsync: deleteFlow, isPending: isDeleting } = useDeleteFlow();
  const { mutateAsync: createFlow, isPending: isCreating } = useCreateFlow();
  const { editable } = flow;

  const isWorking = isDeleting || isCreating;

  const onEdit = () => {
    setMode('UpdateFlow', flow);
  };

  const onDuplicate = () => {
    const newFlow = { ...flow };
    newFlow.name = flow.name + ' - Copy';
    createFlow(newFlow);
  };

  const onDeleteFlow = () => {
    deleteFlow({ flow });
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <IconButton
        aria-label="Edit flow"
        onClick={onEdit}
        iconOnly
        disabled={isWorking || !editable}
      >
        <Icon component={PencilIcon} />
      </IconButton>
      <IconButton aria-label="Duplicate flow" onClick={onDuplicate} iconOnly disabled={isWorking}>
        <Icon component={DocumentDuplicateIcon} />
      </IconButton>
      <IconButton
        color="error"
        aria-label="Delete flow"
        onClick={onDeleteFlow}
        iconOnly
        disabled={isWorking || !editable}
      >
        <Icon component={TrashIcon} />
      </IconButton>
      <Box sx={{ width: 20, display: isWorking ? 'none' : 'block' }} />
      <CircularProgress size={20} sx={{ ml: 1, display: isWorking ? 'block' : 'none' }} />
    </Box>
  );
};
