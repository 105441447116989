import {
  NewSelectionFilter,
  SelectionFilter,
  SelectionFilterType
} from '@api/twin/SelectionFilterApi';
import { Button } from '@stereograph/teia-system-design/components/Button';
import { Checkbox } from '@stereograph/teia-system-design/components/Checkbox';
import { Stack } from '@stereograph/teia-system-design/components/Stack';
import { TextField } from '@stereograph/teia-system-design/components/TextField';
import {
  ModalView,
  ModalViewContent,
  ModalViewFooter
} from '@stereograph/teia-system-design/deprecated';
import { TeiaSearchFilter } from '@stereograph/teia-system-design/utils';
import { useTeiaViewerContext, useTwinSource } from '@stereograph/teiaviewer';
import { usePluginTranslation } from '@translation';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useSelectionFiltersApi } from '../api/useSelectionFilterApi';

interface TeiaSaveSelectionModalProps {
  isModalOpen: boolean;
  onClose: () => void;
  filter?: TeiaSearchFilter;
}

export const TeiaSaveSelectionModal = (props: TeiaSaveSelectionModalProps) => {
  const { isModalOpen, onClose, filter } = props;
  const { t } = usePluginTranslation();
  const { viewer } = useTeiaViewerContext();
  const twin = useTwinSource();
  const {
    usePostSelectionFilter: { mutateAsync, isPending }
  } = useSelectionFiltersApi(twin.projectId);

  const methods = useForm<SelectionFilter>({
    defaultValues: {
      name: '',
      filter: undefined,
      isPublic: false
    },
    mode: 'onChange'
  });

  const createStaticFilter = () => {
    const filter: TeiaSearchFilter = {
      condition: 'Or',
      rules: []
    };

    const uuids = viewer.scene.objectSelection.getUuids();
    for (const uuid of uuids) {
      filter.rules.push({
        type: 'Guid',
        operator: 'EqualTo',
        value: uuid
      });
    }

    return filter;
  };

  const onCloseModal = () => {
    methods.reset();
    onClose();
  };

  const handleCreateSelection: SubmitHandler<SelectionFilter> = (data) => {
    if (isPending) {
      return;
    }
    const newFilter = filter ?? createStaticFilter();

    const newSelectionFilter: NewSelectionFilter = {
      name: data.name,
      type: filter ? SelectionFilterType.DYNAMIC : SelectionFilterType.STATIC,
      filter: newFilter,
      isPublic: data.isPublic
    };

    mutateAsync(
      { selectionFilter: newSelectionFilter },
      {
        onSuccess: onCloseModal
      }
    );
  };

  return (
    <form onSubmit={methods.handleSubmit(handleCreateSelection)}>
      <ModalView
        id="new-selection-modal"
        isFooter={true}
        variant="white"
        size="sm"
        show={isModalOpen}
        onClose={onCloseModal}
        title={t('selection_plugin.record_widget.selection_save.modal.title')}
      >
        <ModalViewContent>
          <Stack
            spacing={1}
            sx={{
              mb: 2
            }}
          >
            <Controller
              render={({ field }) => (
                <TextField
                  label={t('selection_plugin.record_widget.selection_save.modal.name.label')}
                  placeholder={t(
                    'selection_plugin.record_widget.selection_save.modal.name.placeholder'
                  )}
                  required
                  {...field}
                />
              )}
              name={'name'}
              control={methods.control}
              rules={{ required: true, min: 1 }}
            />
            <Controller
              name="isPublic"
              control={methods.control}
              defaultValue={false}
              render={({ field }) => (
                <Checkbox
                  label={t('selection_plugin.record_widget.selection_save.modal.visibility.label')}
                  onChange={field.onChange}
                />
              )}
            />
          </Stack>
          <ModalViewFooter>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3}>
              <Button color="info" type="button" onClick={onCloseModal}>
                {t('selection_plugin.record_widget.selection_save.modal.cancel_button.label')}
              </Button>
              <Button
                type="submit"
                color="primary"
                disabled={!methods.formState.isValid}
                isLoading={isPending}
                sx={{
                  minWidth: 100
                }}
              >
                {t('selection_plugin.record_widget.selection_save.modal.validate_button.label')}
              </Button>
            </Stack>
          </ModalViewFooter>
        </ModalViewContent>
      </ModalView>
    </form>
  );
};
