import { Box } from '@stereograph/teia-system-design/components/Box';
import { useAnomalyTabContext } from '../../context/AnomalyTabsContext';

interface AnomalyTabContentProps {
  value: string;
  children?: React.ReactNode;
}

export const AnomalyTabContent = (props: AnomalyTabContentProps) => {
  const { value, children } = props;
  const { selectedTab } = useAnomalyTabContext();

  const isVisible = value === selectedTab.value;

  return (
    <Box
      sx={{
        minWidth: 0,
        flexGrow: 1,
        display: isVisible ? 'inline' : 'none'
      }}
    >
      {children}
    </Box>
  );
};
