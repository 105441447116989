import { ChatBubbleLeftRightIcon } from '@heroicons/react/24/outline';
import AnomalyHistoryIcon from '@resources/icons/AnomalyHistoryIcon.svg?react';
import DetailsIcon from '@resources/icons/Details.svg?react';
import { Box } from '@stereograph/teia-system-design/components/Box';
import { AnomalyCommentSection } from '../comment/AnomalyCommentSection';
import { UpdateAnomalySection } from '../details/UpdateAnomalySection';
import { AnomalyHistory } from '../history/AnomalyHistory';
import { TabBar, TabBarItem, TabContainer, TabContent } from './AnomalyTabBar';

type Mode = 'update' | 'comments' | 'history';

export const AnomalySections = () => {
  return (
    <TabContainer<Mode> defaultValue="update">
      <Box sx={{ position: 'sticky' }}>
        <TabBar>
          <TabBarItem<Mode> icon={DetailsIcon} value="update" />
          <TabBarItem<Mode> icon={ChatBubbleLeftRightIcon} value="comments" />
          <TabBarItem<Mode> icon={AnomalyHistoryIcon} value="history" />
        </TabBar>
      </Box>
      <TabContent<Mode> value="update">
        <UpdateAnomalySection />
      </TabContent>
      <TabContent<Mode> value="comments">
        <AnomalyCommentSection />
      </TabContent>
      <TabContent<Mode> value="history">
        <AnomalyHistory />
      </TabContent>
    </TabContainer>
  );
};
