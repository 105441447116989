import { CircularProgress } from '@stereograph/teia-system-design/components/Progress';
import { Stack } from '@stereograph/teia-system-design/components/Stack';
import { Text } from '@stereograph/teia-system-design/components/Typography';
import { Suspense } from 'react';
import { useAnomalyCommentContext } from '../../context/AnomalyCommentContext';
import { AnomalyCommentActionBar } from './AnomalyCommentActionBar';
import { AnomalyCommentFiles } from './AnomalyCommentFiles';

export const AnomalyCommentContent = () => {
  const { comment } = useAnomalyCommentContext();

  return (
    <Stack spacing={1} sx={{ pl: 10 }}>
      <Text variant="body2" sx={{ pl: 1 }}>
        {comment.value}
      </Text>
      <AnomalyCommentActionBar />
      <Suspense fallback={<CircularProgress />}>
        <AnomalyCommentFiles />
      </Suspense>
    </Stack>
  );
};
