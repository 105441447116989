import { Anomaly } from '@api/twin/AnomalyApi';
import { usePluginTranslation } from '@translation';
import {
  createContext,
  MouseEvent,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useAnomalyWidgetContext } from './AnomalyWidgetContext';

interface TabContext {
  selectedTab: AnomalyTab;
  setSelectedTab: (anomalyTab: AnomalyTab) => void;
  handleCloseTab: (anomalyTab: AnomalyTab) => void;
  handleAddTab: (anomalyTab: AnomalyTab, shouldSelect: boolean) => void;
  handleAddTabClick: (event: MouseEvent, anomalyTab: AnomalyTab) => void;
  updateTab: (anomaly: AnomalyTab) => void;
  openTabs: Array<AnomalyTab>;
  createAnomalyTab: (anomaly: Anomaly, closable?: boolean) => AnomalyTab;
  handleAddCreationAnomalyTab: (shouldSelect: boolean) => void;
  handleAddCreationAnomalyTabClick: (event: MouseEvent) => void;
}

const Context = createContext<TabContext | null>(null);

export interface TabContainerProps {
  children?: ReactNode;
  isAnomalyCreation: boolean;
}

export interface AnomalyTab {
  label: string;
  value: string;
  anomaly?: Anomaly;
  closable?: boolean;
}

export const AnomalyTabsProvider = (props: TabContainerProps) => {
  const { isAnomalyCreation, children } = props;
  const { onAnomalyCreationSignal } = useAnomalyWidgetContext();
  const { t } = usePluginTranslation();

  const createAnomalyTab = (anomaly: Anomaly, closable?: boolean): AnomalyTab => {
    return {
      label: anomaly.title,
      value: anomaly.id.toString(),
      anomaly,
      closable
    };
  };

  const creationAnomalyTab = {
    label: t('anomaly_plugin.widget.create_anomaly.tab.title'),
    value: 'creation',
    closable: true
  };
  const listAnomalyTab = {
    label: t('anomaly_plugin.resume_widget.tab.title'),
    value: 'list'
  };
  const [openTabs, setOpenTabs] = useState<Array<AnomalyTab>>(() =>
    isAnomalyCreation ? [listAnomalyTab, creationAnomalyTab] : [listAnomalyTab]
  );
  const [selectedTab, setSelectedTab] = useState<AnomalyTab>(openTabs[openTabs.length - 1]);

  const handleCloseTab = (currentTab: AnomalyTab) => {
    setOpenTabs((prevTabs) => {
      const newTabs = prevTabs.filter((tab) => tab.value !== currentTab.value);
      const currentIndex = prevTabs.findIndex((tab) => tab.value === currentTab.value);

      if (selectedTab.value === currentTab.value) {
        const nextTab = newTabs[currentIndex] || newTabs[currentIndex - 1];
        setSelectedTab(nextTab);
      }

      return newTabs;
    });
  };

  const handleAddTab = (newAnomalyTab: AnomalyTab, shouldSelect: boolean) => {
    setOpenTabs((prevTabs) => {
      const exists = prevTabs.some((tab) => tab.value === newAnomalyTab.value);
      const newTabs = exists ? prevTabs : [...prevTabs, newAnomalyTab];

      if (shouldSelect || exists) {
        setSelectedTab(newAnomalyTab);
      }

      return newTabs;
    });
  };

  const handleAddTabClick = (event: MouseEvent, newAnomalyTab: AnomalyTab) => {
    const shouldSelect = !event.ctrlKey && !event.metaKey;
    handleAddTab(newAnomalyTab, shouldSelect);
  };

  const updateTab = (updatedTab: AnomalyTab) => {
    setOpenTabs((prevTabs) =>
      prevTabs.map((anomaly) => (anomaly.value === updatedTab.value ? updatedTab : anomaly))
    );
  };

  const handleAddCreationAnomalyTab = (shouldSelect: boolean) => {
    handleAddTab(creationAnomalyTab, shouldSelect);
  };

  const handleAddCreationAnomalyTabClick = useCallback(
    (event?: MouseEvent) => {
      const shouldSelect = event ? !event.ctrlKey && !event.metaKey : true;

      handleAddCreationAnomalyTab(shouldSelect);
    },
    [handleAddTab, t]
  );

  useEffect(() => {
    const handleSignalCallback = () => handleAddCreationAnomalyTab(true);

    onAnomalyCreationSignal.connect(handleSignalCallback);
    return () => {
      onAnomalyCreationSignal.disconnect(handleSignalCallback);
    };
  }, [onAnomalyCreationSignal, handleAddCreationAnomalyTab]);

  const ctx = useMemo(
    () => ({
      selectedTab,
      setSelectedTab,
      handleAddTab,
      handleAddTabClick,
      handleCloseTab,
      updateTab,
      openTabs,
      createAnomalyTab,
      handleAddCreationAnomalyTab,
      handleAddCreationAnomalyTabClick
    }),
    [
      selectedTab,
      setSelectedTab,
      handleAddTabClick,
      handleCloseTab,
      handleAddTab,
      updateTab,
      openTabs,
      createAnomalyTab,
      handleAddCreationAnomalyTab,
      handleAddCreationAnomalyTabClick
    ]
  );

  return <Context.Provider value={ctx}>{children}</Context.Provider>;
};

export const useAnomalyTabContext = () => {
  const ctx = useContext(Context);

  if (!ctx) {
    throw new Error('TabContext not found');
  }
  return ctx;
};
