import toast from 'react-hot-toast';

import {
  AnomalyCameraType,
  AnomalyCsvImportResponse,
  AnomalyFileType,
  AnomalyGuids,
  AnomalyPerspectiveCamera,
  AnomalyScene,
  ObjectAnomalyInformation
} from '@api/twin/AnomalyApi';
import { usePluginTranslation } from '@translation';
import { Vector3 } from 'three';
import { AnomalyImportErrorCode } from './types';

/* 
  Default Camera when the camera is not a perspective camera
  Match the camera values when first loading
*/
export const DEFAULT_ANOMALY_PERSPECTIVE_CAMERA: AnomalyPerspectiveCamera = {
  cameraViewPoint: new Vector3(29.945303557184424, -34.44530296113798, 29.27030332174607),
  cameraDirection: new Vector3(-0.5773502691896257, 0.5773502691896261, -0.5773502691896257),
  type: AnomalyCameraType.PERSPECTIVE,
  cameraUpVector: new Vector3(0, 0, 1),
  aspectRatio: 3.2764976958525347,
  fieldOfView: 50
};

const getNotSelectedVisibleObjects = (
  visibleGuids: Array<string>,
  selectionGuids: Array<string>
) => {
  return visibleGuids.filter((guid) => !selectionGuids.includes(guid));
};

const getShorterGuidList = (
  hasMoreHiddenGuids: boolean,
  getVisibleNotSelectedGuids: () => Array<string>,
  getHiddenGuids: () => Array<string>
) => {
  return {
    guidList: hasMoreHiddenGuids ? getVisibleNotSelectedGuids() : getHiddenGuids(),
    visibility: hasMoreHiddenGuids
      ? ObjectAnomalyInformation.isVisible
      : ObjectAnomalyInformation.isHidden
  };
};

export const buildAnomalyGuidsDictionnary = (
  selectionGuids: Array<string>,
  visibleGuids: Array<string>,
  hiddenGuids: Array<string>
) => {
  const anomalyGuids: AnomalyGuids = {};

  const hasMoreGuidsHidden = hiddenGuids.length > visibleGuids.length - selectionGuids.length;

  anomalyGuids['DefaultVisibility'] = ObjectAnomalyInformation.isVisible;

  if (hasMoreGuidsHidden) {
    anomalyGuids['DefaultVisibility'] = ObjectAnomalyInformation.isHidden;
  }

  selectionGuids.forEach((guid) => {
    anomalyGuids[guid] = ObjectAnomalyInformation.isSelected;
  });

  const { guidList, visibility } = getShorterGuidList(
    hasMoreGuidsHidden,
    () => getNotSelectedVisibleObjects(visibleGuids, selectionGuids),
    () => hiddenGuids
  );

  guidList.forEach((guid) => {
    anomalyGuids[guid] = visibility;
  });

  return anomalyGuids;
};

export const createAnomalyScene = (anomalyGuids: AnomalyGuids) => {
  const anomalyScene: AnomalyScene = Object.entries(anomalyGuids).reduce<{
    visible: Array<string>;
    hidden: Array<string>;
    selected: Array<string>;
    defaultVisibility: ObjectAnomalyInformation;
  }>(
    (acc, [key, value]) => {
      if (key === 'DefaultVisibility') {
        acc.defaultVisibility = value;
      } else if (value === 'isVisible') {
        acc.visible.push(key);
      } else if (value === 'isHidden') {
        acc.hidden.push(key);
      } else if (value === 'isSelected') {
        acc.selected.push(key);
      }
      return acc;
    },
    {
      visible: [],
      hidden: [],
      selected: [],
      defaultVisibility: ObjectAnomalyInformation.isVisible
    }
  );

  return anomalyScene;
};

export const defineFileImportUrl = (fileExtension: string) => {
  switch (fileExtension) {
    case 'bcf':
    case 'bcfzip':
      return AnomalyFileType.BCF;
    case 'xls':
    case 'xlsx':
      return AnomalyFileType.Excel;
    default:
      throw new Error('Extension non supportée');
  }
};

export const formatAnomalyFilename = (anomalyIds: Array<number>, exportExtension: string) => {
  let filename = `BCF-${new Date().toLocaleDateString()}-`;
  filename += anomalyIds.join('-');
  filename += exportExtension;

  return filename;
};

const returnAnomalyImportErrorTranslation = (errorCode: string) => {
  const { t } = usePluginTranslation();
  switch (errorCode) {
    case AnomalyImportErrorCode.GuidNotFound:
      return t('anomaly_plugin.info_widget.upload.excel_custom_errors.guid_not_found_text');
    case AnomalyImportErrorCode.WrongType:
      return t('anomaly_plugin.info_widget.upload.excel_custom_errors.type_is_wrong_text');
    case AnomalyImportErrorCode.WrongPriority:
      return t('anomaly_plugin.info_widget.upload.excel_custom_errors.priority_is_wrong_text');
    case AnomalyImportErrorCode.WrongStatus:
      return t('anomaly_plugin.info_widget.upload.excel_custom_errors.status_is_wrong_text');
    case AnomalyImportErrorCode.IdNotFound:
      return t('anomaly_plugin.info_widget.upload.excel_custom_errors.id_not_found_text');
    default:
      return;
  }
};

export const buildAnomalyErrorMessage = (csvImportResponse: AnomalyCsvImportResponse) => {
  const { t } = usePluginTranslation();
  const hasImportErrors = Object.entries(csvImportResponse.failed).length > 0;
  const hasImportFailed =
    csvImportResponse.addedCount === 0 && csvImportResponse.updatedCount === 0 && hasImportErrors;

  const errorMessage = Object.entries(csvImportResponse.failed)
    .map((error) => {
      const [line, errorCode] = error;

      return `${t('anomaly_plugin.info_widget.upload.excel_import_failed_text', {
        count: parseInt(line)
      })} : ${returnAnomalyImportErrorTranslation(errorCode)}`;
    })
    .join('\n');

  let trad = `${t('anomaly_plugin.info_widget.upload.excel_created_anomaly.label', {
    count: csvImportResponse.addedCount
  })}, ${t('anomaly_plugin.info_widget.upload.excel_updated_anomaly.label', {
    count: csvImportResponse.updatedCount
  })}`;

  if (hasImportErrors) {
    trad += errorMessage;
  }

  if (hasImportFailed) {
    return toast.error(errorMessage, {
      duration: 6000
    });
  }

  return toast.success(trad, {
    duration: 6000
  });
};
