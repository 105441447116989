import { TwinObjectApi } from '@api/twin/TwinObjectApi';
import { TeiaSearchFilter } from '@stereograph/teia-system-design/utils';
import { useTeiaViewerContext, useTwinSource } from '@stereograph/teiaviewer';
import { useQuery } from '@tanstack/react-query';

export const useTwinTotalObjects = (filter: TeiaSearchFilter) => {
  const twin = useTwinSource();
  const { viewer } = useTeiaViewerContext();
  const client = viewer.twinApiClient;
  const { data } = useQuery({
    queryKey: ['Twin', 'info', twin.projectId, twin.type, filter],
    queryFn: async () => {
      return TwinObjectApi(client).search2({
        projectId: twin.projectId,
        type: twin.type,
        pageNumber: 1,
        pageSize: 1,
        filter
      });
    }
  });

  return data?.totalRecords ?? 0;
};
