import { PluginBuilder, TeiaViewer } from '@stereograph/teiaviewer';
import { setupPluginsTranslation } from '@translation';
import { ShowTeiaAnomalyMenu } from './ToolbarItems';
import { TeiaAnomalyResumeWidget } from './widgets/TeiaAnomalyResumeWidget';

declare global {
  interface ViewerPluginRegistry {
    anomaly: TeiaAnomalyPlugin;
  }
}

const PluginBaseClass = PluginBuilder.NewPlugin('TeiaAnomalyPlugin');

export class TeiaAnomalyPlugin extends PluginBaseClass<TeiaViewer> {
  static override readonly ToolbarItems = {
    ShowTeiaAnomaly: ShowTeiaAnomalyMenu
  };

  override onRegister(): void {
    setupPluginsTranslation();
    this._setupWidgets();
  }

  private _setupWidgets() {
    this.viewer.widgetFactory.registerWidget(
      'TeiaAnomalyResume',
      ({ viewportRect, projectId, isAnomalyCreation }) => {
        const { width, height } = viewportRect;
        return new TeiaAnomalyResumeWidget(this.viewer, projectId, isAnomalyCreation, {
          x: 5,
          y: 5,
          width: width / 3,
          height: height - 10,
          minWidth: 800,
          minHeight: height - height / 3
        });
      }
    );
  }
}
