import { Bold } from '@stereograph/teia-system-design/components/Typography';

interface TeiaQuantityTakeoffTableCellAggregateItemProps {
  label: string;
  value: number;
}

export const TeiaQuantityTakeoffTableCellAggregateItem = (
  props: TeiaQuantityTakeoffTableCellAggregateItemProps
) => {
  const { label, value } = props;
  return (
    <div className={'st-flex st-w-full'}>
      <div className={'st-min-w-[12ch]'}>
        <Bold>{label}</Bold>
      </div>{' '}
      {value}
    </div>
  );
};
