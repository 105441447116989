import { ChatBubbleOvalLeftIcon } from '@heroicons/react/24/outline';
import { usePluginTranslation } from '@translation';
import { useAnomalyCommentContext } from '../../context/AnomalyCommentContext';
import { AnomalyCommentActionsButton } from './AnomalyCommentActionsButton';

export const AnomalyAnswerCommentButton = () => {
  const { t } = usePluginTranslation();
  const { toggleAnswering } = useAnomalyCommentContext();

  return (
    <AnomalyCommentActionsButton
      icon={ChatBubbleOvalLeftIcon}
      title={t('anomaly_plugin.widget.comment_form.answer.button.label')}
      onClick={toggleAnswering}
    />
  );
};
