import { Anomaly } from '@api/twin/AnomalyApi';
import { AttachmentApi, AttachmentTargetType } from '@api/twin/AttachmentApi';
import { Comment } from '@api/twin/CommentApi';
import { useTeiaViewerContext, useTwinSource } from '@stereograph/teiaviewer';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { usePluginTranslation } from '@translation';
import DOMPurify from 'dompurify';
import toast from 'react-hot-toast';
import { useAnomalySectionContext } from '../context/AnomalySectionContext';
import { anomalyQueryKeys } from '../query-keys';

type AnomalyAttachmentTargetType = Extract<AttachmentTargetType, 'Anomaly' | 'Comment'>;

interface UseUploadAnomalyDocumentMutationParams {
  document: File;
  targetId: number;
  targetType: AnomalyAttachmentTargetType;
}

export const useUploadAnomalyDocument = () => {
  const {
    viewer: { twinApiClient }
  } = useTeiaViewerContext();
  const { t } = usePluginTranslation();
  const twin = useTwinSource();
  const queryClient = useQueryClient();
  const { anomaly } = useAnomalySectionContext();

  const { mutateAsync } = useMutation({
    mutationFn: (params: UseUploadAnomalyDocumentMutationParams) => {
      return AttachmentApi(twinApiClient).post(
        twin.projectId,
        params.targetId,
        params.targetType,
        params.document
      );
    },
    onMutate: (variables) => {
      const toastId = `upload-document-${variables.document.name}`;
      toast.loading(t('anomaly_plugin.document.upload.toast.loading_text'), { id: toastId });
      return {
        toastId
      };
    },
    onSuccess: async (_, variables, context) => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: anomalyQueryKeys.documents(anomaly.id) }),
        queryClient.invalidateQueries({
          queryKey: anomalyQueryKeys.commentDocuments(variables.targetId)
        }),
        queryClient.invalidateQueries({ queryKey: anomalyQueryKeys.history_default(anomaly.id) })
      ]);
      toast.success(
        t('anomaly_plugin.document.upload.toast.success_text', {
          document: DOMPurify.sanitize(variables.document.name)
        }),
        {
          id: context.toastId
        }
      );
    },
    onError: (_, variables, context) => {
      toast.error(
        t('anomaly_plugin.document.upload.toast.error_text', {
          document: DOMPurify.sanitize(variables.document.name)
        }),
        { id: context?.toastId }
      );
    }
  });

  const mutateAnomaly = (anomaly: Anomaly, documents: Array<File>) => {
    return Promise.all(
      Array.from(documents).map((document) =>
        mutateAsync({
          document,
          targetId: anomaly.id,
          targetType: 'Anomaly'
        })
      )
    );
  };

  const mutateComment = (comment: Comment, documents: Array<File>) => {
    return Promise.all(
      Array.from(documents).map((document) =>
        mutateAsync({
          document,
          targetId: comment.id,
          targetType: 'Comment'
        })
      )
    );
  };

  return {
    uploadToAnomaly: mutateAnomaly,
    uploadToComment: mutateComment
  };
};
