import { Stack } from '@stereograph/teia-system-design/components/Stack';
import { AnomalyDocument } from './AnomalyDocumentCard';
import { AnomalyDocumentCardDelete } from './AnomalyDocumentCardDelete';
import { AnomalyDocumentCardDownload } from './AnomalyDocumentCardDownload';

interface AnomalyDocumentCardActionsProps {
  document: AnomalyDocument;
}

export const AnomalyDocumentCardActions = (props: AnomalyDocumentCardActionsProps) => {
  const { document } = props;

  return (
    <Stack
      className={'actions'}
      sx={{
        position: 'absolute',
        right: 0,
        transitionProperty: 'all',
        transitionDuration: '100ms',
        opacity: 0,
        visibility: 'hidden'
      }}
      direction="row"
      spacing={1}
      justifyContent="flex-end"
      alignItems="center"
    >
      <AnomalyDocumentCardDownload document={document} />
      <AnomalyDocumentCardDelete document={document} />
    </Stack>
  );
};
