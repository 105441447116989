import { Button } from '@stereograph/teia-system-design/components/Button';
import { Icon } from '@stereograph/teia-system-design/components/Icon';
import { Stack } from '@stereograph/teia-system-design/components/Stack';
import { Text } from '@stereograph/teia-system-design/components/Typography';
import { FC, ForwardRefExoticComponent, RefAttributes, SVGProps } from 'react';

interface AnomalyCommentActionsButtonProps {
  title: string;
  icon:
    | ForwardRefExoticComponent<SVGProps<SVGSVGElement> & RefAttributes<SVGSVGElement>>
    | FC<SVGProps<SVGSVGElement>>;
  onClick?: () => void;
}

export const AnomalyCommentActionsButton = (props: AnomalyCommentActionsButtonProps) => {
  const { title, icon, onClick } = props;

  return (
    <Button
      type="submit"
      variant="text"
      color="info"
      sx={{
        textTransform: 'none',
        '&:hover': {
          textDecoration: 'underline'
        }
      }}
      onClick={onClick}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <Icon
          component={icon}
          sx={{
            height: 16,
            width: 16
          }}
        />
        <Text variant="body2">{title}</Text>
      </Stack>
    </Button>
  );
};
