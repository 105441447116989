import { Alert } from '@stereograph/teia-system-design/components/Alert';
import { Button } from '@stereograph/teia-system-design/components/Button';
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader
} from '@stereograph/teia-system-design/components/Modal';
import { Stack } from '@stereograph/teia-system-design/components/Stack';
import { usePluginTranslation } from '@translation';
import { useEffect, useState } from 'react';
import { formatBytes } from '../../utils/formatBytes';
import {
  ANOMALY_FILE_SIZE_LIMIT,
  ANOMALY_FILELIST_SIZE_LIMIT
} from '../document/AnomalyFileSizeStatus';
import { Dropzone } from './Dropzone';
import { UploadFileList } from './UploadFileList';

interface UploadModalProps {
  open: boolean;
  onClose: () => void;
  onUpload: (files: Array<File>) => void;
}

export const UploadModal = (props: UploadModalProps) => {
  const { open, onClose, onUpload } = props;
  const { t } = usePluginTranslation();
  const [files, setFiles] = useState<Array<File>>([]);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (files.some((file) => file.size > ANOMALY_FILE_SIZE_LIMIT)) {
      setErrorMessage(
        t('anomaly_plugin.document.upload.alert.file_limit_text', {
          size: formatBytes(ANOMALY_FILE_SIZE_LIMIT)
        })
      );
      return;
    }
    const totalSize = files.reduce((sum, file) => sum + file.size, 0);
    if (totalSize > ANOMALY_FILELIST_SIZE_LIMIT) {
      setErrorMessage(
        t('anomaly_plugin.document.upload.alert.file_list_limit_text', {
          size: formatBytes(ANOMALY_FILELIST_SIZE_LIMIT)
        })
      );
      return;
    }
    setErrorMessage('');
  }, [files, t]);

  const handleClose = () => {
    setFiles([]);
    setErrorMessage('');
    onClose();
  };

  const removeFile = (removedFile: File) => {
    setFiles((prevFiles) =>
      prevFiles.filter(
        (file) => !(file.name === removedFile.name && file.size === removedFile.size)
      )
    );
  };

  return (
    <Modal open={open} onClose={handleClose} fullWidth maxWidth="md">
      <ModalHeader>{t('anomaly_plugin.document.modal.title')}</ModalHeader>
      <ModalContent>
        <Stack spacing={4}>
          {errorMessage && (
            <Alert
              severity="error"
              onClose={() => {
                setErrorMessage('');
              }}
            >
              {errorMessage}
            </Alert>
          )}
          <Dropzone onFileChange={setFiles} />
          {files && <UploadFileList files={files} onRemoveFile={removeFile} />}
        </Stack>
      </ModalContent>
      <ModalFooter>
        <Button onClick={handleClose} variant="text" color="info">
          {t('anomaly_plugin.document.modal.close_label')}
        </Button>
        <Button onClick={() => onUpload(files)}>
          {t('anomaly_plugin.document.modal.upload_label')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
