import { CommentQueryParams } from '../CommentApi';

export const commentQueryKeys = {
  default: 'Comments',
  project: (projectId: number) => ['Comments', projectId],
  comment: (projectId: number, commentId: number) => [
    ...commentQueryKeys.project(projectId),
    { targetId: commentId }
  ],
  getAll: (projectId: number, commentQueryParams: CommentQueryParams) => [
    ...commentQueryKeys.project(projectId),
    commentQueryParams
  ]
} as const;
