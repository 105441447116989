import { TrashIcon } from '@heroicons/react/24/solid';
import { Chip } from '@stereograph/teia-system-design/components/Chip';
import { Icon } from '@stereograph/teia-system-design/components/Icon';
import { Tooltip } from '@stereograph/teia-system-design/components/Tooltip';
import { useState } from 'react';
import { useAnomalyCommentContext } from '../../context/AnomalyCommentContext';
import { AnomalyDocument } from '../document/AnomalyDocumentCard';
import { AnomalyDocumentDeleteModal } from '../document/AnomalyDocumentDeletionModal';

interface AnomalyCommentFileProps {
  document: AnomalyDocument;
}

const truncateFileName = (title: string, maxLength = 40) => {
  const parts = title.split('.');
  if (parts.length < 2) return title;

  const extension = parts.pop() ?? '';
  const baseName = parts.join('.');

  if (title.length <= maxLength) return title;

  const truncatedBase = baseName.slice(0, maxLength - extension.length - 4);
  return `${truncatedBase}...${extension}`;
};

export const AnomalyCommentFile = (props: AnomalyCommentFileProps) => {
  const { document } = props;
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { comment } = useAnomalyCommentContext();

  const isDocumentTitleLong = document.title.length >= 40;

  const documentTitle = isDocumentTitleLong ? truncateFileName(document.title) : document.title;

  return (
    <>
      <Tooltip
        title={isDocumentTitleLong ? document.title : ''}
        placement="top"
        enterDelay={500}
        enterNextDelay={300}
      >
        <Chip
          label={documentTitle}
          onDelete={() => setIsDeleteModalOpen(true)}
          deleteIcon={<Icon component={TrashIcon} sx={{ height: '16px', width: '16px' }} />}
          sx={{
            color: (theme) => theme.palette.primary.main,
            height: '24px'
          }}
          variant="outlined"
        />
      </Tooltip>
      <AnomalyDocumentDeleteModal
        isModalOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        document={document}
        comment={comment}
      />
    </>
  );
};
