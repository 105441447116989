import {
  MRT_TableInstance,
  TableToolbarInternalAction
} from '@stereograph/teia-system-design/components/Table';
import { TeiaSearchFilter } from '@stereograph/teia-system-design/utils';
import { TeiaQuantityTakeoffColumn, TeiaQuantityTakeoffRow } from '../../types';
import { TeiaQuantityTakeoffSearchButton } from '../search/TeiaQuantityTakeoffSearchButton';
import { TeiaQuantityTakeoffSelectionButton } from '../selection/TeiaQuantityTakeoffSelectionButton';
import { TeiaQuantityTakeoffExport } from './TeiaQuantityTakeoffExport';

interface TeiaQuantityTakeoffTableInternalActionsProps {
  table: MRT_TableInstance<TeiaQuantityTakeoffRow>;
  searchRules: TeiaSearchFilter;
  columns: Array<TeiaQuantityTakeoffColumn>;
  groupBy: TeiaQuantityTakeoffColumn | null;
}

export const TeiaQuantityTakeoffTableInternalActions = (
  props: TeiaQuantityTakeoffTableInternalActionsProps
) => {
  const { table, searchRules, columns, groupBy } = props;
  return (
    <TableToolbarInternalAction table={table}>
      <TeiaQuantityTakeoffSearchButton />
      <TeiaQuantityTakeoffSelectionButton />
      <TeiaQuantityTakeoffExport
        searchRules={searchRules}
        tableColumns={columns.map((column) => column.value)}
        groupBy={groupBy?.value ?? null}
      />
    </TableToolbarInternalAction>
  );
};
