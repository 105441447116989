import { Autocomplete } from '@stereograph/teia-system-design/components/Autocomplete';
import { Chip } from '@stereograph/teia-system-design/components/Chip';
import { TextField } from '@stereograph/teia-system-design/components/TextField';
import { Tooltip } from '@stereograph/teia-system-design/components/Tooltip';
import { usePluginTranslation } from '@translation';
import { TeiaQuantityTakeoffColumn } from '../types';

interface TeiaQuantityTakeoffColumnFieldProps {
  columns: Array<TeiaQuantityTakeoffColumn>;
  options: Array<TeiaQuantityTakeoffColumn>;
  onChange: (columns: Array<TeiaQuantityTakeoffColumn>) => void;
}

export const TeiaQuantityTakeoffColumnField = (props: TeiaQuantityTakeoffColumnFieldProps) => {
  const { columns, options, onChange } = props;
  const { t } = usePluginTranslation();

  return (
    <Autocomplete<TeiaQuantityTakeoffColumn, true, true>
      size={'small'}
      multiple
      disableClearable
      limitTags={2}
      id="qto-columns"
      disableCloseOnSelect
      getOptionLabel={(option) => {
        if (typeof option === 'object') {
          return option.title;
        }
        return '';
      }}
      getOptionKey={(option) => {
        if (typeof option === 'object') {
          return option.value;
        }
        return '';
      }}
      renderTags={(values, getTagProps, ownerState) => {
        return values.map((option, index) => {
          const { key, ...customTagProps } = getTagProps({ index });
          if (option.title.length >= 15) {
            return (
              <Tooltip title={option.title} key={key} placement={'top'}>
                <Chip
                  label={option.title}
                  size={'small'}
                  sx={{ maxWidth: '15ch!important' }}
                  {...customTagProps}
                />
              </Tooltip>
            );
          }
          return <Chip key={key} label={option.title} size={ownerState.size} {...customTagProps} />;
        });
      }}
      options={options}
      value={columns}
      renderInput={(params) => (
        <TextField {...params} label={t('quantity_takeoff_plugin.column_input_placeholder')} />
      )}
      sx={{ width: 370 }}
      onChange={(_, value) => {
        if (value && Array.isArray(value)) {
          onChange(value as Array<TeiaQuantityTakeoffColumn>);
        }
      }}
      isOptionEqualToValue={(option, value) => option.value === value.value}
    />
  );
};
