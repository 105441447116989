import {
  AnomalyOrderableFields,
  AnomalyPriority,
  AnomalyStatus,
  AnomalyType,
  GetAnomaliesQueryParams
} from '@api/twin/AnomalyApi';
import { Alert } from '@stereograph/teia-system-design/components/Alert';
import { MRT_SortingState } from '@stereograph/teia-system-design/components/Table';
import { usePluginTranslation } from '@translation';
import { useMemo, useState } from 'react';
import { useAnomalyApi } from '../api/useAnomalyApi';
import { useAnomalyWidgetContext } from '../context/AnomalyWidgetContext';
import { AnomalyResumeTable } from './AnomalyResumeTable';

export type ColumnFilter =
  | { id: 'type'; value: AnomalyType }
  | { id: 'priority'; value: AnomalyPriority }
  | { id: 'status'; value: AnomalyStatus }
  | { id: 'author'; value: string }
  | { id: 'title'; value: string }
  | { id: 'isExported'; value: boolean };

export const AnomalyResume = () => {
  const { projectId, twinProjectType } = useAnomalyWidgetContext();
  const { getAnomalies } = useAnomalyApi(projectId);
  const { t } = usePluginTranslation();
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10
  });
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<Array<ColumnFilter>>([]);

  const params: GetAnomaliesQueryParams = useMemo(() => {
    return {
      pageNumber: pagination.pageIndex + 1,
      pageSize: pagination.pageSize,
      status: columnFilters.find((item) => item.id === 'status')?.value as AnomalyStatus,
      type: columnFilters.find((item) => item.id === 'type')?.value as AnomalyType,
      priority: columnFilters.find((item) => item.id === 'priority')?.value as AnomalyPriority,
      author: columnFilters.find((item) => item.id === 'author')?.value as string,
      title: columnFilters.find((item) => item.id === 'title')?.value as string,
      orderBy: sorting[0]?.id ? (sorting[0].id as AnomalyOrderableFields) : 'id',
      orderDirection: sorting[0] && !sorting[0].desc ? 'asc' : 'desc',
      twinProjectType: twinProjectType
    };
  }, [pagination, columnFilters, sorting, twinProjectType]);

  const { data: anomalies, isLoading } = getAnomalies(params);

  if (!anomalies && !isLoading) {
    return <Alert severity="info">{t('anomaly_plugin.resume_widget.no_data.text')}</Alert>;
  }

  return (
    <AnomalyResumeTable
      anomalies={anomalies?.data ?? []}
      rowCount={anomalies?.totalRecords ?? 0}
      pagination={pagination}
      setPagination={setPagination}
      columnFilters={columnFilters}
      setColumnFilters={setColumnFilters}
      sorting={sorting}
      setSorting={setSorting}
      isLoading={isLoading}
    />
  );
};
