import { AnomalyGuids } from '@api/twin/AnomalyApi';
import { Stack } from '@stereograph/teia-system-design/components/Stack';
import { Bold } from '@stereograph/teia-system-design/components/Typography';
import { usePluginTranslation } from '@translation';
import { useMemo } from 'react';
import { createAnomalyScene } from '../../widgets/utils';
import { LoadAnomalySceneButton } from '../table/LoadAnomalySceneButton';

interface AnomalySceneBannerProps {
  anomalyGuids: AnomalyGuids;
}

export const AnomalySceneBanner = (props: AnomalySceneBannerProps) => {
  const { anomalyGuids } = props;
  const { t } = usePluginTranslation();

  const anomalyScene = useMemo(() => {
    return createAnomalyScene(anomalyGuids);
  }, [anomalyGuids]);

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      spacing={2}
      sx={{
        backgroundColor: (theme) => theme.palette.primary.light,
        paddingY: 2.5,
        paddingX: 4
      }}
    >
      <Bold>
        {`${t('anomaly_plugin.widget.scene_banner.selected_objects.label', {
          count: anomalyScene.selected.length
        })}, ${t('anomaly_plugin.widget.scene_banner.visible_objects.label', {
          count: anomalyScene.visible.length
        })}, ${t('anomaly_plugin.widget.scene_banner.hidden_objects.label', {
          count: anomalyScene.hidden.length
        })}`}
      </Bold>
      <Stack direction="row" spacing={2}>
        <LoadAnomalySceneButton anomalyScene={anomalyScene} />
      </Stack>
    </Stack>
  );
};
