import { OrderDirection } from '@api/twin/AnomalyApi';
import { Box } from '@stereograph/teia-system-design/components/Box';
import { usePluginTranslation } from '@translation';
import { useState } from 'react';
import { SwitchOrderDirectionButton } from '../history/SwitchOrderDirectionButton';
import { AnomalySectionLayout } from '../tabs/AnomalySectionLayout';
import { AnomalyCommentList } from './AnomalyCommentList';
import { CreateAnomalyCommentForm } from './CreateAnomalyCommentForm';

export const AnomalyCommentSection = () => {
  const { t } = usePluginTranslation();
  const [orderDirection, setOrderDirection] = useState<OrderDirection>('desc');

  return (
    <AnomalySectionLayout
      title={t('anomaly_plugin.widget.sections.comment_section.title')}
      headerRight={
        <SwitchOrderDirectionButton orderDirection={orderDirection} onChange={setOrderDirection} />
      }
    >
      <AnomalyCommentList orderDirection={orderDirection} />
      <Box sx={{ py: 4 }}>
        <CreateAnomalyCommentForm />
      </Box>
    </AnomalySectionLayout>
  );
};
