import { Text } from '@stereograph/teia-system-design/components/Typography';
import { useViewerPlugin } from '@stereograph/teiaviewer';
import { usePluginTranslation } from '@translation';

export const TeiaQuantityTakeoffWidgetFooter = () => {
  const { t } = usePluginTranslation();
  const { plugin } = useViewerPlugin('qto');
  return (
    <Text>
      {t('quantity_takeoff_plugin.footer_info_text', {
        limit: plugin.settings.maxObjectsLimit.value
      })}
    </Text>
  );
};
