import {
  Anomaly,
  AnomalyCamera,
  AnomalyGuids,
  AnomalyPriority,
  AnomalyStatus,
  AnomalyType,
  RequestPostAnomaly
} from '@api/twin/AnomalyApi';
import { zodResolver } from '@hookform/resolvers/zod';
import { usePluginTranslation } from '@translation';
import { FormProvider, useForm } from 'react-hook-form';
import { useAnomalyApi } from '../../api/useAnomalyApi';
import { useAnomalyTabContext } from '../../context/AnomalyTabsContext';
import { useAnomalyWidgetContext } from '../../context/AnomalyWidgetContext';
import { AnomalyForm, AnomalyFormSchema, anomalyFormSchema } from './AnomalyForm';

interface CreateAnomalyFormProps {
  snapshot: string;
  anomalyGuids: AnomalyGuids;
  camera: AnomalyCamera;
}

export const CreateAnomalyForm = (props: CreateAnomalyFormProps) => {
  const { snapshot, anomalyGuids, camera } = props;
  const { projectId, twinProjectType, fileIds } = useAnomalyWidgetContext();
  const { postAnomaly } = useAnomalyApi(projectId);
  const { t } = usePluginTranslation();
  const { handleCloseTab, handleAddTab, createAnomalyTab } = useAnomalyTabContext();

  const methods = useForm<AnomalyFormSchema>({
    resolver: zodResolver(anomalyFormSchema),
    mode: 'onSubmit',
    defaultValues: {
      anomalySchema: {
        type: AnomalyType.Clash,
        priority: AnomalyPriority.Critical,
        status: AnomalyStatus.Active,
        stateByGuid: anomalyGuids,
        description: '',
        title: ''
      }
    }
  });
  const { handleSubmit } = methods;

  const onSubmit = (data: AnomalyFormSchema) => {
    const anomaly: RequestPostAnomaly = {
      ...data.anomalySchema,
      fileId: fileIds.length === 1 ? fileIds[0] : undefined,
      twinProjectId: fileIds.length === 1 ? undefined : projectId,
      twinProjectType: twinProjectType,
      snapshot: snapshot,
      dueDate: new Date().toISOString(),
      createAnomalyViewPointCommand: {
        ...camera
      }
    };

    postAnomaly(anomaly, {
      onSuccess: (data: Anomaly) => {
        handleCloseTab({
          label: t('anomaly_plugin.widget.create_anomaly.tab.title'),
          value: 'creation',
          closable: true
        });
        handleAddTab(createAnomalyTab(data, true), true);
      }
    });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <AnomalyForm
          buttonLabel={t('anomaly_plugin.widget.form.submit.create.label')}
          snapshot={snapshot}
        />
      </form>
    </FormProvider>
  );
};
