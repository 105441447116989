import { useDownloadDocument } from '../../hooks/useDownloadDocument';
import { Icon } from '@stereograph/teia-system-design/components/Icon';
import { IconButton } from '@stereograph/teia-system-design/components/IconButton';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { usePluginTranslation } from '@translation';
import { AnomalyDocument } from './AnomalyDocumentCard';

interface AnomalyDocumentCardDownloadProps {
  document: AnomalyDocument;
}

export const AnomalyDocumentCardDownload = (props: AnomalyDocumentCardDownloadProps) => {
  const { document } = props;
  const { download } = useDownloadDocument();
  const { t } = usePluginTranslation();

  return (
    <IconButton
      onClick={() => download(document)}
      aria-label={t('anomaly_plugin.document.download_label')}
      iconOnly
    >
      <Icon component={ArrowDownTrayIcon} fontSize={'small'} />
    </IconButton>
  );
};
