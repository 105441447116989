import { ApiClient, ApiDataList } from '@stereograph/teia-system-design/utils';

export interface TwinTransformer {
  id: number;
  twinProjectId: number;
  modelName: string;
  fileId: number;
  fileVersion: number;
  publicName: string;
  summary: {
    objectsUpdated: number;
    objectsCreated: number;
    objectsDeleted: number;
    objectsSkipped: number;
  };
  status: string;
  createdBy: string;
  creationDate: string;
  increment: number;
}

export interface TwinTransformersQueryParams {
  pageNumber?: number;
  pageSize?: number;
  startDate?: string;
  endDate?: string;
  modelId?: number;
  version?: number;
  status?: string;
  twinProjectType?: string;
}

export const TwinTransformersApi = (client: ApiClient) => ({
  getTwinTransformers(projectId: number, twinTransformersQueryParams: TwinTransformersQueryParams) {
    const url = client.URL(`projects/${projectId}/twin/transformers`);
    url.searchParams.set('pageSize', String(twinTransformersQueryParams.pageSize));
    url.searchParams.set('pageNumber', String(twinTransformersQueryParams.pageNumber));

    if (twinTransformersQueryParams.status) {
      url.searchParams.set('status', twinTransformersQueryParams.status);
    }
    if (twinTransformersQueryParams.startDate) {
      url.searchParams.set('startDate', twinTransformersQueryParams.startDate);
    }
    if (twinTransformersQueryParams.endDate) {
      url.searchParams.set('endDate', twinTransformersQueryParams.endDate);
    }
    if (twinTransformersQueryParams.modelId) {
      url.searchParams.set('modelId', String(twinTransformersQueryParams.modelId));
    }
    if (twinTransformersQueryParams.twinProjectType) {
      url.searchParams.set('twinProjectType', twinTransformersQueryParams.twinProjectType);
    }
    if (twinTransformersQueryParams.version) {
      url.searchParams.set('version', String(twinTransformersQueryParams.version));
    }

    const request = new Request(url, {
      method: 'GET'
    });

    return client.sendRequest<ApiDataList<TwinTransformer>>(request);
  }
});
