import { ApiClient, TeiaSearchFilter } from '@stereograph/teia-system-design/utils';
import { Attachment } from './attachments/Attachment';

export interface FeedbackResponse {
  message: string;
}

export type MessageFeedback = 'positive' | 'negative';

export interface ToolCall {
  name: string;
}

export interface AssistantResponse {
  conversationId: string;
  messageId: string;
  messageText: string;
  finishReason: string;
  completionTokens: number;
  promptTokens: number;
  attachments: Array<Attachment>;
  usedTools: Array<ToolCall>;
}

export interface ViewerState {
  currentSelection: TeiaSearchFilter | null;
}

export interface ChatMessageInput {
  text: string;
  projectId: number;
  viewerState: ViewerState;
}

export interface ConversationResponse {
  id: string;
  flowId: string;
}

export interface ConversationInput {
  flowId: string;
}

export const TiaConversationApi = (client: ApiClient) => ({
  postFeedback(conversationId: string, messageId: string, feedback: MessageFeedback) {
    const url = client.URL(`conversations/${conversationId}/messages/${messageId}/feedback`);
    const data = JSON.stringify({ feedback });

    const request = new Request(url, {
      method: 'POST',
      body: data,
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return client.sendRequest<FeedbackResponse>(request);
  },

  createConversation(flowId: string): Promise<ConversationResponse> {
    const url = client.URL('conversations');
    const data: ConversationInput = { flowId };

    const request = new Request(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return client.sendRequest<ConversationResponse>(request);
  },

  postMessage(conversationId: string, input: ChatMessageInput) {
    const url = client.URL(`conversations/${conversationId}/messages`);
    const data = JSON.stringify(input);

    const request = new Request(url, {
      method: 'POST',
      body: data,
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return client.sendRequest<AssistantResponse>(request);
  }
});
