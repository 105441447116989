import { CircularProgress } from '@stereograph/teia-system-design/components/Progress';
import { Divider } from '@stereograph/teia-system-design/components/Divider';
import { Stack } from '@stereograph/teia-system-design/components/Stack';
import { Suspense } from 'react';
import { AnomalyDocumentSectionHeader } from './AnomalyDocumentSectionHeader';
import { AnomalyDocuments } from './AnomalyDocuments';

export const AnomalyDocumentSection = () => {
  return (
    <Stack spacing={2} sx={{ pb: 4 }}>
      <AnomalyDocumentSectionHeader />
      <Divider />
      <Suspense fallback={<CircularProgress />}>
        <AnomalyDocuments />
      </Suspense>
    </Stack>
  );
};
