import { AnomalyHistoryQueryParams } from '@api/twin/AnomalyApi';
import { AttachmentApiGetAllParams } from '@api/twin/AttachmentApi';

export const anomalyQueryKeys = {
  default: ['anomaly_plugin'] as const,
  documents: (anomalyId: number, params?: AttachmentApiGetAllParams) =>
    [...anomalyQueryKeys.default, anomalyId, 'documents', params] as const,
  commentDocuments: (commentId: number) =>
    [...anomalyQueryKeys.default, commentId, 'commentDocuments'] as const,
  history_default: (anomalyId: number) =>
    [...anomalyQueryKeys.default, anomalyId, 'history'] as const,
  history: (anomalyId: number, params?: AnomalyHistoryQueryParams) => [
    ...anomalyQueryKeys.default,
    anomalyId,
    'history',
    params
  ]
};
