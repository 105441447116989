import { ApiClient, ApiDataList } from '@stereograph/teia-system-design/utils';
import { Attachment, AttachmentApiGetAllParams } from './AttachmentApi';
import { TwinType } from './TwinApi';

export type AnomalyAttachmentParams = Omit<AttachmentApiGetAllParams, 'targetType' | 'targetId'>;

export enum ObjectAnomalyInformation {
  isVisible = 'isVisible',
  isSelected = 'isSelected',
  isHidden = 'isHidden'
}

export type AnomalyGuids = Record<string, ObjectAnomalyInformation>;

export interface AnomalyScene {
  visible: Array<string>;
  hidden: Array<string>;
  selected: Array<string>;
  defaultVisibility: ObjectAnomalyInformation;
}

export enum AnomalyType {
  Clash = 'Clash',
  Inquiry = 'Inquiry',
  Issue = 'Issue',
  Fault = 'Fault',
  Remark = 'Remark',
  Request = 'Request',
  Unknown = 'Unknown',
  Failure = 'Failure'
}

export interface AnomalyTypeOption {
  value: AnomalyType;
  label: string;
}

export enum AnomalyPriority {
  Critical = 'Critical',
  Major = 'Major',
  Normal = 'Normal',
  Minor = 'Minor',
  OnHold = 'OnHold',
  Unknown = 'Unknown'
}

export interface AnomalyPriorityOption {
  value: AnomalyPriority;
  label: string;
}

export enum AnomalyStatus {
  Active = 'Active',
  InProgress = 'InProgress',
  Resolved = 'Resolved',
  Closed = 'Closed',
  ReOpened = 'ReOpened',
  Unknown = 'Unknown'
}

export interface AnomalyStatusOption {
  value: AnomalyStatus;
  label: string;
}

export enum AnomalyCameraType {
  ORTHOGONAL = 'Orthogonal',
  PERSPECTIVE = 'Perspective'
}

interface BaseAnomalyCamera {
  cameraViewPoint: { x: number; y: number; z: number };
  cameraDirection: { x: number; y: number; z: number };
  cameraUpVector: { x: number; y: number; z: number };
  aspectRatio: number;
}

export interface AnomalyPerspectiveCamera extends BaseAnomalyCamera {
  fieldOfView: number;
  type: AnomalyCameraType.PERSPECTIVE;
}

export interface AnomalyOrthographicCamera extends BaseAnomalyCamera {
  viewToWorldScale: number;
  type: AnomalyCameraType.ORTHOGONAL;
}

export type AnomalyCamera = AnomalyPerspectiveCamera | AnomalyOrthographicCamera;

export interface Anomaly {
  id: number;
  fileId: number;
  title: string;
  stateByGuid?: AnomalyGuids;
  snapshot: string; // base64url string for image
  description: string;
  dueDate: string;
  author: string;
  creationDate: string;
  updatedAuthor: string;
  updateDate: string;
  type: AnomalyType;
  priority: AnomalyPriority;
  status: AnomalyStatus;
  commentCounts: number;
  labels?: Array<string>;
  anomalyViewPointDto: AnomalyCamera;
  projectId: number;
}

export enum AnomalyHistoryStatus {
  ADDED = 'Added',
  UPDATED = 'Updated',
  DELETED = 'Deleted',
  CREATED = 'Created'
}

export interface AnomalyHistoryProperty {
  newValue: string;
  oldValue: string;
  propertyName: string;
}

export interface AnomalyHistory {
  id: number;
  anomalyId: number;
  creationDate: string;
  createdBy: string;
  author: string;
  anomalyHistoryState: AnomalyHistoryStatus;
  anomalyHistoryPropertyDtos: Array<AnomalyHistoryProperty>;
}

export interface RequestPostAnomaly {
  title: string;
  stateByGuid?: AnomalyGuids;
  snapshot: string; // base64url string for image
  description: string;
  dueDate?: string;
  type: AnomalyType;
  twinProjectType?: string;
  twinProjectId?: number;
  fileId?: number;
  priority: AnomalyPriority;
  status: AnomalyStatus;
  labels?: Array<string>;
  createAnomalyViewPointCommand: AnomalyCamera;
}

export type AnomalyOrderableFields = keyof Anomaly;

export interface GetAnomaliesQueryParams {
  pageSize: number;
  pageNumber: number;
  twinProjectType?: TwinType;
  status?: AnomalyStatus;
  priority?: AnomalyPriority;
  type?: AnomalyType;
  fileIds?: Array<number>;
  title?: string;
  author?: string;
  isExported?: boolean;
  orderBy?: AnomalyOrderableFields;
  orderDirection?: 'asc' | 'desc';
}

export enum AnomalyFileType {
  BCF = 'bcf',
  Excel = 'xls'
}

export const ANOMALY_EXTENSIONS: Record<AnomalyFileType, string> = {
  [AnomalyFileType.BCF]: '.bcf',
  [AnomalyFileType.Excel]: '.xlsx'
};

export interface AnomalyCsvImportResponse {
  addedCount: number;
  updatedCount: number;
  failed: Record<string, string>;
}

export type OrderDirection = 'asc' | 'desc';

export interface AnomalyHistoryQueryParams {
  pageNumber: number;
  pageSize: number;
  orderDirection: OrderDirection;
  orderBy: 'creationDate' | 'id' | 'anomalyId' | 'createdBy' | 'author' | 'anomalyHistoryState';
}

export const AnomalyApi = (client: ApiClient) => ({
  getAnomaly(projectId: number, anomalyId: number) {
    const url = client.URL(`projects/${projectId}/anomalies/${anomalyId}`);

    const request = new Request(url, {
      method: 'GET'
    });

    return client.sendRequest<Anomaly>(request);
  },

  postAnomaly(projectId: number, anomaly: RequestPostAnomaly) {
    const url = client.URL(`projects/${projectId}/anomalies`);

    const request = new Request(url, {
      method: 'POST',
      body: JSON.stringify(anomaly),
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return client.sendRequest<Anomaly>(request);
  },

  patchAnomaly(projectId: number, anomalyId: number, anomaly: RequestPostAnomaly) {
    const url = client.URL(`projects/${projectId}/anomalies/${anomalyId}`);

    const request = new Request(url, {
      method: 'PUT',
      body: JSON.stringify(anomaly),
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return client.sendRequest<Anomaly>(request);
  },

  getAnomalyHistory(
    projectId: number,
    anomalyId: number,
    anomalyHistoryQueryParams: AnomalyHistoryQueryParams
  ) {
    const url = client.URL(`projects/${projectId}/anomalies/${anomalyId}/histories`);
    url.searchParams.set('pageNumber', anomalyHistoryQueryParams.pageNumber.toString());
    url.searchParams.set('pageSize', anomalyHistoryQueryParams.pageSize.toString());
    if (anomalyHistoryQueryParams.orderBy) {
      url.searchParams.set('orderBy', anomalyHistoryQueryParams.orderBy);
    }
    if (anomalyHistoryQueryParams.orderDirection) {
      url.searchParams.set('orderDirection', anomalyHistoryQueryParams.orderDirection);
    }

    const request = new Request(url, {
      method: 'GET'
    });

    return client.sendRequest<ApiDataList<AnomalyHistory>>(request);
  },

  getAnomalies(projectId: number, anomaliesQueryParams: GetAnomaliesQueryParams) {
    const url = client.URL(`projects/${projectId}/anomalies`);

    Object.entries(anomaliesQueryParams).forEach(([key, value]) => {
      if (value === undefined || value === null) {
        return;
      }

      if (Array.isArray(value)) {
        value.forEach((item) => url.searchParams.append(key, String(item)));
      } else {
        url.searchParams.set(key, String(value));
      }
    });

    const request = new Request(url, {
      method: 'GET'
    });

    return client.sendRequest<ApiDataList<Anomaly>>(request);
  },

  async exportAnomalies(
    projectId: number,
    anomalyIds: Array<number>,
    anomalyUrlType: AnomalyFileType
  ) {
    const url = client.URL(`projects/${projectId}/anomalies/${anomalyUrlType}/export`);

    anomalyIds.forEach((anomalyId) => {
      url.searchParams.append('anomalyIds', String(anomalyId));
    });

    const request = new Request(url, {
      method: 'GET'
    });

    return client.sendRequest<Response>(request);
  },

  async importBcfAnomaly(
    projectId: number,
    files: Array<File>,
    twinProjectType: TwinType
  ): Promise<unknown> {
    const url = client.URL(`projects/${projectId}/anomalies/${AnomalyFileType.BCF}/import`);
    url.searchParams.set('twinProjectType', twinProjectType);

    const formData = new FormData();
    files.forEach((file) => {
      formData.append('files', file);
    });

    const request = new Request(url, {
      method: 'POST',
      body: formData,
    });

    return client.sendRequest(request);
  },

  async importCsvAnomaly(projectId: number, files: Array<File>, twinProjectType: TwinType) {
    const url = client.URL(`projects/${projectId}/anomalies/${AnomalyFileType.Excel}/import`);
    url.searchParams.set('twinProjectType', twinProjectType);

    const formData = new FormData();
    files.forEach((file) => {
      formData.append('files', file);
    });

    const request = new Request(url, {
      method: 'POST',
      body: formData,
    });

    return client.sendRequest<AnomalyCsvImportResponse>(request);
  },

  getAnomalyAttachments(
    projectId: number,
    anomalyId: number,
    anomalyAttachmentParams: AnomalyAttachmentParams
  ) {
    const url = client.URL(`projects/${projectId}/anomalies/${anomalyId}/attachments`);

    Object.entries(anomalyAttachmentParams).forEach(([key, value]) => {
      if (!value) {
        return;
      }

      if (Array.isArray(value)) {
        value.forEach((item) => url.searchParams.append(key, String(item)));
      } else {
        url.searchParams.set(key, String(value));
      }
    });

    const request = new Request(url, {
      method: 'GET'
    });

    return client.sendRequest<ApiDataList<Attachment>>(request);
  }
});
