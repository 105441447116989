import { VisibilityOutlined } from '@mui/icons-material';
import SelectionInfoIcon from '@resources/icons/SelectionInfoIcon.svg?react';
import { Stack } from '@stereograph/teia-system-design/components/Stack';
import { IconButton } from '@stereograph/teia-system-design/deprecated';
import { ObjectSelection, SelectObjectsCommand, useViewerContext } from '@stereograph/teiaviewer';
import { useEffect, useState } from 'react';

interface SelectionBannerProps {
  label: string;
  selection: ObjectSelection | Array<string>;
  onSelectionChange?: (selection: ObjectSelection) => void;
}

const VisibilityOutlinedIcon = () => <VisibilityOutlined />;

export const SelectionBanner = (props: SelectionBannerProps) => {
  const { label, selection, onSelectionChange } = props;
  const { viewer } = useViewerContext();
  const [selectionChanged, setSelectionChanged] = useState(false);

  const onShowSelectionFilter = async () => {
    const command = new SelectObjectsCommand(viewer, selection);
    command.execute();
  };

  const selectionChangedSignalHandler = () => {
    setSelectionChanged(true);
  };

  const selectionChangeHandler = () => {
    if (onSelectionChange) {
      onSelectionChange(viewer.scene.objectSelection);
      setSelectionChanged(false);
    }
  };

  useEffect(() => {
    viewer.signals.onObjectSelectionChanged.connect(selectionChangedSignalHandler);

    return () => {
      viewer.signals.onObjectSelectionChanged.disconnect(selectionChangedSignalHandler);
    };
  }, []);

  return (
    <div className="st-px-4 st-py-2.5 st-bg-primary-25 st-flex st-flex-row st-items-center st-justify-between st-gap-2">
      <span className="st-text-black st-font-bold">{label}</span>
      <Stack direction={'row'} spacing={2}>
        {selectionChanged && onSelectionChange && (
          <IconButton
            icon={{ svgIcon: SelectionInfoIcon }}
            variant="primary"
            onClick={selectionChangeHandler}
            className="st-border-black-200 hover:st-border-black-200 st-ml-auto"
            outlined
          />
        )}
        <IconButton
          icon={{ svgIcon: VisibilityOutlinedIcon }}
          variant="primary"
          onClick={onShowSelectionFilter}
          className="st-border-black-200 hover:st-border-black-200 st-ml-auto"
          outlined
        />
      </Stack>
    </div>
  );
};
