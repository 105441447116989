import { Anomaly } from '@api/twin/AnomalyApi';
import { createContext, useContext, useMemo } from 'react';

interface AnomalySectionContext {
  anomaly: Anomaly;
}

const Context = createContext<AnomalySectionContext | null>(null);

export interface TabContainerProps {
  children?: React.ReactNode;
  anomaly: Anomaly;
}

export const AnomalySectionProvider = (props: TabContainerProps) => {
  const { anomaly, children } = props;

  const ctx = useMemo(
    () => ({
      anomaly
    }),
    [anomaly]
  );

  return <Context.Provider value={ctx}>{children}</Context.Provider>;
};

export const useAnomalySectionContext = () => {
  const ctx = useContext(Context);

  if (!ctx) {
    throw new Error('Anomaly Section Context not found');
  }

  return ctx;
};
