import AdvancedSearchIcon from '@resources/icons/AdvancedSearchIcon.svg?react';
import { TableIconButton } from '@stereograph/teia-system-design/components/Table';
import { Tooltip } from '@stereograph/teia-system-design/components/Tooltip';
import { usePluginTranslation } from '@translation';
import { MouseEventHandler, useState } from 'react';
import { TeiaQuantityTakeoffAdvancedSearch } from './TeiaQuantityTakeoffAdvancedSearch';

export const TeiaQuantityTakeoffSearchButton = () => {
  const { t } = usePluginTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const handleClick: MouseEventHandler<HTMLElement> = (event) => {
    setAnchorEl(event.currentTarget);
  };
  return (
    <>
      <Tooltip title={t('quantity_takeoff_plugin.advanced_search.tooltip')} placement="bottom">
        <TableIconButton
          onClick={handleClick}
          aria-label={t('quantity_takeoff_plugin.advanced_search.label')}
          icon={AdvancedSearchIcon}
        />
      </Tooltip>
      <TeiaQuantityTakeoffAdvancedSearch anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
    </>
  );
};
