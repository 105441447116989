import { Anomaly } from '@api/twin/AnomalyApi';
import { PencilIcon } from '@heroicons/react/24/outline';
import { Icon } from '@stereograph/teia-system-design/components/Icon';
import { IconButton } from '@stereograph/teia-system-design/components/IconButton';
import { Tooltip } from '@stereograph/teia-system-design/components/Tooltip';
import { usePluginTranslation } from '@translation';
import { MouseEvent } from 'react';
import { useAnomalyTabContext } from '../../context/AnomalyTabsContext';

interface EditAnomalyButton {
  anomaly: Anomaly;
}

export const EditAnomalyButton = (props: EditAnomalyButton) => {
  const { anomaly } = props;
  const { t } = usePluginTranslation();
  const { handleAddTabClick, createAnomalyTab } = useAnomalyTabContext();

  return (
    <Tooltip
      title={t('anomaly_plugin.resume_widget.table.columns.actions.edit_tooltip')}
      placement="top"
    >
      <IconButton
        onClick={(event: MouseEvent) => handleAddTabClick(event, createAnomalyTab(anomaly, true))}
        aria-label={t('anomaly_plugin.resume_widget.table.columns.actions.edit_tooltip')}
      >
        <Icon component={PencilIcon} />
      </IconButton>
    </Tooltip>
  );
};
