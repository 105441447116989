import { Box } from '@stereograph/teia-system-design/components/Box';
import { Title } from '@stereograph/teia-system-design/components/Typography';
import { TokenInfoView } from './TokenInfoView';

export const InfoTabView = () => {
  return (
    <Box sx={{ height: 1, width: 1, display: 'flex', flexDirection: 'column', p: 1, gap: 1 }}>
      <Title sx={{ alignSelf: 'center', m: 1, mb: 5 }}>Information</Title>
      <TokenInfoView />
    </Box>
  );
};
