import { Quota } from '@api/tia/TiaQuotaApi';
import { useGetQuota } from '@api/tia/TiaQuotaApiHooks';
import { Alert, AlertTitle } from '@stereograph/teia-system-design/components/Alert';
import { Box } from '@stereograph/teia-system-design/components/Box';
import { LinearProgress } from '@stereograph/teia-system-design/components/Progress';
import { Text } from '@stereograph/teia-system-design/components/Typography';

export const TokenInfoView = () => {
  const { data: quota, isLoading } = useGetQuota();

  const getPercentageProgressValue = (quota: Quota) => {
    if (quota.totalToken == 0) return 0;
    return Math.round(Math.min(100, (quota.tokenConsumption / quota.totalToken) * 100));
  };

  const isError = !isLoading && !quota;

  return (
    <Alert sx={{ '& .MuiAlert-message': { width: 1 } }} severity={isError ? 'error' : 'info'}>
      <AlertTitle>Tokens status</AlertTitle>
      {isLoading && <LinearProgress variant="indeterminate" />}
      {isError && <Text>No tokens available for your instance.</Text>}
      {!isLoading && quota && (
        <Box sx={{ display: 'flex', alignItems: 'center', width: 1 }}>
          <Box sx={{ flexGrow: 1 }}>
            <LinearProgress
              variant="determinate"
              color="primary"
              value={getPercentageProgressValue(quota)}
            />
          </Box>
          <Text hidden={isLoading} sx={{ mx: 5 }}>
            {quota.tokenConsumption.toLocaleString()} / {quota.totalToken.toLocaleString()} (
            {getPercentageProgressValue(quota)}%)
          </Text>
        </Box>
      )}
    </Alert>
  );
};
