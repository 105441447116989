import { TwinApi } from '@api/twin/TwinApi';
import { TeiaSearchGroup } from '@stereograph/teia-system-design/utils';
import { useTeiaViewerContext, useTwinSource } from '@stereograph/teiaviewer';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { teiaQuantityTakeoffObjectsDTO } from '../utils/teiaQuantityTakeoffObjectsDTO';
import { teiaQuantityTakeoffQueryKeys } from '../utils/teiaQuantityTakeoffQueryKeys';

export interface UseQuantityTakeoffObjectParams {
  rule: TeiaSearchGroup;
  columns: Array<string>;
  groupBy?: string;
  pageIndex?: number;
  pageSize?: number;
  orderBy?: string;
  orderDirection?: string;
}

export const useQuantityTakeoffObject = (params: UseQuantityTakeoffObjectParams) => {
  const {
    rule,
    columns,
    groupBy,
    pageIndex = 1,
    pageSize = 100,
    orderBy = '',
    orderDirection = ''
  } = params;
  const { viewer } = useTeiaViewerContext();
  const twin = useTwinSource();
  const client = viewer.twinApiClient;

  const queryRule = { ...rule };
  if (twin.filter && 'rules' in twin.filter && 'condition' in twin.filter) {
    const searchRules = twin.filter as TeiaSearchGroup;
    queryRule.rules = [searchRules, ...rule.rules];
  }

  const { data, isLoading, isFetching, error } = useQuery({
    queryKey: teiaQuantityTakeoffQueryKeys.objects(
      queryRule,
      columns,
      groupBy ?? '',
      pageIndex,
      pageSize,
      orderBy,
      orderDirection
    ),
    queryFn: async () => {
      const { data, ...restResponse } = await TwinApi(client).searchExport(
        twin,
        {
          rule: queryRule,
          columns: columns,
          group: groupBy ?? ''
        },
        pageIndex,
        pageSize,
        orderBy,
        orderDirection
      );
      const flatTakeoffObjects = teiaQuantityTakeoffObjectsDTO(data.datas, groupBy);
      return {
        data: flatTakeoffObjects,
        ...restResponse
      };
    },
    placeholderData: keepPreviousData
  });

  return {
    data,
    isLoading: isLoading || isFetching,
    error
  };
};
