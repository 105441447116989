import { Box } from '@stereograph/teia-system-design/components/Box';
import { Card, CardContent } from '@stereograph/teia-system-design/components/Card';
import { Stack } from '@stereograph/teia-system-design/components/Stack';
import { Text } from '@stereograph/teia-system-design/components/Typography';
import { usei18nDate } from '../../../../hooks/usei18nDate';
import { AnomalyDocumentBanner } from './AnomalyDocumentBanner';
import { AnomalyDocumentCardActions } from './AnomalyDocumentCardActions';

export interface AnomalyDocument {
  id: number;
  title: string;
  createdAt: Date;
  type: File['type'];
}

export type AnomalyDocumentProps = AnomalyDocument;

export const AnomalyDocumentCard = (props: AnomalyDocumentProps) => {
  const { id, title, createdAt, type } = props;
  const { format } = usei18nDate();
  return (
    <Card
      sx={{
        width: 160
      }}
    >
      <CardContent
        sx={{
          textAlign: 'center',
          '&:hover .actions': {
            opacity: 1,
            visibility: 'visible'
          }
        }}
      >
        <Stack spacing={2}>
          <Box
            sx={{
              position: 'relative'
            }}
          >
            <AnomalyDocumentCardActions document={{ id, title, createdAt, type }} />
            <AnomalyDocumentBanner type={type} />
          </Box>
          <div>
            <Text
              bold
              variant="body2"
              sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
            >
              {title}
            </Text>
            <Text variant="body2">{format(createdAt)}</Text>
          </div>
        </Stack>
      </CardContent>
    </Card>
  );
};
