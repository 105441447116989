import { OpenDataAction } from '@api/twin/OpenDataApi';
import { TrashIcon } from '@heroicons/react/24/outline';
import { Autocomplete } from '@stereograph/teia-system-design/components/Autocomplete';
import { Box } from '@stereograph/teia-system-design/components/Box';
import { Icon } from '@stereograph/teia-system-design/components/Icon';
import { IconButton } from '@stereograph/teia-system-design/components/IconButton';
import { Select } from '@stereograph/teia-system-design/components/Select';
import { Stack } from '@stereograph/teia-system-design/components/Stack';
import { Text } from '@stereograph/teia-system-design/components/Typography';
import { TextField } from '@stereograph/teia-system-design/components/TextField';
import { usePluginTranslation } from '@translation';
import { Controller, useFormContext } from 'react-hook-form';
import { useOpenDataPropertyKeys } from '../hooks/useOpenDataPropertyKeys';
import { OpenDataSchema } from '../widgets/TeiaOpenDataForm';

interface OpenDataActionFieldProps {
  index: number;
  removeField: (index: number) => void;
}

export const OpenDataActionField = (props: OpenDataActionFieldProps) => {
  const { index, removeField } = props;
  const { t } = usePluginTranslation();
  const { control } = useFormContext<OpenDataSchema>();
  const { openDataPropertyKeys, isLoading } = useOpenDataPropertyKeys();

  const actions = {
    [OpenDataAction.Add]: t('open_data_plugin.form.action.add.text'),
    [OpenDataAction.Update]: t('open_data_plugin.form.action.update.text'),
    [OpenDataAction.Delete]: t('open_data_plugin.form.action.delete.text'),
    [OpenDataAction.AddOrUpdate]: t('open_data_plugin.form.action.add_or_update.text')
  };

  return (
    <Stack
      sx={{
        boxShadow: '0px 4px 4px 0px #00000040',
        borderRadius: '0.375rem',
        padding: 4
      }}
      spacing={4}
    >
      <Stack direction="row">
        <Text variant={'lead'} bold>
          {t('open_data_plugin.form.data.title')}
        </Text>
        <Text component={'sup'} sx={{ ml: 0.5 }} color={'error'}>
          *
        </Text>
      </Stack>

      <Stack direction="row" spacing={4}>
        <Stack sx={{ width: '100%' }} spacing={4}>
          <Controller
            control={control}
            name={`propertySchema.${index}.key`}
            render={({ field, fieldState: { error } }) => (
              <Autocomplete
                id={field.name}
                freeSolo
                disableClearable
                loading={isLoading}
                onChange={(_event, value) => {
                  field.onChange(value);
                }}
                onInputChange={(_event, value) => {
                  field.onChange(value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={error !== undefined}
                    helperText={error?.message}
                    name={field.name}
                    required
                    label={t('open_data_plugin.form.property.key.placeholder')}
                    placeholder={t('open_data_plugin.form.property.key.placeholder')}
                  />
                )}
                options={openDataPropertyKeys ?? []}
              />
            )}
          />

          <Controller
            control={control}
            name={`propertySchema.${index}.value`}
            render={({ field, fieldState: { error } }) => (
              <TextField
                id={field.name}
                label={t('open_data_plugin.form.property.value.placeholder')}
                placeholder={t('open_data_plugin.form.property.value.placeholder')}
                error={error !== undefined}
                helperText={error?.message}
                required
                onChange={field.onChange}
                name={field.name}
                value={field.value}
              />
            )}
          />

          <Controller
            control={control}
            name={`propertySchema.${index}.action`}
            render={({ field }) => (
              <Select<OpenDataAction, false>
                getOptionLabel={(option) => actions[option]}
                defaultValue={OpenDataAction.Add}
                onChange={(value) => {
                  if (value !== null) {
                    field.onChange(value);
                  }
                }}
                id={field.name}
                options={Object.keys(actions) as Array<OpenDataAction>}
                required
                label={t('open_data_plugin.form.action.title')}
              />
            )}
          />
        </Stack>

        <Box>
          <IconButton
            aria-label={t('patch_data_plugin.form.property.delete_property_label')}
            color="error"
            onClick={() => removeField(index)}
          >
            <Icon component={TrashIcon} />
          </IconButton>
        </Box>
      </Stack>
    </Stack>
  );
};
